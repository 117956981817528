import logo from '../images/o2-full.svg';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd'
import React, { Component } from 'react'
import O2ToursAPI from '../api/O2ToursAPI';
import LoginPageLayout from './LoginPageLayout';

export class LoginPage extends Component {
    unmount = false;
    state = {
        loading: false,
        loginFalied: false,
    };
    handleFinish = (values) => {
        this.setState({ loading: true, loginFalied: false });
        O2ToursAPI.auth.login(values.email, values.password, values.remember).catch((error) => {
            this.setState({ loginFalied: true });
        }).finally(() => {
            if (this.unmount) return;
            this.setState({ loading: false });
        });
    }
    componentWillUnmount() {
        this.unmount = true;
    }
    render() {
        return (
            <LoginPageLayout>
                <img src={logo} alt="Logo" style={{ height: 44 }} />
                <Form layout='vertical' initialValues={{ remember: true }} onFinish={this.handleFinish}>
                    <Form.Item label="帳號" name="email">
                        <Input />
                    </Form.Item>
                    <Form.Item label="密碼" name="password" validateStatus={this.state.loginFalied ? 'error' : 'success'} help={this.state.loginFalied ? '登入失敗' : null}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={12} style={{ textAlign: 'left' }}>
                                <Form.Item name="remember" valuePropName="checked">
                                    <Checkbox style={{ verticalAlign: 'middle' }}>記住我</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Button htmlType="submit" loading={this.state.loading}>登入</Button>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        返回 <a href={process.env.REACT_APP_URL}>O2 旅行</a>
                    </Form.Item>
                </Form>
            </LoginPageLayout>
        )
    }
}

export default LoginPage