import { AutoComplete, Button, Form, Input, InputNumber, Radio } from 'antd'
import React, { forwardRef } from 'react'
import AvatorUpload from '../AvatorUpload'
import TransportationSelect from '../TransportationSelect';

const TravelerForm = forwardRef(({ onSelectedAvatorFile, isProfile, ...props }, ref) => (
    <Form ref={ref} labelCol={{ span: 4 }} {...props}>
        <Form.Item label="旅者代號" name="account" rules={[
            {
                required: true,
                pattern: new RegExp("([a-zA-Z0-9.-_]{6,})"),
                message: "需由至少 6 位小寫英數文字組成"
            },
        ]}>
            <Input placeholder="請輸入您想要使用的旅者代號，需由至少 6 位小寫英數文字組成" />
        </Form.Item>
        <Form.Item label="電子信箱" name="email">
            <Input placeholder="請輸入您想要使用的電子信箱，格式為：user@example.com" />
        </Form.Item>
        <Form.Item label="名稱" name="name" required={true}>
            <Input placeholder="請輸入您的名稱" />
        </Form.Item>
        <Form.Item label="頭銜" name="type">
            <AutoComplete placeholder="請輸入您的頭銜，例：百岳王者" options={[
                { value: "旅遊專家" },
                { value: "地球探險家" },
                { value: "旅遊作家" },
                { value: "文化探究者" },
                { value: "冒險家" },
                { value: "美食家" },
                { value: "自然生態觀察家" },
                { value: "海洋探險家" },
                { value: "奢華旅行家" },
                { value: "宗教旅遊導覽" },
                { value: "城市漫遊者" },
                { value: "自然景觀攝影家" },
                { value: "聚落文化研究者" },
                { value: "藝術品收藏家" },
                { value: "在地人生活體驗家" },
                { value: "青年旅館老闆" },
                { value: "特色住宿經營者" },
                { value: "自然保育志工" },
                { value: "青年旅行團領隊" },
                { value: "遠足導遊" },
                { value: "船長" },
                { value: "賞鳥專家" },
                { value: "博物館導覽員" },
                { value: "夜間探險家" },
                { value: "社區旅遊策劃師" },
                { value: "露營達人" },
                { value: "私人旅行規劃師" },
                { value: "珠寶採購員" },
                { value: "旅遊攝影師" },
                { value: "移民故事收集家" },
                { value: "城市導覽專家" },
                { value: "火車探險家" },
                { value: "路線規劃師" },
                { value: "旅遊部落客" },
                { value: "環保旅遊推廣者" },
                { value: "在地美食導覽" },
                { value: "家庭旅行規劃師" },
                { value: "垂釣專家" },
                { value: "浪漫之旅策劃師" },
                { value: "文化交流大使" },
                { value: "電影取景地導覽員" },
                { value: "水上運動教練" },
                { value: "旅遊行銷策略師" },
                { value: "媒體旅遊採訪員" },
                { value: "飯店經理" },
                { value: "旅遊地產開發商" },
                { value: "自然科學研究者" },
                { value: "音樂節主辦人" },
                { value: "旅遊產品設計師" },
                { value: "空中飛人" },
                { value: "旅遊藝術家" },
                { value: "旅行商品採購員" },
                { value: "綠色旅遊評估員" },
                { value: "室內設計師" },
                { value: "環保旅遊" },
            ]} />
        </Form.Item>
        <Form.Item label="簡短自介" name="brief" required={true}>
            <Input placeholder="請輸入約 20 字左右的簡短自我介紹" />
        </Form.Item>
        <Form.Item label="完整自介" name="description">
            <Input.TextArea placeholder="請輸入詳細的自我介紹" rows={4} />
        </Form.Item>
        <Form.Item label="交通工具" name="transportations">
            <TransportationSelect />
        </Form.Item>
        {!isProfile && <Form.Item label="首頁排行" name="top">
            <InputNumber min={1} placeholder="請輸入首頁排序顯示順序 1-3" />
        </Form.Item>}
        {!isProfile && <Form.Item label="追蹤人數" name="followers">
            <InputNumber min={0} placeholder="請輸入預設顯示的追蹤人數" />
        </Form.Item>}
        <Form.Item label="頭像" name="avator_file">
            <AvatorUpload onChange={onSelectedAvatorFile} />
        </Form.Item>
        <Form.Item label="公開顯示" name="display" valuePropName="value" required={true}>
            <Radio.Group placeholder="請上傳您的頭像照片" >
                <Radio.Button value={1}>是</Radio.Button>
                <Radio.Button value={0}>否</Radio.Button>
            </Radio.Group>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 4, span: 20 }} style={{ textAlign: 'center' }}>
            <Button htmlType="reset" style={{ marginRight: 5 }}>重設</Button>
            <Button type="primary" htmlType="submit">送出</Button>
        </Form.Item>
    </Form>
));

export default TravelerForm