import { Select } from 'antd'
import React, { Component } from 'react'
import O2ToursAPI from '../api/O2ToursAPI';

export class CategoriesSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            categories: []
        };
    }
    componentDidMount() {
        this.fetchData();
    }
    fetchData = () => {
        this.setState({ isLoading: true });
        O2ToursAPI.categories.index(1, 99).then((response) => {
            this.setState({ categories: response.data.data });
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }
    render() {
        return (
            <>
                <Select
                    style={{ width: '100%' }}
                    mode="multiple"
                    {...this.props}
                    placeholder="請選擇分類"
                    optionLabelProp="label"
                    loading={this.state.isLoading}
                >
                    {this.state.categories.map((row) => <Select.Option key={row.id} value={row.id} label={row.name}>{row.name}</Select.Option>)}
                </Select>
            </>
        )
    }
}

export default CategoriesSelect
