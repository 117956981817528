import { Select, Spin } from 'antd';
import React, { Component } from 'react'
import O2ToursAPI from '../api/O2ToursAPI';

export class TravelerSelect extends Component {
    timeoutHandler;
    currentSearchName;
    select;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            travelers: [],
            value: undefined
        }
        this.select = React.createRef();
    }
    componentDidMount() {
        this.fetchByValue();
    }
    fetchByValue = () => {
        const travelerId = this.props.value;
        this.fetchData({ id: travelerId });
    }
    fetchData = (query) => {
        // let query = {
        //     id: id,
        //     name: searchName
        // };
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler);
            this.timeoutHandler = null;
        }
        this.currentSearchName = query.name;

        this.setState({ isLoading: true });
        let search = () => {
            O2ToursAPI.travelers.index(query).then((response) => {
                // 若回傳的結果不是目前實際搜尋的內容則略過 (回應時已經更改輸入的文字)
                if (this.currentSearchName === query.name) {
                    this.setState({ travelers: response.data.data });
                }
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        }
        this.timeoutHandler = setTimeout(search, 300);
    }
    handleSearch = (searchName) => {
        // 有輸入名稱時才發請求
        if (searchName) {
            this.fetchData({ name: searchName });
        } else {
            this.setState({ travelers: [] });
        }
    }
    render() {
        const { ...selectProps } = this.props;
        return (
            <div>
                <Select
                    ref={this.select}
                    {...selectProps}
                    showSearch
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    notFoundContent={null}
                    onSearch={this.handleSearch}
                >
                    {
                        this.state.travelers.map((row) => <Select.Option key={row.id} value={row.id}>{row.account} ({row.name})</Select.Option>)
                    }
                </Select>
                <Spin style={{ marginLeft: 10 }} size="small" spinning={this.state.isLoading}></Spin>
            </div>
        )
    }
}

export default TravelerSelect
