import React from 'react'
import PermissionManager from '../PermissionManager';
import { Menu as AntdMenu } from 'antd';
import {
    LoginOutlined,
    LogoutOutlined,
    UserOutlined,
    GlobalOutlined,
    CoffeeOutlined,
    CarOutlined,
    //HomeOutlined,
    FormOutlined,
    ProfileOutlined,
    StarOutlined,
} from '@ant-design/icons';
import O2ToursAPI from '../api/O2ToursAPI';
import { useNavigate } from 'react-router-dom';

function Menu({ onClick }) {
    const navigate = useNavigate();
    const isLoggedIn = () => {
        return O2ToursAPI.auth.getLoginUser();
    };
    const handleLogout = () => {
        if (O2ToursAPI.auth.getLoginUser()) {
            O2ToursAPI.auth.logout();
        }
    }
    const items = [
        {
            label: '登入',
            key: 'anonymous-login',
            hidden: !!isLoggedIn(),
            icon: <LoginOutlined />,
            roles: [PermissionManager.ROLE_ANONYMOUS],
        },
        // {
        //     label: '首頁',
        //     key: 'anonymous-home',
        //     icon: <HomeOutlined />,
        //     roles: [PermissionManager.ROLE_ANONYMOUS],
        // },
        {
            type: 'group',
            label: '旅者',
            key: 'g-traveler',
            roles: [PermissionManager.ROLE_TRAVELER],
            children: [
                {
                    label: '行程管理',
                    key: 'traveler-tours',
                    icon: <CarOutlined />,
                },
                {
                    label: '報名紀錄',
                    key: 'traveler-attends',
                    icon: <FormOutlined />,
                },
                {
                    label: '基本資料',
                    key: 'traveler-profile',
                    icon: <ProfileOutlined />,
                },
                {
                    label: '我的成就',
                    key: 'traveler-achievements',
                    icon: <StarOutlined />,
                },
                {
                    label: '我的裝備',
                    key: 'traveler-equipments',
                    icon: <StarOutlined />,
                },
                {
                    label: '我的證照',
                    key: 'traveler-certificates',
                    icon: <StarOutlined />,
                },
            ]
        },
        {
            type: 'group',
            label: '管理',
            key: 'g-admin',
            roles: [PermissionManager.ROLE_ADMIN],
            children: [
                {
                    label: '行程管理',
                    key: 'admin-tours',
                    icon: <CarOutlined />,
                },
                {
                    label: '旅者管理',
                    key: 'admin-travelers',
                    icon: <UserOutlined />,
                },
                {
                    label: '地點管理',
                    key: 'admin-places',
                    icon: <CoffeeOutlined />,
                },
                {
                    label: '地區管理',
                    key: 'admin-regions',
                    icon: <GlobalOutlined />,
                },
                {
                    label: '分類管理',
                    key: 'admin-categories',
                    icon: <GlobalOutlined />,
                },
                {
                    label: '帳號管理',
                    key: 'admin-users',
                    icon: <UserOutlined />,
                },
                {
                    label: '成就管理',
                    key: 'admin-achievements',
                    icon: <StarOutlined />,
                },
                {
                    label: '證照管理',
                    key: 'admin-certifications',
                    icon: <StarOutlined />,
                },
            ],
        },
        {
            label: '登出',
            key: 'anonymous-logout',
            hidden: !isLoggedIn(),
            icon: <LogoutOutlined />,
            roles: [PermissionManager.ROLE_ANONYMOUS],
        },
    ];
    const handleClick = ({ item, key, keyPath, domEvent }) => {
        console.log(domEvent, key, keyPath);
        switch (key) {
            case 'anonymous-home':
                navigate('/');
                break;
            // case 'anonymous-login':
            //     if (!isLoggedIn()) {
            //         navigate('/login');
            //     }
            //     break;
            case 'anonymous-logout':
                if (isLoggedIn()) {
                    handleLogout();
                    navigate('/');
                }
                break;
            case 'admin-tours':
                navigate('/admin/tours');
                break;

            case 'admin-travelers':
                navigate('/admin/travelers');
                break;
            case 'admin-places':
                navigate('/admin/places');
                break;
            case 'admin-regions':
                navigate('/admin/regions');
                break;
            case 'admin-categories':
                navigate('/admin/categories');
                break;
            case 'admin-users':
                navigate('/admin/users');
                break;
            case 'admin-achievements':
                navigate('/admin/achievements');
                break;
            case 'admin-certifications':
                navigate('/admin/certifications');
                break;
            case 'traveler-tours':
                navigate('/traveler/tours');
                break;
            case 'traveler-attends':
                navigate('/traveler/attends');
                break;
            case 'traveler-profile':
                navigate('/traveler/profile');
                break;
            case 'traveler-achievements':
                navigate('/traveler/achievements');
                break;
            case 'traveler-equipments':
                navigate('/traveler/equipments');
                break;
            case 'traveler-certificates':
                navigate('/traveler/certificates');
                break;
            default:
                break;
        }
        if (onClick) {
            onClick();
        }
    }

    console.log(PermissionManager.getRoles());
    const filteredItems = items.filter((item) => {
        return PermissionManager.getRoles().reduce((pv, role, idx) => {
            return pv || item.roles.indexOf(role) >= 0
        }, false);
    });
    console.log(filteredItems);
    return (
        <AntdMenu theme="dark" mode="inline" defaultSelectedKeys={["0"]} items={filteredItems} onClick={handleClick} />
    )
}

export default Menu