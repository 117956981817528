import React, { Component } from 'react'
import { Form, Input, Spin, notification, Button, Select } from 'antd';
import O2ToursAPI from '../api/O2ToursAPI';
import Modal from 'antd/lib/modal/Modal';
import RegionSelect from './RegionSelect';

export class PlaceEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            mode: 'store',
            isSearchingPlace: false,
            placeQueryResults: [],
        };
        this.form = React.createRef();
        this.searchHandler = null;
        this.regionSelect = React.createRef();
    }
    componentDidMount() {
        const placeId = this.props.placeId;
        this.fetchData(placeId);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.placeId !== this.props.placeId) {
            this.fetchData(this.props.placeId);
        }
    }
    refreshSelect = () => {
        this.regionSelect.current.fetchByValue();
    }
    fetchData = (placeId) => {
        if (!placeId) return;
        this.setState({ isLoading: true, mode: 'update', id: placeId });
        O2ToursAPI.places.show(placeId).then((response) => {
            const data = response.data;
            console.log(data);
            this.form.current.setFieldsValue(data);
            this.refreshSelect();
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    };
    onFinish = (values) => {
        console.log("PlaceCard: onFinish: ", values)
        if (this.state.mode === 'store') {
            this.setState({ isLoading: true });
            O2ToursAPI.places.store(values).then((response) => {
                notification.info({
                    message: "新增景點成功"
                });
                this.form.current.resetFields();
                if (this.props.onAdd) {
                    this.props.onAdd(response.data);
                }
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        } else {
            this.setState({ isLoading: true });
            O2ToursAPI.places.update(this.state.id, values).then((response) => {
                notification.info({
                    message: "修改景點成功"
                });
                this.form.current.resetFields();
                if (this.props.onUpdate) {
                    this.props.onUpdate(response.data);
                }
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        }

    }
    onFinishFailed = (errorInfo) => {
        console.log(errorInfo);
    }
    handleOk = () => {
        this.form.current.submit();
    }
    handleCancel = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }
    handlePreviewGps = () => {
        const gpsLocation = this.form.current.getFieldValue('gpslocation');
        if (!gpsLocation) {
            notification.error({ message: "請輸入 GPS 位置" });
            return;
        }
        window.open('https://www.google.com/maps/search/?api=1&query=' + gpsLocation, 'previewGpsLocation')
    }
    handlePreviewUrl = () => {
        const navigateurl = this.form.current.getFieldValue('navigateurl');
        if (!navigateurl) {
            notification.error({ message: "請輸入導航網址" });
            return;
        }
        window.open(navigateurl, 'previewNagivateUrl')
    }
    handlePlaceSearchInput = (e) => {
        if (!e.target.value || this.state.mode === 'update') return;
        this.handlePlaceSearch(e.target.value);
    }
    handlePlaceSearch = (value) => {
        if (this.searchHandler) {
            clearTimeout(this.searchHandler);
        }
        this.setState({ isSearchingPlace: true });
        this.searchHandler = setTimeout(() => {
            O2ToursAPI.places.search(value).then((response) => {
                // console.log(response);
                this.setState({ isSearchingPlace: false, placeQueryResults: response.data.predictions, selectedPlace: null });
            }).catch((error) => {
                console.log(error);
                notification.error({ message: error.message });
            });
        }, 1000);
    }
    handlePlaceSelect = (index) => {
        const placeData = this.state.placeQueryResults[index];
        this.setState({ selectedPlace: placeData });
        this.form.current.setFieldsValue({
            navigateurl: 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(placeData.description) + '&query_place_id=' + encodeURIComponent(placeData.place_id)
        });
    }
    handleResolveGpsUrl = (e) => {
        const regex = /.*@(\d{1,}.\d{1,},\d{1,}.\d{1,}).*/g;
        const subst = `$1`;
        let value = e.target.value;

        // The substituted value will be contained in the result variable
        if (value.match(regex, subst)) {
            const result = value.replace(regex, subst);
            this.form.current.setFieldsValue({
                gpslocation: result
            });
        }
    }
    render() {
        return (
            <Modal title={this.state.mode === 'store' ? "新增地點" : "編輯地點 " + this.state.id} okText="確定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} {...this.props}>
                <Spin spinning={this.state.isLoading}>
                    <Form
                        labelCol={{ span: 4 }}
                        ref={this.form}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        initialValues={{
                            // name: "車城福安宮",
                            // gpslocation: "22.6304461,120.2844837",
                            // description: "車城福安宮是東南亞最大的土地公廟，香火鼎盛，至今已經超過35",
                            // navigateurl: "https://goo.gl/maps/Ux4XbZue82KefqeP6",
                            // address: "屏東縣車城鄉保新路64-13號",
                            // operationtime: "11:00~14:00／17:00~21:00（週二、週三公休）"
                        }}
                    >
                        <Form.Item label="地點名稱" name="name">
                            <Input placeholder="車城福安宮" onChange={this.handlePlaceSearchInput} />
                        </Form.Item>
                        <Form.Item label="地點描述" name="description">
                            <Input.TextArea rows={4} placeholder="車城福安宮是東南亞最大的土地公廟，香火鼎盛，至今已經超過35..." />
                        </Form.Item>
                        <Form.Item label="地址" name="address">
                            <Input placeholder="屏東縣車城鄉保新路64-13號" />
                        </Form.Item>
                        <Form.Item label="地區" name="region_id">
                            <RegionSelect
                                ref={this.regionSelect}
                                placeholder="搜尋地區"
                            />
                        </Form.Item>
                        <Form.Item label="營業時間" name="operationtime">
                            <Input.TextArea rows={4} placeholder="星期二 11:00–17:00
星期三 11:00–17:00
星期四 11:00–17:00
" />
                        </Form.Item>
                        <Form.Item label="官網名稱" name="officialwebname">
                            <Input placeholder="連結名稱" />
                        </Form.Item>
                        <Form.Item label="官網連結" name="officialwebname">
                            <Input placeholder="https://example.com" />
                        </Form.Item>
                        <Form.Item label="導航網址" style={{ marginBottom: 0 }}>
                            <Form.Item>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={this.handlePlaceSelect}
                                    onSearch={this.handlePlaceSearch}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    loading={this.state.isSearchingPlace}
                                    style={{ maxWidth: 300 }}
                                    placeholder="地點搜尋"
                                >
                                    {this.state.placeQueryResults.map((row, index) => <Select.Option key={index}>{row.description}</Select.Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="navigateurl"
                                rules={[{ type: 'url', message: '網址格式錯誤，例: https://goo.gl/maps/Ux4XbZue82KefqeP6' }]}
                                style={{ display: 'inline-block', width: 'calc(100% - 100px)', marginRight: 10 }}>
                                <Input placeholder="https://goo.gl/maps/Ux4XbZue82KefqeP6" />
                            </Form.Item>
                            <Button onClick={this.handlePreviewUrl}>預覽</Button>
                        </Form.Item>
                        <Form.Item label="GPS 位置" style={{ marginBottom: 0 }}>
                            <Form.Item>
                                <Input placeholder="Google GPS 網址，例: https://www.google.com/maps/place/22%C2%B017'54.4%22N+120%C2%B044'46.9%22E/@22.2984389,120.7441663,17z/data=!3m1!4b1!4m14!1m7!3m6!1s0x0:0x35f08cf1dd065592!2zMjLCsDA1JzMyLjIiTiAxMjDCsDQzJzE5LjQiRQ!3b1!8m2!3d22.09229!4d120.7220623!3m5!1s0x0:0x7ec067d654d93d1a!7e2!8m2!3d22.2984341!4d120.7463554" onChange={this.handleResolveGpsUrl} />
                            </Form.Item>
                            <Form.Item
                                name="gpslocation"
                                rules={[{ pattern: '^([-+]?)([\\d]{1,2})(((\\.)(\\d+)(,)))(\\s*)(([-+]?)([\\d]{1,3})((\\.)(\\d+))?)$', message: '格式錯誤，例: 22.6304461,120.2844837' }]}
                                style={{ display: 'inline-block', width: 'calc(100% - 100px)', marginRight: 10 }}>
                                <Input placeholder="22.6304461,120.2844837" />
                            </Form.Item>
                            <Button onClick={this.handlePreviewGps}>預覽</Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>

        )
    }
}

export default PlaceEditModal
