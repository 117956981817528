import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import O2ToursAPI from '../api/O2ToursAPI'


function TransportationSelect({ value, onChange, ...props }) {
    const [transportations, setTransportations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectValue, setSelectValue] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        O2ToursAPI.transportations.index({}, 1, 999).then((response) => {
            const result = response.data;
            setTransportations(result);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        console.log("value", value);
        setSelectValue(value?.map((row) => row.transportation_id));
    }, [value]);

    const handleChange = (value) => {
        console.log("handleChange", value);
        setSelectValue(value);
        if (onChange) {
            onChange(value?.map((transportationId) => ({ transportation_id: transportationId })));
        }
    }
    return (
        <Select mode="multiple" value={selectValue} onChange={handleChange} loading={isLoading} allowClear {...props}>
            {transportations.map((row) => <Select.Option key={row.id} value={row.id}>{row.name}</Select.Option>)}
        </Select>
    )
}


export default TransportationSelect