import { Avatar, Button, Form, Input, Modal, notification, Popconfirm, Space, Spin, Table, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useState } from 'react'
import { useEffect } from 'react';
import O2ToursAPI from '../../api/O2ToursAPI'
import AvatorUpload from '../../components/AvatorUpload';

function TravelerEquipmentsPage() {
    const [equipments, setEquipments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editEquipmentModal, setEditEquipmentModal] = useState({
        open: false,
        id: null,
    });
    const [editEquipmentModalLoading, setEditEquipmentModalLoading] = useState(false);
    const [editEquipmentForm] = useForm();
    const [selectedPhoto, setSelectedPhoto] = useState();

    const refresh = useCallback(() => {
        setLoading(true);
        O2ToursAPI.traveler.equipments.index({}, 1, 999).then((response) => {
            const result = response.data;
            setEquipments(result.data);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const handleShowEditEquipmentModal = (id) => {
        setEditEquipmentModal({
            open: true,
            id: id,
        });
        if (id) {
            O2ToursAPI.traveler.equipments.show(id).then((response) => {
                const result = response.data;
                editEquipmentForm.setFieldsValue(result);
            }).finally(() => {

            });
        }
    }
    const handleOkEditEquipmentModal = () => {
        editEquipmentForm?.validateFields().then((values) => {
            editEquipmentForm.submit();
        });
    }
    const handleFinishEditEquipmentModal = (values) => {
        if (!editEquipmentModal.id) {
            setEditEquipmentModalLoading(true);
            O2ToursAPI.traveler.equipments.store(values, selectedPhoto).then((response) => {
                notification.info({ message: '新增裝備成功' });
            }).finally(() => {
                setEditEquipmentModalLoading(false);
                handleCloseEquipmentModal();
                refresh();
            })
        } else {
            setEditEquipmentModalLoading(true);
            O2ToursAPI.traveler.equipments.update(editEquipmentModal.id, values, selectedPhoto).then((response) => {
                notification.info({ message: '修改裝備成功' });
            }).finally(() => {
                setEditEquipmentModalLoading(false);
                handleCloseEquipmentModal();
                refresh();
            });
        }
    }
    const handleDeleteEquipment = (id) => {
        O2ToursAPI.traveler.equipments.destroy(id).then((response) => {
            notification.info({ message: '刪除裝備成功' });
        }).finally(() => {
            refresh();
        });
    }
    const handleCloseEquipmentModal = () => {
        setEditEquipmentModal({
            open: false,
            id: null,
        });
        setSelectedPhoto(null);
        editEquipmentForm.resetFields();
    }
    const handleSelectPhoto = (photo) => {
        setSelectedPhoto(photo);
    }
    return (
        <>
            <Button onClick={() => handleShowEditEquipmentModal()}>新增裝備</Button>
            <Table loading={loading} columns={[
                {
                    dataIndex: 'imagefile',
                    title: '照片',
                    render: (value, record) => <Avatar shape="square" size={64} src={process.env.REACT_APP_URL + record.thumbnailfileurl} />,
                },
                {
                    dataIndex: 'name',
                    title: '裝備名稱',
                },
                {
                    dataIndex: 'description',
                    title: '說明',
                },
                {
                    title: '操作',
                    render: (value, record) => <Space>
                        <Typography.Link onClick={() => handleShowEditEquipmentModal(record.id)}>修改</Typography.Link>
                        <Popconfirm title="是否要刪除裝備?" onConfirm={() => handleDeleteEquipment(record.id)}>
                            <Typography.Link>刪除</Typography.Link>
                        </Popconfirm>
                    </Space>
                },
            ]} dataSource={equipments} rowKey="id" />
            <Modal title="新增/修改裝備" open={editEquipmentModal.open} onOk={handleOkEditEquipmentModal} onCancel={handleCloseEquipmentModal}>
                <Spin spinning={editEquipmentModalLoading}>
                    <Form form={editEquipmentForm} onFinish={handleFinishEditEquipmentModal}>
                        <Form.Item label="裝備名稱" name="name">
                            <Input />
                        </Form.Item>
                        <Form.Item label="說明" name="description">
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item label="照片" name="thumbnailfileurl">
                            <AvatorUpload value={selectedPhoto} onChange={handleSelectPhoto} />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
}

export default TravelerEquipmentsPage