import { Button, notification, Popconfirm, Space, Spin, Table, Typography } from 'antd'
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import O2ToursAPI from '../../api/O2ToursAPI';
import { MenuOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

export class CategoryPage extends Component {
    state = {
        loading: false,
        data: [],
    };
    componentDidMount() {
        this.refresh();
    }
    refresh = () => {
        O2ToursAPI.categories.index(1, 99).then((response) => {
            const result = response.data;
            console.log(result);
            this.setState({ data: result.data });
        });
    }
    handleDelete = (id) => {
        this.setState({ loading: true });
        O2ToursAPI.categories.destroy(id).then((response) => {
            notification.info({ message: '刪除成功' });
            this.refresh();
        }).catch((error) => {
            notification.info({ message: '刪除失敗' });
        }).finally(() => {
            this.setState({ loading: false });
        });
    }
    render() {
        const DragHandle = SortableHandle(() => (
            <MenuOutlined
                style={{
                    cursor: 'grab',
                    color: '#999',
                }}
            />
        ));
        const SortableItem = SortableElement((props) => <tr {...props} />);
        const SortableBody = SortableContainer((props) => <tbody {...props} />);
        const columns = [
            {
                title: '排序',
                dataIndex: 'index',
                width: 30,
                className: 'drag-visible',
                render: () => <DragHandle />,
            },
            // {
            //     title: 'ID',
            //     key: 'id',
            //     dataIndex: 'id'
            // },
            {
                title: '名稱',
                key: 'name',
                dataIndex: 'name',
                className: 'drag-visible',
            },
            {
                title: '類型',
                key: 'type',
                dataIndex: 'type'
            },
            {
                title: '行程數',
                key: 'tours_count',
                dataIndex: 'tours_count',
                // 排序跟 Sorter 不能併用
                // sorter: (a, b) => a.tours_count - b.tours_count
            },
            {
                title: '更新於',
                key: 'created_at',
                dataIndex: 'created_at',
                render: (value) => new Date(value).toLocaleString()
            },
            {
                title: '建立於',
                key: 'updated_at',
                dataIndex: 'updated_at',
                render: (value) => new Date(value).toLocaleString()
            },
            {
                title: '動作',
                render: (value, record) => <Space>
                    <Link to={'/admin/categories/' + record.id}>修改</Link>
                    <Popconfirm title={`你是否確定要刪除 ${record.name}?`} onConfirm={this.handleDelete.bind(this, record.id)} okText="是" cancelText="否">
                        <Typography.Link>刪除</Typography.Link>
                    </Popconfirm>
                </Space>
            }
        ];
        const onSortEnd = ({ oldIndex, newIndex }) => {
            if (oldIndex !== newIndex) {
                console.log("Move item", this.state.data[oldIndex], "from", oldIndex, "to", newIndex);
                // 更新分類索引
                const newCategories = arrayMoveImmutable(this.state.data.slice(), oldIndex, newIndex).filter(
                    (el) => !!el,
                );
                // console.log('Sorted items: ', newData);
                let updateIndexes = [];
                newCategories.forEach((item, index) => {
                    if (item.index === index) return;
                    // 所引不同的進行更新
                    console.log("Update category ", item.id, " index from ", item.index, "to", index);
                    updateIndexes.push({ id: item.id, index: index });
                });
                this.setState({ loading: true });
                O2ToursAPI.categories.sortIndex.update(updateIndexes).then(() => {
                    this.setState({ data: newCategories });
                }).finally(() => {
                    this.setState({ loading: false });
                });
            }
        };

        const DraggableContainer = (props) => (
            <SortableBody
                useDragHandle
                disableAutoscroll
                helperClass="row-dragging"
                onSortEnd={onSortEnd}
                {...props}
            />
        );

        const rowKey = "id";
        const DraggableBodyRow = ({ className, style, ...restProps }) => {
            // function findIndex base on Table rowKey props and should always be a right array index
            // console.log('restProps', restProps);
            const index = this.state.data.findIndex((x) => x[rowKey] === restProps['data-row-key']);
            return <SortableItem index={index} {...restProps} />;
        };

        return (
            <Spin spinning={this.state.loading}>
                <Link to="/admin/categories/create">
                    <Button type="primary">新增</Button>
                </Link>
                <Table columns={columns} dataSource={this.state.data} pagination={false} rowKey={rowKey} components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
                />
            </Spin>
        )
    }
}

export default CategoryPage