import React, { Component } from 'react'
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types'

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export class AvatorUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      previewBase64Image: this.getImageFullUrl(props.value),
    };
  }

  getImageFullUrl = (relativePath) => {
    if (!relativePath) return relativePath;
    return process.env.REACT_APP_URL + relativePath;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.setState({ previewBase64Image: this.getImageFullUrl(this.props.value) });
    }
  }

  handleChange = info => {
    console.log(info.file);
    getBase64(info.file.originFileObj, previewBase64Image =>
      this.setState({
        previewBase64Image,
        loading: false,
      }),
    );
    if (this.props.onChange) {
      this.props.onChange(info.file.originFileObj);
    }
  };
  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('只能上傳 JPG/PNG 檔案!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('圖片大小必須於 10MB!');
      return false;
    }
    return true;
  }
  upload = (callback) => {
    // 選完圖片後不直接上傳，直接假裝上傳成功，透過 this.props.onChange() 回傳選擇檔案待由表單統一處理上傳
    callback.onSuccess();
  }
  render() {
    const { loading, previewBase64Image } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>選擇封面圖</div>
      </div>
    );
    return (
      <Upload
        name="avator"
        listType="picture-card"
        className="avator-uploader"
        showUploadList={false}
        beforeUpload={this.beforeUpload}
        onChange={this.handleChange}
        customRequest={this.upload}
      >
        {previewBase64Image ? <img src={previewBase64Image} alt="avator" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    );
  }
}

AvatorUpload.propTypes = {
  /**
   * 更改照片
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Base64 圖片資料
   */
  value: PropTypes.string,
};

export default AvatorUpload;