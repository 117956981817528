import { Form, Input, Modal, Select, Spin } from 'antd'
import React, { Component } from 'react'
import O2ToursAPI from '../api/O2ToursAPI';

export class TravelerSocialMediaModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            mode: 'store',
            isLoading: false,
            socialmedias: [],
            travelerSocialMedias: [],
        }
        this.form = React.createRef();
    }
    componentDidMount() {
        this.fetchSocialMedias();
        const id = this.props.id;
        this.fetchData(id);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id) {
            this.fetchData(this.props.id);
        }
    }
    fetchData = (id) => {
        if (!id) {
            this.setState({ mode: 'store', id: null });
            return;
        }
        console.log("fetchData", id);
        this.setState({ isLoading: true });
        if (this.props.onShow) {
            this.props.onShow(id).then((response) => {
                const result = response.data;
                this.form.current.setFieldsValue(result);
                this.setState({ mode: 'update', id: result.id });
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        }
        // O2ToursAPI.travelers.socialmedias.show(travelerId, id).then((response) => {
        //     const result = response.data;
        //     this.form.current.setFieldsValue(result);
        //     this.setState({ mode: 'update', id: result.id });
        // }).finally(() => {
        //     this.setState({ isLoading: false });
        // });
    }
    fetchSocialMedias = () => {
        return O2ToursAPI.socialmedias.index({}, 1, 999).then((response) => {
            const result = response.data;
            this.setState({ socialmedias: result });
        });
    }
    handleOk = () => {
        this.form.current.submit();
    }
    handleCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }
    onFinished = (values) => {
        console.log("onFinished", values);
        this.setState({ isLoading: true });
        if (this.state.mode === 'store') {
            if (this.props.onStore) {
                this.props.onStore(values).then((response) => {
                    const result = response.data;
                    console.log("O2ToursAPI.travelers.socialmedias.store", result);
                    if (this.props.onOk) {
                        this.props.onOk(result);
                    }
                }).finally(() => {
                    this.setState({ isLoading: false });
                })
            }
            // O2ToursAPI.travelers.socialmedias.store(this.props.travelerId, values).then((response) => {
            //     const result = response.data;
            //     console.log("O2ToursAPI.travelers.socialmedias.store", result);
            //     if (this.props.onOk) {
            //         this.props.onOk(result);
            //     }
            // }).finally(() => {
            //     this.setState({ isLoading: false });
            // })
        } else {
            if (this.props.onUpdate) {
                this.props.onUpdate(this.props.id, values).then((response) => {
                    const result = response.data;
                    console.log("O2ToursAPI.travelers.socialmedias.update", result);
                    if (this.props.onOk) {
                        this.props.onOk(result);
                    }
                }).finally(() => {
                    this.setState({ isLoading: false });
                })
            }
            // O2ToursAPI.travelers.socialmedias.update(this.props.travelerId, this.props.id, values).then((response) => {
            //     const result = response.data;
            //     console.log("O2ToursAPI.travelers.socialmedias.update", result);
            //     if (this.props.onOk) {
            //         this.props.onOk(result);
            //     }
            // }).finally(() => {
            //     this.setState({ isLoading: false });
            // })
        }

    }
    render() {
        return (
            <Modal title={(this.state.mode === 'store' ? "新增" : "修改") + "社交媒體"} open={this.props.open} onOk={this.handleOk} onCancel={this.handleCancel}>
                <Spin spinning={this.state.isLoading}>
                    <Form ref={this.form} onFinish={this.onFinished}>
                        <Form.Item label="類型" name="socialmedia_id">
                            <Select>
                                {this.state.socialmedias.map((row) => <Select.Option key={row.id} value={row.id}>{row.name}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label="網址" name="socialmedia_url">
                            <Input placeholder="https://www.facebook.com/o2.tours.world/" />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

export default TravelerSocialMediaModal