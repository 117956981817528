import { Select } from 'antd'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import O2ToursAPI from '../../../api/O2ToursAPI';

const TravelerTourPlanSelect = forwardRef(({ tourId, ...props }, ref) => {
    const [isLoadingPlans, setIsLoadingPlans] = useState(false);
    const [plans, setPlans] = useState([]);

    const refreshPlans = useCallback(() => {
        if (!tourId) return;
        setIsLoadingPlans(true);
        O2ToursAPI.traveler.tours.plans.index(tourId, 0, 999).then((response) => {
            const result = response.data.data;
            setPlans(result);
        }).finally(() => {
            setIsLoadingPlans(false);
        });
    }, [tourId]);

    useEffect(() => {
        refreshPlans();
    }, [refreshPlans]);

    useImperativeHandle(ref, () => ({
        refresh: refreshPlans
    }));

    return (
        <Select loading={isLoadingPlans} {...props}>
            {plans.map((plan) => <Select.Option key={plan.id} value={plan.id}>{plan.name}</Select.Option>)}
        </Select>
    )
});

export default TravelerTourPlanSelect