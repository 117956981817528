import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import O2ToursAPI from '../api/O2ToursAPI';

function AchivementTypeSelect({ ...props }) {
    const [achivementTypes, setAchivementTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        O2ToursAPI.achievementTypes.index({}, 1, 999).then((response) => {
            const result = response.data;
            setAchivementTypes(result.data);
        }).finally(() => {
            setLoading(false);
        });
    }, []);
    return (
        <Select loading={loading} {...props}>
            {achivementTypes.map((achivementType) =>
                <Select.Option key={achivementType.id} value={achivementType.id}>
                    {achivementType.name}
                </Select.Option>)}
        </Select>
    )
}

export default AchivementTypeSelect