import { Button, Divider, notification, Select, Spin, Typography } from 'antd';
import React, { Component } from 'react'
import O2ToursAPI from '../api/O2ToursAPI';
import { PlusOutlined } from '@ant-design/icons';

export class PlaceSelect extends Component {
    timeoutHandler;
    currentSearchName;
    select;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            places: [],
        }
        this.select = React.createRef();
    }
    componentDidMount() {
        this.fetchByValue();
    }
    fetchByValue = () => {
        const placeId = this.props.value;
        this.fetchData({ id: placeId });
    }
    fetchData = (query) => {
        // let query = {
        //     id: id,
        //     name: searchName
        // };
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler);
            this.timeoutHandler = null;
        }
        this.currentSearchName = query.name;

        this.setState({ isLoading: true });
        let search = () => {
            O2ToursAPI.places.index(query).then((response) => {
                // 若回傳的結果不是目前實際搜尋的內容則略過 (回應時已經更改輸入的文字)
                if (this.currentSearchName === query.name) {
                    this.setState({ places: response.data.data });
                }
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        }
        this.timeoutHandler = setTimeout(search, 300);
    }
    handleSearch = (searchName) => {
        // 有輸入名稱時才發請求
        if (searchName) {
            this.fetchData({ name: searchName });
        } else {
            this.setState({ places: [] });
        }
    }
    addPlace = () => {
        if (this.props.onAddItem) {
            this.props.onAddItem();
        }
        console.log("Add place");
    }
    updatePlace = () => {
        if (this.props.onUpdateItem) {
            this.props.onUpdateItem(this.props.value);
        }
        console.log("Update Place", this.props.value);
    }
    previewUrl = () => {
        const placeId = this.props.value;
        const row = this.state.places.find((row) => row.id === placeId);
        if (!row) {
            notification.error({ message: "請選擇景點" });
            return;
        }
        const navigateurl = row.navigateurl;
        if (!navigateurl) {
            notification.error({ message: "請輸入導航網址" });
            return;
        }
        window.open(navigateurl, 'previewNagivateUrl')
    }
    render() {
        const { onAddItem, ...selectProps } = this.props; // Exclude onAddItem
        return (
            <div>
                <Spin spinning={this.state.isLoading}>
                    <Select
                        ref={this.select}
                        {...selectProps}
                        onSearch={this.handleSearch}
                        dropdownRender={(menu) => <div>
                            {menu}
                            <Divider style={{ margin: '2px 0' }} />
                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 2 }}>
                                <Typography.Link
                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                    onClick={this.addPlace}
                                >
                                    <PlusOutlined /> 新增景點
                                </Typography.Link>
                            </div>
                        </div>}
                    >
                        {
                            this.state.places.map((row) => <Select.Option key={row.id} value={row.id}>{row.name} ({row.id})</Select.Option>)
                        }
                    </Select>
                    <Button style={{ marginLeft: 10 }} onClick={this.previewUrl}>預覽</Button>
                    <Button style={{ marginLeft: 10 }} onClick={this.updatePlace}>編輯</Button>
                </Spin>
            </div>
        )
    }
}

export default PlaceSelect
