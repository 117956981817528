import { Button, Modal, notification, Space, Spin, Table, Typography } from 'antd';
import React, { useCallback } from 'react'
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react'
import O2ToursAPI from '../../api/O2ToursAPI';
// import AvatorUpload from '../../components/AvatorUpload';
import TravelerForm from '../../components/forms/TravelerForm';
import TravelerSocialMediaModal from '../../components/TravelerSocialMediaModal';

function TravelerProfilePage() {
  const [isLoading, setIsLoading] = useState(false);
  const [, setId] = useState(null);
  const [socialmedias, setSocialMedias] = useState([]);
  const [follows, setFollows] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [travelerSocialMedias, setTravelerSocialMedias] = useState([]);
  const [addOrUpdateTravelerSocialMediaModalVisible, setAddOrUpdateTravelerSocialMediaModalVisible] = useState(false);
  const [updateTravelerSocialMediaId, setUpdateTravelerSocialMediaId] = useState(null);
  const [deleteTravelerSocialMediaModalVisible, setDeleteTravelerSocialMediaModalVisible] = useState(false);
  const [deleteTravelerSocialMediaId, setDeleteTravelerSocialMediaId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const form = useRef();

  const fetchData = useCallback(() => {
    setIsLoading(true);
    O2ToursAPI.traveler.show().then((response) => {
      const data = response.data;
      console.log(data);
      form.current.setFieldsValue(data);
      const travelerId = data.id;
      setId(travelerId);
      fetchFollows(travelerId);
      fetchFollowers(travelerId);
    }).finally(() => {
      setIsLoading(false);
    });
    fetchTravelerSocialMedias();
  }, []);

  // TODO: API Not implemented
  const fetchSocialMedias = () => {
    return O2ToursAPI.socialmedias.index({}, 1, 999).then((response) => {
      const result = response.data;
      setSocialMedias(result);
    });
  }
  const fetchTravelerSocialMedias = async () => {
    O2ToursAPI.traveler.socialmedias.index({}, 1, 999).then((response) => {
      const result = response.data;
      setTravelerSocialMedias(result.data);
    })
  }
  // TODO: API Not implemented
  const fetchFollows = (travelerId) => {
    O2ToursAPI.traveler.follows.index(travelerId, {}, 1, 999).then((response) => {
      const result = response.data;
      setFollows(result.data);
    });
  }
  // TODO: API Not implemented
  const fetchFollowers = (travelerId) => {
    O2ToursAPI.traveler.followers.index(travelerId, {}, 1, 999).then((response) => {
      const result = response.data;
      setFollowers(result.data);
    });
  }

  useEffect(() => {
    fetchSocialMedias();
    fetchData();
  }, [fetchData]);

  const onFinish = (values) => {
    setIsLoading(true);
    O2ToursAPI.traveler.update(values).then((response) => {
      notification.info({
        message: "修改成功"
      });
      uploadAvator();
    }).finally(() => {
      setIsLoading(false);
    });
  }
  const showTravelerSocialMediaAddModal = () => {
    console.log("showTravelerSocialMediaAddModal");
    setAddOrUpdateTravelerSocialMediaModalVisible(true);
    setUpdateTravelerSocialMediaId(null);
  }
  const showTravelerSocialMediaUpdateModal = (id) => {
    console.log("showTravelerSocialMediaUpdateModal", id);
    setAddOrUpdateTravelerSocialMediaModalVisible(true);
    setUpdateTravelerSocialMediaId(id);
  }
  const hideTravelerSocialMediaModal = () => {
    setAddOrUpdateTravelerSocialMediaModalVisible(false);
  }
  const handleUpdateTravelerSocialMedia = (values) => {
    console.log("addTravelerSocialMedia", values);
    hideTravelerSocialMediaModal();
    fetchTravelerSocialMedias();
  }
  const showTravelerSocialMediaDeleteModal = (id) => {
    console.log("confirmDeleteTravelerSocialMedia", id);
    setDeleteTravelerSocialMediaModalVisible(true);
    setDeleteTravelerSocialMediaId(id);
  }
  const hideTravelerSocialMediaDeleteModal = () => {
    setDeleteTravelerSocialMediaModalVisible(false);
    setDeleteTravelerSocialMediaId(null);
  }
  const deleteTravelerSocialMedia = () => {
    const id = deleteTravelerSocialMediaId;
    O2ToursAPI.traveler.socialmedias.destroy(id).then((response) => {
      console.log("O2ToursAPI.traveler.socialmedias.destroy", response);
      fetchData();
    }).finally(() => {
      hideTravelerSocialMediaDeleteModal();
    });
  }
  const onSelectedAvatorFile = (file) => {
    console.log("Selected avator file", file);
    setSelectedFile(file);
  }
  const uploadAvator = () => {
    const coverImage = selectedFile;
    if (!coverImage) return;
    setIsLoading(true);
    O2ToursAPI.traveler.avator.store(coverImage).then((response) => {
      notification.info({
        message: "圖片上傳成功"
      });
    }).finally(() => {
      setIsLoading(false);
    });
  }
  const columns = [
    {
      title: '類型',
      dataIndex: 'socialmedia_id',
      key: 'socialmedia_id',
      render: (value) => {
        const row = socialmedias.find((row) => row.id === value);
        return row ? row.name : value;
      }
    },
    {
      title: '網址',
      dataIndex: 'socialmedia_url',
      key: 'socialmedia_url',
    },
    {
      title: '動作',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Typography.Link onClick={() => showTravelerSocialMediaUpdateModal(record.id)}>修改</Typography.Link>
          <Typography.Link onClick={() => showTravelerSocialMediaDeleteModal(record.id)}>刪除</Typography.Link>
        </Space>
      ),
    },
  ];
  return (
    <div>
      <Spin spinning={isLoading}>
        <h1>旅者基本資料</h1>
        <TravelerForm ref={form} isProfile={true} onFinish={onFinish} onSelectedAvatorFile={onSelectedAvatorFile} />
        <h3>社交媒體</h3>
        <>
          <div style={{ textAlign: 'right' }}>
            <Button onClick={showTravelerSocialMediaAddModal}>新增</Button>
          </div>
          <Table size="small" dataSource={travelerSocialMedias} columns={columns} rowKey="id" pagination={false} />
          <TravelerSocialMediaModal
            id={updateTravelerSocialMediaId}
            open={addOrUpdateTravelerSocialMediaModalVisible}
            onShow={(id) => O2ToursAPI.traveler.socialmedias.show(id)}
            onStore={(values) => O2ToursAPI.traveler.socialmedias.store(values)}
            onUpdate={(id, values) => O2ToursAPI.traveler.socialmedias.update(id, values)}
            onOk={handleUpdateTravelerSocialMedia}
            onCancel={hideTravelerSocialMediaModal} />
          <Modal
            title="刪除社交媒體"
            open={deleteTravelerSocialMediaModalVisible}
            onOk={deleteTravelerSocialMedia}
            onCancel={hideTravelerSocialMediaDeleteModal}
            okText="確定"
            cancelText="取消"
            confirmLoading={isLoading}
          >
            <p>你確定要刪除該社交媒體?</p>
          </Modal>
        </>
        <br />
        <h3>追蹤</h3>
        <Table size="small" columns={[
          {
            title: '名稱',
            dataIndex: 'traveler',
            key: 'traveler',
            render: (value, record) => value.name
          },
          {
            title: '帳號',
            dataIndex: 'traveler',
            key: 'traveler',
            render: (value, record) => value.account
          },
          {
            title: 'E-mail',
            dataIndex: 'traveler',
            key: 'traveler',
            render: (value, record) => value.email
          },
        ]} dataSource={follows} rowKey="traveler_id" pagination={false} />
        <br />
        <h3>被追蹤</h3>
        <Table size="small" columns={[
          {
            title: '名稱',
            dataIndex: 'follower',
            key: 'follower',
            render: (value, record) => value.name
          },
          {
            title: '帳號',
            dataIndex: 'follower',
            key: 'follower',
            render: (value, record) => value.account
          },
          {
            title: 'E-mail',
            dataIndex: 'follower',
            key: 'follower',
            render: (value, record) => value.email
          },
        ]} dataSource={followers} rowKey="follower_id" pagination={false} />
      </Spin>
    </div>
  )
}

export default TravelerProfilePage