import { Table, Space, Button, notification, Typography, Col, Row, Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import O2ToursAPI from '../../api/O2ToursAPI';

export class RegionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            pagination: {
                current: null,
                total: null,
                pageSize: null,
                onChange: (page, pageSize) => this.refresh(page, pageSize)
            },
            query: {},
            data: []
        };
        this.form = React.createRef();
    }
    componentDidMount() {
        this.refresh();
    }
    refresh = (page, pageSize) => {
        console.log("refresh");
        this.setState({ isLoading: true });
        O2ToursAPI.regions.index(this.state.query, page, pageSize).then((response) => {
            const results = response.data.data;
            console.log(results);
            const pagination = this.state.pagination;
            pagination.current = response.data.current_page;
            pagination.pageSize = response.data.per_page;
            // 若無回傳頁碼則自動依 next_page_url 推算總筆數為目前頁數 +1
            pagination.total = response.data.total || (response.data.next_page_url ? (pagination.current * pagination.pageSize + 1) : (pagination.current * pagination.pageSize))
            this.setState({
                pagination: pagination,
                data: results
            });
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }
    confirmDelete = (id) => {
        console.log("confirmDelete", id);
        this.setState({ confirmDeleteModalVisible: true, confirmDeleteId: id });
    }
    hideDelete = () => {
        console.log("hideDelete");
        this.setState({ confirmDeleteModalVisible: false, confirmDeleteId: null });
    }
    handleDelete = () => {
        const id = this.state.confirmDeleteId;
        this.setState({ isLoading: true });
        O2ToursAPI.regions.destroy(id).then((response) => {
            console.log("handleDelete", id, response);
            notification.info({
                message: "刪除成功"
            });
        }).finally(() => {
            this.setState({ isLoading: false, confirmDeleteModalVisible: false });
            this.refresh();
        });
    }
    handleSearch = (values) => {
        console.log(values);
        this.setState({ query: values }, () => this.refresh());
    }
    render() {
        const columns = [
            {
                title: '國家',
                dataIndex: 'country',
                key: 'country',
            },
            {
                title: '地區',
                dataIndex: 'citygroup',
                key: 'citygroup',
            },
            {
                title: '城市',
                dataIndex: 'city',
                key: 'city',
            },
            {
                title: '區域',
                dataIndex: 'district',
                key: 'district',
            },
            {
                title: '名稱',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => <Link to={'/admin/regions/' + record.id}>{text}</Link>,
            },
            {
                title: '動作',
                key: 'action',
                fixed: 'right',
                render: (text, record) => (
                    <Space size="middle">
                        <Link to={'/admin/regions/' + record.id}>修改</Link>
                        <Typography.Link onClick={this.confirmDelete.bind(this, record.id)}>刪除</Typography.Link>
                    </Space>
                ),
            },
        ];
        return (
            <div>
                <Form ref={this.form} onFinish={this.handleSearch}>
                    <Row gutter={10}>
                        <Col>
                            <Form.Item label="搜尋名稱" name="name">
                                <Input placeholder="台北市" allowClear={true} />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Button htmlType="submit">查詢</Button>
                        </Col>
                        <Col flex="auto" style={{ textAlign: 'right' }}>
                            <Link to="/admin/regions/create">
                                <Button type="primary">新增</Button>
                            </Link>
                        </Col>
                    </Row>
                </Form>

                <Table columns={columns} dataSource={this.state.data} loading={this.state.isLoading} rowKey="id" pagination={this.state.pagination} scroll={{ x: 'max-content' }} />
                <Modal
                    title="刪除地區"
                    open={this.state.confirmDeleteModalVisible}
                    onOk={this.handleDelete}
                    onCancel={this.hideDelete}
                    okText="確定"
                    cancelText="取消"
                    confirmLoading={this.state.isLoading}
                >
                    <p>你確定要刪除該地區?</p>
                </Modal>
            </div>
        )
    }
}

export default RegionPage
