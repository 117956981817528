import { Input, Form, Button, Spin, notification } from 'antd'
import React, { Component } from 'react'
import O2ToursAPI from '../../api/O2ToursAPI';
import TextEditor from '../../components/TextEditor';
import withRouterParamsHook from '../../withRouterParamsHook'

export class RegionEditPage extends Component {
    state = {
        mode: 'store',
        isLoading: false,
        id: null
    }
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    componentDidMount() {
        const regionId = this.props.params.regionId;
        this.fetchData(regionId);
    }
    fetchData = (regionId) => {
        if (!regionId) return;
        this.setState({ isLoading: true, mode: 'update', id: regionId });
        O2ToursAPI.regions.show(regionId).then((response) => {
            const data = response.data;
            console.log(data);
            this.form.current.setFieldsValue(data);
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }
    onFinish = (values) => {
        if (this.state.mode === 'store') {
            this.setState({ isLoading: true });
            O2ToursAPI.regions.store(values).then((response) => {
                notification.info({
                    message: "新增成功"
                });
                // switch to update mode
                //this.fetchData(response.data.id); 
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        } else {
            this.setState({ isLoading: true });
            O2ToursAPI.regions.update(this.state.id, values).then((response) => {
                notification.info({
                    message: "修改成功"
                });
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        }
    }
    render() {
        return (
            <div>
                <Spin spinning={this.state.isLoading}>
                    <h1>{this.state.mode === 'store' ? '新增地區':'編輯地區: ' + this.state.id}</h1>
                    <Form ref={this.form} labelCol={{ span: 2 }} onFinish={this.onFinish}>
                        <Form.Item label="國家" name="country">
                            <Input placeholder="台灣" />
                        </Form.Item>
                        <Form.Item label="地區" name="citygroup">
                            <Input placeholder="北部地區" />
                        </Form.Item>
                        <Form.Item label="城市" name="city">
                            <Input placeholder="基隆市" />
                        </Form.Item>
                        <Form.Item label="區域" name="district">
                            <Input placeholder="暖暖區" />
                        </Form.Item>
                        <Form.Item label="名稱" name="name">
                            <Input placeholder="基隆市 暖暖區" />
                        </Form.Item>
                        <Form.Item label="網頁關鍵字" name="metakeyword">
                            <Input />
                        </Form.Item>
                        <Form.Item label="介紹" name="description">
                            <TextEditor />
                        </Form.Item>
                        <Button htmlType="reset" style={{ marginRight: 5 }}>重設</Button>
                        <Button type="primary" htmlType="submit">送出</Button>
                    </Form>
                </Spin>
            </div>
        )
    }
}

export default withRouterParamsHook(RegionEditPage);
