import { notification } from 'antd';
import React, { Component } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import './TextEditor.css';
import PropTypes from 'prop-types';

export class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.quillRef = null; // Quill instance
        this.reactQuillRef = null; // ReactQuill component
    }
    componentDidMount() {
        this.attachQuillRefs();
    }

    componentDidUpdate() {
        this.attachQuillRefs();
    }
    selectLocalImage() {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();

        // Listen upload local image and save to server
        input.onchange = () => {
            const file = input.files[0];

            // file type is only image.
            if (/^image\//.test(file.type)) {
                this.saveToServer(file);
            } else {
                notification.warning({ message: '你只能上傳圖片' });
                console.warn('You could only upload images.');
            }
        };
    }
    saveToServer(file) {
        if (this.props.onUploadImage) {
            this.props.onUploadImage(file).then((url) => {
                console.log("insertToEditor", url);
                this.insertToEditor(url);
            })
        }
    }
    insertToEditor(url) {
        // push image url to rich editor.
        const range = this.quillRef.getSelection();
        this.quillRef.insertEmbed(range.index, 'image', `${url}`);
    }
    attachQuillRefs = () => {
        if (typeof this.reactQuillRef.getEditor !== 'function') return;
        this.quillRef = this.reactQuillRef.getEditor();

        if (this.quillRef) {
            const toolbar = this.quillRef.getModule('toolbar');
            toolbar.addHandler('image', (...values) => {
                console.log("TextEditor: toolbar: image: ", values);
                this.selectLocalImage();
            });
        }
    };
    render() {
        let linkBar = ['link', 'code'];
        let formats = [
            'header',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link',
            'code',
        ];

        if (this.props.onUploadImage) {
            formats.push('image');
            linkBar.push('image');
        }

        let modules = {
            toolbar: [
                [{ header: [1, 2, 3, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { indent: '-1' },
                    { indent: '+1' },
                ],
                linkBar,
                ['clean'],
            ],
        };

        return (<>
            <ReactQuill
                theme="snow"
                value={this.props.value || ''}
                modules={modules}
                formats={formats}
                onChange={this.props.onChange}
                placeholder={this.props.placeholder}
                ref={(el) => {
                    this.reactQuillRef = el;
                }}
            ></ReactQuill>
        </>
        )
    }
}

TextEditor.propTypes = {
    /**
     * onUploadImage(file)
     * 
     * @returns Promise
     */
    onUploadImage: PropTypes.func.isRequired,
}
export default TextEditor