import React, { Component } from 'react'
import { Upload, Modal, Spin, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export class TourDayPlacePhotoUpload extends Component {
  state = {
    isLoading: false,
    photos: [],
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [
      //   {
      //     uid: 'uid',      // unique identifier, negative is recommend, to prevent interference with internal generated id
      //     name: 'xx.png',   // file name
      //     status: 'done', // options：uploading, done, error, removed. Intercepted file by beforeUpload don't have status field.
      //     response: '{"status": "success"}', // response from server
      //     linkProps: '{"download": "image"}', // additional html props of file link
      //     xhr: 'XMLHttpRequest{ ... }', // XMLHttpRequest Header
      //  }
    ],
  };
  getImageFullUrl = (relativePath) => {
    if (!relativePath) return relativePath;
    return process.env.REACT_APP_URL + relativePath;
  }
  componentDidMount() {
    console.log("componentDidMount: props", this.props);
    const tourId = this.props.tourId;
    const tourDayId = this.props.tourDayId;
    const tourPlaceId = this.props.tourPlaceId;
    this.fetchData(tourId, tourDayId, tourPlaceId);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.props.tourId !== prevProps.tourId) ||
      (this.props.tourPlaceId !== prevProps.tourPlaceId) ||
      (this.props.tourDayId !== prevProps.tourDayId)) {
      console.log("componentDidUpdate: props", this.props);
      const tourId = this.props.tourId;
      const tourDayId = this.props.tourDayId;
      const tourPlaceId = this.props.tourPlaceId;
      this.fetchData(tourId, tourDayId, tourPlaceId);
    }
  }
  fetchData = (tourId, tourDayId, tourPlaceId) => {
    if (!tourId || !tourPlaceId) {
      return;
    }
    this.setState({ isLoading: true });
    if (this.props.onPlacePhotosIndex) {
      this.props.onPlacePhotosIndex(tourId, tourDayId, tourPlaceId, 1, 100).then((response) => {
        const photos = response.data.data;
        const fileList = [];
        photos.forEach((photo) => {
          fileList.push({
            uid: photo.id,
            name: photo.name,
            description: photo.description,
            status: 'done',
            url: this.getImageFullUrl(photo.image_file),
            response: photo
          });
        });
        this.setState({ photos: photos, fileList: fileList });
      }).finally(() => {
        this.setState({ isLoading: false });
      })
    }
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChange = (data) => {
    const { file, fileList, event } = data;
    console.log("onChange", file, fileList, event);
    switch (file.status) {
      case 'error':
      case 'success':
      case 'done':
      case 'uploading':
      case 'removed':
      default:
        break;
    }
    this.setState({ fileList: fileList });
  }

  removePhoto = (file) => {
    console.log("removePhoto", file);
    if (!file.response) {
      return true;
    }
    const id = file.response.id; // get photo id from response.
    const tourId = this.props.tourId;
    const tourDayId = this.props.tourDayId;
    const tourPlaceId = file.response.tour_place_id; // get placeId from response.

    if (!id || !tourPlaceId) {
      notification.error({
        message: 'Missing photo id or place id to remove photo'
      });
      return false;
    }

    this.setState({ isLoading: true });
    if (this.props.onPlacePhotosDestroy) {
      return this.props.onPlacePhotosDestroy(tourId, tourDayId, tourPlaceId, id).then((response) => {
        console.log(response);
      }).finally(() => {
        this.setState({ isLoading: false });
      });
    }
  }

  uploadPhoto = (callback) => {
    console.log("uploadPhoto", callback);
    const tourId = this.props.tourId;
    const tourDayId = this.props.tourDayId;
    const tourPlaceId = this.props.tourPlaceId;
    if (!tourPlaceId) {
      notification.error({
        message: 'Missing place Id to upload photo'
      });
      callback.onError('Missing place Id to upload photo');
      return;
    }
    if (this.props.onPlacePhotosStore) {
      this.props.onPlacePhotosStore(tourId, tourDayId, tourPlaceId, { name: 'unnamed', description: 'empty' }, callback.file).then((response) => {
        console.log(response);
        callback.onSuccess(response.data);
      }).catch((error) => {
        callback.onError(error);
      }).finally(() => {
        //
      });
    }
  }
  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>上傳</div>
      </div>
    );
    return (
      <Spin spinning={this.state.isLoading}>
        <Upload
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          onRemove={this.removePhoto}
          customRequest={this.uploadPhoto}
        >
          {uploadButton}
        </Upload>
        <Modal
          open={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Spin>
    );
  }
}
export default TourDayPlacePhotoUpload