import { Select, Spin } from 'antd';
import React, { Component } from 'react'
import O2ToursAPI from '../api/O2ToursAPI';

export class RegionSelect extends Component {
    timeoutHandler;
    currentSearchName;
    select;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            regions: [],
            value: undefined
        }
        this.select = React.createRef();
    }
    componentDidMount() {
        this.fetchByValue();
    }
    fetchByValue = () => {
        const regionId = this.props.value;
        this.fetchData({ id: regionId });
    }
    fetchData = (query) => {
        // let query = {
        //     id: id,
        //     name: searchName
        // };
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler);
            this.timeoutHandler = null;
        }
        this.currentSearchName = query.name;

        this.setState({ isLoading: true });
        let search = () => {
            O2ToursAPI.regions.index(query).then((response) => {
                // 若回傳的結果不是目前實際搜尋的內容則略過 (回應時已經更改輸入的文字)
                if (this.currentSearchName === query.name) {
                    this.setState({ regions: response.data.data });
                }
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        }
        this.timeoutHandler = setTimeout(search, 300);
    }
    handleSearch = (searchName) => {
        // 有輸入名稱時才發請求
        if (searchName) {
            this.fetchData({ name: searchName });
        } else {
            this.setState({ regions: [] });
        }
    }
    handleChange = (value, option) => {
        console.log("handleChange", value, option);
        if (!value) {
            this.handleSearch();
        }
        if (this.props.onChange) {
            this.props.onChange(value, option);
        }
    }
    render() {
        const { ...selectProps } = this.props;
        return (
            <>
                <Select
                    style={{ minWidth: 150 }}
                    ref={this.select}
                    {...selectProps}
                    showSearch
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    notFoundContent={null}
                    onChange={this.handleChange}
                    onSelect={(value) => console.log("onSelect", value)}
                    onSearch={this.handleSearch}
                    allowClear={true}
                >
                    {
                        this.state.regions.map((row) => <Select.Option key={row.id} value={row.id}>{row.name} ({row.id})</Select.Option>)
                    }
                </Select>
                <Spin style={{ marginLeft: 10, display: 'inline-block' }} size="small" spinning={this.state.isLoading}></Spin>
            </>
        )
    }
}

export default RegionSelect
