import { Checkbox, DatePicker, Descriptions, Drawer, Form, Input, Modal, notification, Popconfirm, Radio, Space, Spin, Table, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react'
import O2ToursAPI from '../../api/O2ToursAPI';
import CountrySelect from '../../components/CountrySelect';
import dayjs from 'dayjs';

function AttendsPage() {
  const [tours, setTours] = useState([]);
  const [isLoadingTours, setIsLoadingTours] = useState(false);

  const [showAttendees, setShowAttendees] = useState({
    open: false,
    tourId: null
  });
  const [isLoadingAttendees, setIsLoadingAttendees] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [editAttendee, setEditAttendee] = useState({
    open: false,
    attendeeId: null,
  });
  const [editAttendeeLoading, setEditAttendeeLoading] = useState(false);
  const [attendeeForm] = useForm();

  const refresh = useCallback(() => {
    setIsLoadingTours(true);
    O2ToursAPI.traveler.tourPeriods.index({}, 1, 999).then((response) => {
      const result = response.data;
      setTours(result.data);
      console.log("tours", result);
    }).finally(() => {
      setIsLoadingTours(false);
    });
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const refreshAttendees = (tourId) => {
    if (!tourId && !showAttendees.tourId) return;
    setIsLoadingAttendees(true);
    O2ToursAPI.traveler.tourPeriods.attendees.index(tourId || showAttendees.tourId, {}, 1, 999).then((response) => {
      const result = response.data;
      setAttendees(result.data);
    }).finally(() => {
      setIsLoadingAttendees(false);
    });
  };
  const handleShowAttendees = (id) => {
    console.log("handleShowAttendees", id);
    setShowAttendees({
      open: true,
      tourId: id,
    });
    refreshAttendees(id);
  }
  const handleCloseShowAttendees = () => {
    setShowAttendees({
      open: false,
      tourId: null
    })
  }

  const handleEditAttendee = (id) => {
    console.log("handleEditAttendee", id);
    setEditAttendee({
      open: true,
      attendeeId: id,
    });
    if (id) {
      O2ToursAPI.traveler.tourPeriods.attendees.show(showAttendees.tourId, id).then((response) => {
        const result = response.data;
        console.log("handleEditAttendee", result);
        attendeeForm.setFieldsValue({
          ...result,
          birthdate: result.birthdate ? dayjs(result.birthdate) : null,
        });
      }).finally(() => {

      });
    }
  }
  const handleCloseEditAttendee = () => {
    setEditAttendee({
      open: false,
      attendeeId: null
    });
    attendeeForm.resetFields();
  }
  const handleSubmitEditAttendee = () => {
    attendeeForm.validateFields().then((values) => {
      attendeeForm?.submit();
    });
  }

  const handleFinish = (values) => {
    const newValues = {
      ...values,
      birthdate: values.birthdate?.format("YYYY-MM-DD"),
    };
    console.log("handleFinish", newValues);
    console.log("tourId", showAttendees.tourId);
    if (!editAttendee.attendeeId) {
      setEditAttendeeLoading(true);
      O2ToursAPI.traveler.tourPeriods.attendees.store(showAttendees.tourId, newValues).then((response) => {
        notification.info({ message: '新增報名資料成功' });
      }).finally(() => {
        setEditAttendeeLoading(false);
        refreshAttendees();
        handleCloseEditAttendee();
      });
    } else {
      setEditAttendeeLoading(true);
      O2ToursAPI.traveler.tourPeriods.attendees.update(showAttendees.tourId, editAttendee.attendeeId, newValues).then((response) => {
        notification.info({ message: '修改報名資料成功' })
      }).finally(() => {
        setEditAttendeeLoading(false);
        refreshAttendees();
        handleCloseEditAttendee();
      });
    }
  }
  const handleDeleteAttendee = (id) => {
    O2ToursAPI.traveler.tourPeriods.attendees.destroy(showAttendees.tourId, id).then((response) => {
      notification.info({ message: '取消報名成功' });
    }).finally(() => {
      refreshAttendees();
    });
  }
  return (
    <div>
      <Table dataSource={tours} columns={[
        {
          dataIndex: 'tourtitle',
          title: '行程名稱',
        },
        {
          dataIndex: 'tourplan_id',
          title: '方案',
          render: (value, record) => record.plan?.name,
        },
        {
          dataIndex: 'startdate',
          title: '出發日',
        },
        {
          dataIndex: 'numberofpeople',
          title: '報名人數',
        },
        // {
        //   dataIndex: 'state',
        //   title: '出發狀態',
        // },
        // {
        //   dataIndex: '',
        //   title: '退回原因',
        // },
        {
          dataIndex: 'totalamount',
          title: '費用',
        },
        {
          dataIndex: '',
          title: '操作',
          render: (value, record) => <Space>
            <Typography.Link onClick={() => handleShowAttendees(record.id)}>修改報名資料</Typography.Link>
          </Space>
        },
      ]} rowKey="id" scroll={{ x: 1000 }} loading={isLoadingTours} />

      <Drawer title="參加者" size="large" open={showAttendees.open} onClose={handleCloseShowAttendees}>
        {/* <Form>
          <Form.Item>
            <Button onClick={() => handleEditAttendee()}>新增參加者</Button>
          </Form.Item>
        </Form> */}
        <Table dataSource={attendees} columns={[
          // {
          //   dataIndex: 'id',
          //   title: 'ID',
          // },
          {
            dataIndex: 'name',
            title: '姓名',
          },
          {
            dataIndex: 'sex',
            title: '性別',
            render: (value) => value ? '男' : '女'
          },
          {
            dataIndex: 'birthdate',
            title: '生日',
          },
          {
            dataIndex: 'isforeignnational',
            title: '外國人?',
            render: (value) => value ? '是' : '否'
          },
          {
            dataIndex: 'country_id',
            title: '國藉',
            render: (value, record) => record.country?.cn,
          },
          {
            dataIndex: 'idnumber',
            title: '身份證/護照',
          },

          {
            dataIndex: 'cellphone',
            title: '手機號碼',
          },
          // {
          //   dataIndex: 'meat',
          //   title: '葷/素',
          // },
          // {
          //   dataIndex: 'address',
          //   title: '地址',
          // },
          // {
          //   dataIndex: 'comment',
          //   title: '備註',
          // },
          // {
          //   dataIndex: 'emercontact',
          //   title: '緊急聯絡人',
          // },
          // {
          //   dataIndex: 'emercontacttel',
          //   title: '緊急聯絡人電話',
          // },
          // {
          //   dataIndex: 'experience',
          //   title: '登山經歷及運動習慣',
          // },
          // {
          //   dataIndex: 'historyofdrugallergy',
          //   title: '藥物過敏或疾病史',
          // },
          // {
          //   dataIndex: 'state',
          //   title: '報名狀態',
          // },
          {
            dataIndex: 'created_at',
            title: '新增日期',
            width: 200,
            render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss")
          },
          {
            title: '操作',
            fixed: 'right',
            width: 150,
            render: (value, record) => <Space>
              <Typography.Link onClick={() => handleEditAttendee(record.id)}>修改</Typography.Link>
              <Popconfirm title="您是否確定要取消報名?" onConfirm={() => handleDeleteAttendee(record.id)}>
                <Typography.Link>取消報名</Typography.Link>
              </Popconfirm>
            </Space>
          },
        ]}
          expandable={{
            expandedRowRender: (record) => (
              <Descriptions column={1}>
                <Descriptions.Item label="葷/素">
                  {record.meat ? '葷' : '素'}
                </Descriptions.Item>
                <Descriptions.Item label="地址">
                  {record.address}
                </Descriptions.Item>
                <Descriptions.Item label="備註">
                  {record.comment}
                </Descriptions.Item>
                <Descriptions.Item label="緊急聯絡人">
                  {record.emercontact}
                </Descriptions.Item>
                <Descriptions.Item label="緊急聯絡人電話">
                  {record.emercontacttel}
                </Descriptions.Item>
                <Descriptions.Item label="登山經歷及運動習慣">
                  {record.experience}
                </Descriptions.Item>
                <Descriptions.Item label="藥物過敏或疾病史">
                  {record.historyofdrugallergy}
                </Descriptions.Item>
              </Descriptions>
            ),
            rowExpandable: (record) => true,
          }}
          rowKey="id"
          scroll={{ x: 1500 }}
          loading={isLoadingAttendees}
        />
      </Drawer>
      <Modal title="新增/編輯參加者" open={editAttendee.open} onOk={handleSubmitEditAttendee} onCancel={handleCloseEditAttendee}>
        <Spin spinning={editAttendeeLoading}>
          <Form form={attendeeForm} onFinish={handleFinish} labelCol={{ span: 6 }}>
            <Form.Item name="name" label="姓名" rules={[
              {
                required: true,
              }
            ]}>
              <Input />
            </Form.Item>
            <Form.Item name="sex" label="性別">
              <Radio.Group>
                <Radio value={1}>男</Radio>
                <Radio value={0}>女</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="birthdate" label="生日">
              <DatePicker />
            </Form.Item>
            <Form.Item name="foreignnational" label="外國人?" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item name="country_id" label="國藉">
              <CountrySelect />
            </Form.Item>
            <Form.Item name="idnumber" label="身份證/護照">
              <Input />
            </Form.Item>
            <Form.Item name="cellphone" label="手機號碼" rules={[
              {
                required: true,
              }
            ]}>
              <Input />
            </Form.Item>
            <Form.Item name="meat" label="葷/素">
              <Radio.Group>
                <Radio value={1}>葷食</Radio>
                <Radio value={2}>素食</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="address" label="地址">
              <Input />
            </Form.Item>
            <Form.Item name="comment" label="備註">
              <Input.TextArea />
            </Form.Item>
            <Form.Item name="emercontact" label="緊急聯絡人">
              <Input />
            </Form.Item>
            <Form.Item name="emercontacttel" label="緊急聯絡人電話">
              <Input />
            </Form.Item>
            <Form.Item name="experience" label="登山經歷及運動習慣">
              <Input.TextArea />
            </Form.Item>
            <Form.Item name="historyofdrugallergy" label="藥物過敏或疾病史">
              <Input.TextArea />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
}

export default AttendsPage