export default class PermissionManager {
    static updateRoleCallback = null;
    static ROLE_ADMIN = "admin";
    static ROLE_TRAVELER = "traveler";
    static ROLE_ANONYMOUS = "anonymous";
    static roles = [this.ROLE_ANONYMOUS];

    static init(updateRoleCallback) {
        this.updateRoleCallback = updateRoleCallback;
    }
    static updateRoles(roles) {
        roles.push(this.ROLE_ANONYMOUS);
        this.roles = roles;
        if (this.updateRoleCallback) {
            this.updateRoleCallback(roles);
        }
    }
    static getRoles() {
        return this.roles;
    }
    static getRoutes() {
        return [
            // {
            //     key: "home",
            //     path: "/home",
            //     title: "Home",
            //     icon: <HomeOutlined />,
            //     component: <HomePage />,
            //     roles: [ROLE_DEMO, ROLE_READ, ROLE_WRITE],
            //     locked: false,
            // },
            // {
            //     key: "overview",
            //     path: "/overview",
            //     title: "Overview",
            //     icon: <DashboardOutlined />,
            //     component: <OverviewPage />,
            //     roles: [ROLE_DEMO, ROLE_READ, ROLE_WRITE],
            //     locked: false,
            // },
        ];
    }
    static getRoutesByRole() {
        return this.getRoutes().filter(
            (route) => route.roles.indexOf(this.role) >= 0
        );
    }

}
