import React, { Component } from 'react'
import { Button, Col, Form, Input, Modal, notification, Popconfirm, Row, Space, Spin, Table, Typography } from 'antd'
import O2ToursAPI from '../../api/O2ToursAPI';
import dayjs from 'dayjs';
export class UserPage extends Component {
    form = React.createRef();
    state = {
        results: [],
        loading: true,
        modalVisible: false,
        modalType: 'store',
        modalId: null,
        modalLoading: false,
    }
    componentDidMount() {
        this.refresh();
    }
    refresh = () => {
        this.setState({ loading: true });
        O2ToursAPI.users.index({}, 1, 999).then((response) => {
            const result = response.data;
            this.setState({ results: result.data });
        }).finally(() => {
            this.setState({ loading: false });
        });
    }
    showAddModal = () => {
        this.setState({
            modalVisible: true,
            modalType: 'store',
        });

    }
    showUpdateModal = (id) => {
        this.setState({
            modalLoading: true,
            modalVisible: true,
            modalType: 'update',
            modalId: id,
        });
        O2ToursAPI.users.show(id).then((response) => {
            const result = response.data;
            this.form.current.setFieldsValue(result);
        }).finally(() => {
            this.setState({ modalLoading: false });
        });
    }
    hideModal = () => {
        // reset state;
        this.setState({
            modalLoading: false,
            modalVisible: false,
            modalType: 'store',
            modalId: null,
        });
        this.form.current.setFieldsValue({
            name: null,
            email: null,
            password: null,
        });
    }
    handleOk = () => {
        this.setState({ modalVisible: false });
        this.form.current.submit();
    }
    handleFinish = (values) => {
        //console.log(values);
        this.setState({ loading: true });
        if (this.state.modalType === 'store') {
            // store
            O2ToursAPI.users.store(values).then((response) => {
                notification.info({ message: '新增成功' });
                this.refresh();
            }).catch((error) => {
                notification.info({ message: '新增失敗' });
            }).finally(() => {
                this.setState({ loading: false });
                this.hideModal();
            });
        } else {
            // update
            O2ToursAPI.users.update(this.state.modalId, values).then((response) => {
                notification.info({ message: '修改成功' });
                this.refresh();
            }).catch((error) => {
                notification.info({ message: '修改失敗' });
            }).finally(() => {
                this.setState({ loading: false });
                this.hideModal();
            });
        }
    }
    handleDelete = (id) => {
        this.setState({ loading: true });
        O2ToursAPI.users.destroy(id).then((response) => {
            notification.info({ message: '刪除成功' });
            this.refresh();
        }).catch((error) => {
            notification.info({ message: '刪除失敗' });
        }).finally(() => {
            this.setState({ loading: false });
        });
    }
    render() {
        const columns = [
            {
                title: '名稱',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'E-mail',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: '建立日期',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                sorter: (a, b) => dayjs(a.created_at).diff(b.created_at)
            },
            {
                title: '修改日期',
                dataIndex: 'updated_at',
                key: 'updated_at',
                render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                sorter: (a, b) => dayjs(a.updated_at).diff(b.updated_at)
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                fixed: 'right',
                render: (value, record) => <Space>
                    <Typography.Link onClick={this.showUpdateModal.bind(this, record.id)}>修改</Typography.Link>
                    <Popconfirm title={`你是否確定要刪除 ${record.name}?`} onConfirm={this.handleDelete.bind(this, record.id)} okText="是" cancelText="否">
                        <Typography.Link>刪除</Typography.Link>
                    </Popconfirm>
                </Space>
            }
        ]
        return (
            <Spin spinning={this.state.loading}>
                <h1>帳號管理</h1>
                <Row justify="end" style={{ marginBottom: 10 }}>
                    <Col>
                        <Space>
                            <Button type="primary" onClick={this.showAddModal}>新增</Button>
                        </Space>
                    </Col>
                </Row>
                <Table columns={columns} dataSource={this.state.results} rowKey="id" scroll={{ x: 'max-content' }} />
                <Modal title={this.state.modalType === 'store' ? "新增" : "修改"} open={this.state.modalVisible} onOk={this.handleOk} onCancel={this.hideModal}>
                    <Spin spinning={this.state.modalLoading}>
                        <Form layout="vertical" ref={this.form} onFinish={this.handleFinish}>
                            <Form.Item label="名稱" name="name">
                                <Input />
                            </Form.Item>
                            <Form.Item label="E-mail" name="email">
                                <Input />
                            </Form.Item>
                            <Form.Item label="密碼" name="password">
                                <Input.Password />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </Spin>
        )
    }
}

export default UserPage
