import { Button, Form, Input, notification, Select, Space, Spin, Table } from 'antd'
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import O2ToursAPI from '../../api/O2ToursAPI';
import withRouterParamsHook from '../../withRouterParamsHook';

export class CategoryEditPage extends Component {

    state = {
        mode: 'store',
        isLoading: false,
        id: null,
        tours: [],
    }
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    componentDidMount() {
        const categoryId = this.props.params.categoryId;
        this.fetchData(categoryId);
    }
    fetchData = (categoryId) => {
        if (!categoryId) return;
        this.setState({ isLoading: true, mode: 'update', id: categoryId });
        O2ToursAPI.categories.show(categoryId).then((response) => {
            const data = response.data;
            this.setState({ tours: data.tours });
            this.form.current.setFieldsValue(data);
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }
    handleFinish = (values) => {
        if (this.state.mode === 'store') {
            this.setState({ isLoading: true });
            O2ToursAPI.categories.store(values).then((response) => {
                notification.info({
                    message: '新增成功'
                });
            }).finally(() => {
                this.setState({ isLoading: false });
            })
        } else {
            this.setState({ isLoading: true });
            O2ToursAPI.categories.update(this.state.id, values).then((response) => {
                notification.info({
                    message: '修改成功'
                });
            }).finally(() => {
                this.setState({ isLoading: false });
            })
        }
    }
    render() {
        return (
            <Spin spinning={this.state.isLoading}>
                <h1>{this.state.mode === 'store' ? '新增分類' : '編輯分類: ' + this.state.id}</h1>
                <Form labelCol={{ span: 2 }} initialValues={{ type: 'normal' }} ref={this.form} onFinish={this.handleFinish}>
                    <Form.Item label="分類名稱" name="name">
                        <Input placeholder="南台灣一日遊" />
                    </Form.Item>
                    <Form.Item label="類型" name="type">
                        <Select>
                            <Select.Option value="normal">一般</Select.Option>
                            <Select.Option value="days">天數</Select.Option>
                        </Select>
                    </Form.Item>
                    <Button htmlType="reset" style={{ marginRight: 5 }}>重設</Button>
                    <Button type="primary" htmlType="submit">送出</Button>
                </Form>
                <br />
                <h3>關連的行程</h3>
                {this.state.mode === 'update' && <Table columns={[
                    {
                        title: '標題',
                        key: 'title',
                        dataIndex: 'title'
                    },
                    {
                        title: '動作',
                        render: (value, record) => <Space>
                            <Link to={`/admin/tours/${record.id}`}>編輯</Link>
                            <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_URL}/@${record.traveler.account}/${record.urlroutename}`}>檢視</a>
                        </Space>
                    }
                ]} dataSource={this.state.tours} />}
            </Spin>
        )
    }
}

export default withRouterParamsHook(CategoryEditPage);