import { Table, Space, Button, notification, Typography, Input, Form, Row, Col, Modal } from 'antd';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import O2ToursAPI from '../../api/O2ToursAPI';
import dayjs from 'dayjs';

export class PlacePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            pagination: {
                position: ['topRight', 'bottomRight'],
                current: null,
                total: null,
                pageSize: null,
                // onChange: (page, pageSize) => this.refresh(page, pageSize),
            },
            sorter: {},
            query: {},
            data: [],
            confirmDeleteId: null,
        };
        this.form = React.createRef();
    }
    componentDidMount() {
        this.refresh(1, 50);
    }
    refresh = (page, pageSize, sort, direction) => {
        if (!sort) sort = this.state.sorter.columnKey;
        if (!direction) direction = this.state.sorter.order;

        console.log("refresh", page, pageSize);
        this.setState({ isLoading: true });
        O2ToursAPI.places.index(this.state.query, page, pageSize, sort, direction).then((response) => {
            const results = response.data.data;
            console.log(results);
            const pagination = this.state.pagination;
            pagination.current = response.data.current_page;
            pagination.pageSize = response.data.per_page;
            // 若無回傳頁碼則自動依 next_page_url 推算總筆數為目前頁數 +1
            pagination.total = response.data.total || (response.data.next_page_url ? (pagination.current * pagination.pageSize + 1) : (pagination.current * pagination.pageSize))
            console.log("pagination", pagination);
            this.setState({
                pagination: pagination,
                data: results
            });
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }
    confirmDelete = (id) => {
        console.log("confirmDelete", id);
        this.setState({ confirmDeleteModalVisible: true, confirmDeleteId: id });
    }
    hideDelete = () => {
        console.log("hideDelete");
        this.setState({ confirmDeleteModalVisible: false, confirmDeleteId: null });
    }
    handleDelete = () => {
        const id = this.state.confirmDeleteId;
        this.setState({ isLoading: true });
        O2ToursAPI.places.destroy(id).then((response) => {
            console.log("handleDelete", id, response);
            notification.info({
                message: "刪除成功"
            });
        }).finally(() => {
            this.setState({ isLoading: false, confirmDeleteModalVisible: false });
            this.refresh();
        });
    }
    handleSearch = (values) => {
        console.log(values);
        this.setState({ query: values }, () => this.refresh());
    }
    handleTableChange = (newPagination, filters, sorter) => {
        this.setState({
            sorter: sorter
        }, () => this.refresh(newPagination.current, newPagination.pageSize));
    }
    render() {
        const columns = [
            {
                title: '地點名稱',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '營業時間',
                dataIndex: 'operationtime',
                key: 'operationtime',
                ellipsis: true,
            },
            {
                title: '導航次數',
                dataIndex: 'navigatorcount',
                key: 'navigatorcount',
                // sorter: (a, b) => a.navigatorcount - b.navigatorcount
                sorter: true,
                width: 120,
            },
            {
                title: '導航網址',
                dataIndex: 'navigateurl',
                key: 'navigateurl',
                width: 100,
                render: (text, record) => (
                    <Typography.Link href={text} rel="noreferrer" target="_blank">導航</Typography.Link>
                )
            },
            {
                title: '建立日期',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                // sorter: (a, b) => dayjs(a.created_at).diff(b.created_at)
                sorter: true,
                width: 160,
            },
            {
                title: '動作',
                key: 'action',
                fixed: 'right',
                width: 160,
                render: (text, record) => (
                    <Space size="middle">
                        <Link to={'/admin/places/' + record.id}>修改</Link>
                        <Typography.Link onClick={this.confirmDelete.bind(this, record.id)}>刪除</Typography.Link>
                    </Space>
                ),
            },
        ];
        return (
            <div>
                <Form ref={this.form} onFinish={this.handleSearch}>
                    <Row gutter={10}>
                        {/* <Col>
                            <Form.Item label="搜尋地址" name="address">
                                <Input placeholder="台北市" />
                            </Form.Item>
                        </Col> */}
                        <Col>
                            <Form.Item label="搜尋名稱" name="name">
                                <Input placeholder="車城福安宮" allowClear={true} />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Button htmlType="submit">查詢</Button>
                        </Col>
                        <Col flex="auto" style={{ textAlign: 'right' }}>
                            <Link to="/admin/places/create">
                                <Button type="primary">新增</Button>
                            </Link>
                        </Col>
                    </Row>
                </Form>

                <Table
                    columns={columns}
                    dataSource={this.state.data}
                    loading={this.state.isLoading}
                    onChange={this.handleTableChange}
                    rowKey="id"
                    pagination={this.state.pagination}
                    scroll={{ x: 1000 }} />
                <Modal
                    title="刪除地點"
                    open={this.state.confirmDeleteModalVisible}
                    onOk={this.handleDelete}
                    onCancel={this.hideDelete}
                    okText="確定"
                    cancelText="取消"
                    confirmLoading={this.state.isLoading}
                >
                    <p>你確定要刪除該地點?</p>
                </Modal>
            </div>
        )
    }
}

export default PlacePage
