import { Form, Button, Col, Row, Space, Table, Tag, notification, Typography, Modal } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useState } from 'react';
import O2ToursAPI from '../../api/O2ToursAPI';
import CategoriesSelect from '../../components/CategoriesSelect';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';

function ToursPage() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: null,
    total: null,
    pageSize: null,
  });
  const [sorter, setSorter] = useState({});
  const [query, setQuery] = useState({});
  const formRef = useRef();

  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState(null);

  const refresh = useCallback((page, pageSize, sort, direction) => {
    if (!sort) sort = sorter.columnKey;
    if (!direction) direction = sorter.order;
    console.log("refresh", query);
    setIsLoading(true);
    O2ToursAPI.traveler.tours.index(query, page, pageSize, sort, direction).then((response) => {
      const results = response.data.data;
      results.forEach(element => {
        element.tags = [];
      });
      console.log(results);
      pagination.current = response.data.current_page;
      pagination.pageSize = response.data.per_page;
      pagination.total = response.data.total;
      setPagination(pagination);
      setData(results);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [sorter, query, pagination]);

  const handleTableChange = (newPagination, filters, sorter) => {
    setSorter(sorter);
    refresh(newPagination.current, newPagination.pageSize);
  }
  const handleSearch = (values) => {
    values.category_ids = values.category_ids.join(",");
    setQuery(values);
  }

  useEffect(() => {
    refresh();
  }, [refresh]);

  const confirmDelete = (id) => {
    // console.log("confirmDelete", id);
    setConfirmDeleteModalVisible(true);
    setConfirmDeleteId(id);
  }
  const hideDelete = () => {
    // console.log("hideDelete");
    setConfirmDeleteModalVisible(false);
    setConfirmDeleteId(null);
  }
  const handleDelete = () => {
    const id = confirmDeleteId;
    setIsLoading(true);
    O2ToursAPI.traveler.tours.destroy(id).then((response) => {
      // console.log("handleDelete", id, response);
      notification.info({
        message: "刪除成功"
      });
    }).finally(() => {
      setIsLoading(false);
      setConfirmDeleteModalVisible(false);
      refresh();
    });
  }
  const columns = [
    {
      title: '行程名稱',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => <Link to={'/traveler/tours/' + record.id}>{text}</Link>,
    },
    {
      title: '網址',
      dataIndex: 'urlroutename',
      key: 'urlroutename',
    },
    {
      title: '發佈?',
      key: 'published',
      dataIndex: 'published',
      render: published => (
        <>
          {published ? <Tag color='green'>已發佈</Tag> : <Tag color='volcano'>草稿</Tag>}
        </>
      ),
    },
    // {
    //   title: '參加數',
    //   dataIndex: 'attendees_count',
    //   key: 'attendees_count',
    //   sorter: true,
    // },
    // {
    //   title: '顯示參加數',
    //   dataIndex: 'attendees',
    //   key: 'attendees',
    //   sorter: true,
    // },
    {
      title: '檢視數',
      dataIndex: 'viewercount',
      key: 'viewercount',
      sorter: true,
    },
    {
      title: '建立日期',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
      // sorter: (a, b) => dayjs(a.created_at).diff(b.created_at)
      sorter: true,
    },
    {
      title: '修改日期',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
      // sorter: (a, b) => dayjs(a.updated_at).diff(b.updated_at)
      sorter: true,
    },
    {
      title: '動作',
      key: 'action',
      fixed: 'right',
      render: (text, record) => (
        <Space size="middle">
          <Link to={'/traveler/tours/' + record.id}>修改</Link>
          <Typography.Link onClick={() => confirmDelete(record.id)}>刪除</Typography.Link>
        </Space>
      ),
    },
  ];
  return (
    <div>
      <Form ref={formRef} onFinish={handleSearch}>
        <Row gutter={10} style={{ marginBottom: 10 }}>
          <Col span={20}>
            <Form.Item name="category_ids">
              <CategoriesSelect style={{ width: 300, maxWidth: '100%' }} onChange={() => formRef.current.submit()} />
            </Form.Item>
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
            <Link to="/traveler/tours/create">
              <Button type="primary">新增</Button>
            </Link>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        onChange={handleTableChange}
        rowKey="id"
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => refresh(page, pageSize)
        }}
        scroll={{ x: 'max-content' }} />
      <Modal
        title="刪除行程"
        open={confirmDeleteModalVisible}
        onOk={handleDelete}
        onCancel={hideDelete}
        okText="確定"
        cancelText="取消"
        confirmLoading={isLoading}
      >
        <p>你確定要刪除該行程?</p>
      </Modal>
    </div>
  )
}

export default ToursPage