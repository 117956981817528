import { Button, Form, Modal, notification, Popconfirm, Space, Table, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form';
import React, { useCallback, useEffect, useState } from 'react'

function CRUDTableForm({ resourceName, columns, onIndex, onShow, onStore, onUpdate, onDestroy, pageStartContainer }) {
    const [resourceItems, setResourceItems] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    const [resourceForm] = useForm();
    const [editModal, setEditModal] = useState({
        open: false,
        id: null,
    });
    const [formItems, setFormItems] = useState([]);
    const refresh = useCallback(() => {
        setIsLoading(true);
        onIndex().then((response) => {
            const result = response.data;
            setResourceItems(result.data);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [onIndex]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const refreshFormItems = useCallback(() => {
        const formItems = columns.filter((column) => column.formChildren).map((column) =>
            <Form.Item key={column.dataIndex} label={column.title} name={column.dataIndex} rules={column.formRules} valuePropName={column.formValuePropName}>
                {column.formChildren}
            </Form.Item>
        );
        //console.log("refreshFormItems", formItems);
        setFormItems(formItems);
    }, [columns]);

    useEffect(() => {
        refreshFormItems();
    }, [refreshFormItems]);

    const handleShowModal = (id) => {
        setEditModal({
            open: true,
            id: id,
        });
        if (id && onShow) {
            onShow(id).then((response) => {
                const result = response.data;
                resourceForm.setFieldsValue(result);
            }).finally(() => {
            });
        }
    }
    const handleOkEditModal = () => {
        resourceForm.validateFields().then((value) => {
            resourceForm.submit();
        });
    }
    const handleFinish = (values) => {
        console.log("handleFinish", values);

        if (!editModal.id) {
            onStore(values).then((response) => {
                notification.info({ message: `新增${resourceName}成功` })
            }).finally(() => {
                handleCloseModal();
                refresh();
            });
        } else {
            onUpdate(editModal.id, values).then((response) => {
                notification.info({ message: `修改${resourceName}成功` })
            }).finally(() => {
                handleCloseModal();
                refresh();
            });
        }
    }
    const handleCloseModal = () => {
        setEditModal({
            open: false,
            id: null,
        });
        resourceForm.resetFields();
    }
    const handleDelete = (id) => {
        if (onDestroy) {
            onDestroy(id).then((response) => {
                notification.info({ message: `刪除${resourceName}成功` })
            }).finally(() => {
                refresh();
            });
        }
    }
    return (
        <>
            <Space style={{ marginBottom: 10 }}>
                {pageStartContainer}
                <Button onClick={() => handleShowModal()}>新增{resourceName}</Button>
            </Space>
            <Table
                loading={isLoading}
                columns={[
                    ...columns,
                    {
                        title: '操作',
                        width: 120,
                        render: (value, record) => <Space>
                            <Typography.Link onClick={() => handleShowModal(record.id)}>修改</Typography.Link>
                            <Popconfirm onConfirm={() => handleDelete(record.id)} title="您確定是否要刪除?">
                                <Typography.Link>刪除</Typography.Link>
                            </Popconfirm>
                        </Space>
                    }
                ]}
                dataSource={resourceItems}
                rowKey="id"
                scroll={{ x: 1000 }}
            />
            <Modal title={`新增/修改${resourceName}`} open={editModal.open} onOk={handleOkEditModal} onCancel={handleCloseModal}>
                <Form form={resourceForm} onFinish={handleFinish} labelCol={{ span: 4 }}>
                    {formItems}
                </Form>
            </Modal>
        </>
    )
}

export default CRUDTableForm