import logo from '../images/o2-full.svg';
import React, { Component } from 'react'
import LoginPageLayout from './LoginPageLayout';
import { Link } from 'react-router-dom';

export class NotFoundPage extends Component {
    render() {
        return (
            <LoginPageLayout>
                <img src={logo} alt="Logo" style={{ height: 44, margin: 10 }} />
                <h2>您沒有權限進入該頁面或頁面不存在，請點選 <Link to="/">此處</Link> 返回首頁。</h2>
            </LoginPageLayout>
        )
    }
}

export default NotFoundPage