import dayjs from 'dayjs';
import 'moment/locale/zh-tw';
import { Form, Radio, Input, Button, DatePicker, InputNumber, Select, Tabs, notification, Spin, Switch, Table } from 'antd';
import React, { Component } from 'react'
import AvatorUpload from '../../components/AvatorUpload';
import { generatePath } from "react-router-dom";
import O2ToursAPI from '../../api/O2ToursAPI';
import TravelerSelect from '../../components/TravelerSelect';
import RegionSelect from '../../components/RegionSelect';
import CategoriesSelect from '../../components/CategoriesSelect';
import withRouterParamsHook from '../../withRouterParamsHook';
import TextEditor from '../../components/TextEditor';
import TourDayEditor from '../../components/TourDayEditor';
export class TourEditPage extends Component {
    initialValues = {
        // traveler_id: 1,
        // urlroutename: '2021-pingtung-spring',
        // title: '【2021 屏東四重溪溫泉季】賞大片燈海，兩天一夜溫泉住宿行程',
        starttime: dayjs().set({ hour: 9, minute: 0, second: 0, millisecond: 0 }),
        published: 0,
        // region_id: 1,
        // originplace: '高雄',
        // destplace: '屏東',
        // budget: 2799,
        // includefood: 0,
        // description: '四重溪溫泉屬於碳酸氫鈉泉，帶有舒緩肌肉痠痛的功效，很適合平常擁有多樣都市文明病的朋友們來這裡紓解壓力，說到四重溪不得不提的就是屏東一年一度的溫泉季也即將在11/12晚間正式點燈，一直到隔年的2/15，大片白色、粉色燈海將撲滿整個四重溪溫泉公園，讓園區變得炫麗奪目，絕對是來到四重溪不容錯過的必拍美景！這個行程會安排一系列的車城、恆春必吃美食、無人的秘境海灘與新興景點，讓你在享受溫泉的同時，也能滿足口腹之慾。',
        // numofppl: 6,
        // attendees: 3,
        // follows: 200,
        // ended: 1,
        // transportdesc: '自駕車',
        // tour_tag: ['鹿境梅花鹿生態園區', '後壁湖邱家生魚片二店', '社頂自然公園']
    };
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            mode: 'store',
            id: null,
            selectedFile: null,
            isLoading: false,
            travelerPageUrl: null,
            previewUrl: null,
            data: null,
            interested: [],
        };
        this.regionSelect = React.createRef();
        this.travelerSelect = React.createRef();
    }
    componentDidMount() {
        const id = this.props.params.tourId;
        this.fetchData(id);
    }
    refreshSelect = () => {
        this.regionSelect.current.fetchByValue();
        this.travelerSelect.current.fetchByValue();
    }
    fetchData = (id) => {
        if (!id) return;
        const navigate = this.props.navigate;
        const genPath = generatePath('/admin/tours/:id', { id: id });
        navigate(genPath, { replace: true });
        this.setState({ mode: 'update', id: id, isLoading: true });
        O2ToursAPI.tours.show(id).then((response) => {
            const tour = response.data;
            tour.starttime = dayjs(tour.starttime);
            tour.endtime = dayjs(tour.endtime);
            this.setState({ data: tour });
            this.updatePreviewUrl(tour.traveler_id);
            this.form.current.setFieldsValue(tour);
            this.refreshSelect();
        }).finally(() => {
            this.setState({ isLoading: false });
        });
        this.fetchInterested(id);
    }
    fetchInterested = (tourId) => {
        O2ToursAPI.tours.interested.index(tourId, 1, 999).then((response) => {
            const result = response.data;
            this.setState({ interested: result.data });
        });
    }
    onSelectedAvatorFile = (file) => {
        console.log("Selected avator file", file);
        this.setState({ selectedFile: file });
    }
    uploadCover = (tourId) => {
        const coverImage = this.state.selectedFile;
        if (!tourId || !coverImage) return;
        this.setState({ isLoading: true });
        O2ToursAPI.tours.image.store(tourId, coverImage).then((response) => {
            notification.info({
                message: "圖片上傳成功"
            });
        }).finally(() => {
            this.setState({ isLoading: false });
        });

    }
    onFinish = (values) => {
        values.starttime = dayjs(values.starttime).format('YYYY-MM-DD HH:mm:ss');
        values.endtime = dayjs(values.endtime).format('YYYY-MM-DD HH:mm:ss');
        values.published = values.published ? 1 : 0;
        console.log('Send:', values);
        if (this.state.mode === 'store') {
            this.setState({ isLoading: true });
            O2ToursAPI.tours.store(values).then((response) => {
                const tourId = response.data.id;
                notification.info({
                    message: "新增成功"
                });
                this.uploadCover(tourId);
                this.fetchData(response.data.id);
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        } else {
            this.setState({ isLoading: true });
            O2ToursAPI.tours.update(this.state.id, values).then((response) => {
                notification.info({
                    message: "修改成功"
                });
                this.uploadCover(this.state.id);
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        }
    }
    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }
    getPreviewUrl = async () => {
        if (this.state.mode !== "update") return false;
        const urlRouteName = this.state.data.urlroutename;
        const travelerId = this.state.data.traveler_id;
        if (!urlRouteName) return false;
        if (!travelerId) return false;

        const response = await O2ToursAPI.travelers.show(travelerId);
        const traveler = response.data;
        if (!traveler) return false;

        const travelerAccount = '@' + traveler.account;
        const url = process.env.REACT_APP_URL + '/' + travelerAccount + '/' + urlRouteName;

        console.log(url);
        return url;
    }
    disabledStartDate = (current) => {
        const endtime = this.form.current.getFieldValue('endtime');
        if (current && endtime) {
            return current.diff(endtime) > 0;
        }
        return false;
    }
    disabledEndDate = (current) => {
        const starttime = this.form.current.getFieldValue('starttime');
        if (current && starttime) {
            return current.diff(starttime) < 0;
        }
        return false;
    }

    onValuesChange = (changedValues, allValues) => {
        //console.log("onValuesChange", changedValues, allValues);
        if (changedValues.traveler_id && changedValues.traveler_id) {
            this.updateTravelerPageUrl(changedValues.traveler_id);
        }
    }
    updateTravelerPageUrl = async (travelerId, travelerAccount) => {
        this.setState({ travelerPageUrl: null });
        if (!travelerAccount) {
            const response = await O2ToursAPI.travelers.show(travelerId);
            travelerAccount = response.data.user.account;
        }
        if (!travelerAccount) return;
        const url = process.env.REACT_APP_URL + '/@' + travelerAccount + '/';
        this.setState({ travelerPageUrl: url });
    }
    updatePreviewUrl = (travelerId) => {
        this.setState({ previewUrl: null });
        O2ToursAPI.travelers.show(travelerId).then((response) => {
            const travelerAccount = response.data.user.account;
            if (!travelerAccount) return;
            const url = process.env.REACT_APP_URL + '/@' + travelerAccount + '/' + this.state.data.urlroutename;
            this.setState({ previewUrl: url });
            this.updateTravelerPageUrl(null, travelerAccount); // 若取得 TravelerAccount 則同時更新 TravelerPageUrl
        });
    }
    handlePreview = () => {
        if (!this.state.previewUrl) {
            return;
        }
        window.open(this.state.previewUrl, "_blank");
    }
    render() {
        return (
            <div>
                <Spin spinning={this.state.isLoading}>
                    <h1>{this.state.mode === 'store' ? '新增行程' : '編輯行程: ' + this.state.id}</h1>
                    <div style={{ textAlign: 'right' }}>
                        <Button disabled={!this.state.previewUrl} style={{ marginLeft: '10px' }} size="small" onClick={this.handlePreview}>前台檢視</Button>
                    </div>
                    <Tabs items={[
                        {
                            label: '行程資訊',
                            key: '1',
                            children: <>
                                <Form
                                    ref={this.form}
                                    labelCol={{ span: 2 }}
                                    onFinish={this.onFinish}
                                    onFinishFailed={this.onFinishFailed}
                                    onValuesChange={this.onValuesChange}
                                    initialValues={this.initialValues}>
                                    <Form.Item label="發佈?" name="published" valuePropName="checked">
                                        <Switch checkedChildren="發佈" unCheckedChildren="草稿" />
                                    </Form.Item>
                                    <Form.Item label="旅者" name="traveler_id">
                                        <TravelerSelect
                                            ref={this.travelerSelect}
                                            placeholder="搜尋旅者"
                                            style={{ width: 300 }}
                                        />
                                    </Form.Item>
                                    <Form.Item label="頁面網址" name="urlroutename">
                                        <Input addonBefore={this.state.travelerPageUrl} />
                                    </Form.Item>
                                    <Form.Item label="行程名稱" name="title" rules={[{ required: true }]}>
                                        <Input placeholder="【2021 屏東四重溪溫泉季】賞大片燈海，兩天一夜溫泉住宿行程" />
                                    </Form.Item>
                                    <Form.Item label="出發日期" name="starttime" rules={[{ required: true }]}>
                                        <DatePicker minuteStep={15} disabledDate={this.disabledStartDate} />
                                    </Form.Item>
                                    <Form.Item label="結束日期" name="endtime" rules={[{ required: true }]}>
                                        <DatePicker minuteStep={15} disabledDate={this.disabledEndDate} />
                                    </Form.Item>
                                    <Form.Item label="地區" name="region_id" rules={[{ required: true }]}>
                                        <RegionSelect
                                            ref={this.regionSelect}
                                            placeholder="搜尋地區"
                                            style={{ width: 200 }}
                                        />
                                    </Form.Item>
                                    <Form.Item label="集合地點" name="originplace">
                                        <Input placeholder="高雄" />
                                    </Form.Item>
                                    <Form.Item label="目的地 (略)" name="destplace">
                                        <Input placeholder="屏東" />
                                    </Form.Item>
                                    <Form.Item label="預算" name="budget">
                                        <InputNumber
                                            min={0}
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </Form.Item>
                                    <Form.Item label="是否含餐" name="includefood" rules={[{ required: true }]}>
                                        <Radio.Group value={1}>
                                            <Radio value={1}>是</Radio>
                                            <Radio value={0}>否</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item label="YouTube 影片嵌入網址" name="video_url">
                                        <Input placeholder="https://www.youtube.com/embed/..." />
                                    </Form.Item>
                                    <Form.Item label="行程描述" name="description" rules={[{ required: true }]}>
                                        <TextEditor onUploadImage={(file) => {
                                            console.log("upload file", file);
                                            const p = new Promise((resolve, reject) => { resolve('http://10.168.103.102:8887/assets/images/logo.svg') });
                                            return p;
                                        }} />
                                    </Form.Item>
                                    <Form.Item label="預計人數" name="numofppl">
                                        <InputNumber min={0} />
                                    </Form.Item>
                                    <Form.Item label="參加數" name="attendees">
                                        <InputNumber min={0} disabled />
                                    </Form.Item>
                                    <Form.Item label="追蹤數" name="follows">
                                        <InputNumber min={0} disabled />
                                    </Form.Item>
                                    <Form.Item label="是否結束?	" name="ended">
                                        <Radio.Group value={1}>
                                            <Radio value={1}>是</Radio>
                                            <Radio value={0}>否</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item label="交通方式說明" name="transportdesc" rules={[{ required: true }]}>
                                        <Input.TextArea rows={4} />
                                    </Form.Item>
                                    <Form.Item label="分類" name="tour_category" rules={[{ required: true }]}>
                                        <CategoriesSelect />
                                    </Form.Item>
                                    <Form.Item label="標籤" name="tour_tag">
                                        <Select
                                            mode="tags"
                                            placeholder="請輸入標籤"
                                            style={{ width: '100%' }}
                                        >
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="封面圖">
                                        <AvatorUpload onChange={this.onSelectedAvatorFile} />
                                    </Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        送出
                                    </Button>
                                </Form>
                            </>,
                        },
                        {
                            label: '行程地點',
                            key: '2',
                            children: <>
                                <TourDayEditor tourId={this.state.id}
                                    onDaysIndex={(tourId, page, pageSize) => O2ToursAPI.tours.days.index(tourId, page, pageSize)}
                                    onDaysShow={(tourId, tourDayId) => O2ToursAPI.tours.days.show(tourId, tourDayId)}
                                    onDaysStore={(tourId, values) => O2ToursAPI.tours.days.store(tourId, values)}
                                    onDaysUpdate={(tourId, tourDayId, values) => O2ToursAPI.tours.days.update(tourId, tourDayId)}
                                    onDaysDestroy={(tourId, tourDayId) => O2ToursAPI.tours.days.destroy(tourId, tourDayId)}
                                    onPlacesIndex={(tourId, tourDayId, page, pageSize) => O2ToursAPI.tours.days.places.index(tourId, tourDayId, page, pageSize)}
                                    onPlacesDestroy={(tourId, tourDayId, id) => O2ToursAPI.tours.days.places.destroy(tourId, tourDayId, id)}
                                    onPlacesShow={(tourId, tourDayId, id) => O2ToursAPI.tours.days.places.show(tourId, tourDayId, id)}
                                    onPlacesStore={(tourId, tourDayId, values) => O2ToursAPI.tours.days.places.store(tourId, tourDayId, values)}
                                    onPlacesUpdate={(tourId, tourDayId, id, values) => O2ToursAPI.tours.days.places.update(tourId, tourDayId, id, values)}
                                    onPlacePhotosIndex={(tourId, tourDayId, tourPlaceId, page, pageSize) => O2ToursAPI.tours.days.places.photos.index(tourId, tourDayId, tourPlaceId, page, pageSize)}
                                    onPlacePhotosStore={(tourId, tourDayId, tourPlaceId, data, imageFile) => O2ToursAPI.tours.days.places.photos.store(tourId, tourDayId, tourPlaceId, data, imageFile)}
                                    onPlacePhotosDestroy={(tourId, tourDayId, tourPlaceId, id) => O2ToursAPI.tours.days.places.photos.destroy(tourId, tourDayId, tourPlaceId, id)}
                                />
                            </>,
                        },
                        {
                            label: '追蹤者',
                            key: '4',
                            children: <>
                                <Table columns={[
                                    {
                                        title: '名稱',
                                        dataIndex: 'traveler',
                                        key: 'traveler',
                                        render: (value, record) => value.name
                                    },
                                    {
                                        title: '帳號',
                                        dataIndex: 'traveler',
                                        key: 'traveler',
                                        render: (value, record) => value.account
                                    },
                                    {
                                        title: 'E-mail',
                                        dataIndex: 'traveler',
                                        key: 'traveler',
                                        render: (value, record) => value.email
                                    },
                                ]} dataSource={this.state.interested} />
                            </>,
                        },
                        {
                            label: '開團',
                            key: '5',
                            disabled: !this.state.id,
                            // children: <TravelerTourPlanTabPane tourId={this.state.id} />
                            children: <>請使用一般使用者權限進行開團</>
                        }
                    ]}>
                    </Tabs>
                </Spin>
            </div>
        )
    }
}

export default withRouterParamsHook(TourEditPage)
