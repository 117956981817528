import { Radio, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import O2ToursAPI from '../../api/O2ToursAPI';

function TravelerCertificatesPage() {
    const [certificates, setCertificates] = useState([]);
    const [certificateSystems, setCertificateSystems] = useState([]);
    const [certificateTypes, setCertificateTypes] = useState([]);

    const [travelerCertificates, setTravelerCertificates] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedType, setSelectedType] = useState();

    useEffect(() => {
        setLoading(true);
        O2ToursAPI.certificates.index({
            certificatetype_id: selectedType,
        }, 1, 999).then((response) => {
            const result = response.data;
            setCertificates(result.data);
        }).finally(() => {
            setLoading(false);
        });
    }, [selectedType]);

    useEffect(() => {
        setLoading(true);
        O2ToursAPI.certificateSystems.index({}, 1, 999).then((response) => {
            const result = response.data;
            setCertificateSystems(result.data);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        O2ToursAPI.certificateTypes.index({}, 1, 999).then((response) => {
            const result = response.data;
            setCertificateTypes(result.data);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const refreshTravelerCertificates = useCallback(() => {
        setLoading(true);
        O2ToursAPI.traveler.certificates.index({
            certificatetype_id: selectedType,
        }, 1, 999).then((response) => {
            const result = response.data;
            setTravelerCertificates(result.data);
        }).finally(() => {
            setLoading(false);
        });
    }, [selectedType]);

    useEffect(() => {
        refreshTravelerCertificates();
    }, [refreshTravelerCertificates]);

    const handleSelectType = (e) => {
        console.log(e.target.value);
        setSelectedType(e.target.value);
    }
    const rowSelectionEvents = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            console.log("onSelect", record, selected, selectedRows);
            if (selected) {
                setLoading(true);
                O2ToursAPI.traveler.certificates.store({
                    certificate_id: record.id,
                }).then((response) => {
                    const result = response.data;
                    setTravelerCertificates([...travelerCertificates, result]);
                }).finally(() => {
                    setLoading(false);
                });
            } else {
                const foundRow = travelerCertificates.find((row) => row.certificate_id === record.id);
                if (foundRow) {
                    setLoading(true);
                    O2ToursAPI.traveler.certificates.destroy(foundRow.id).then((response) => {
                        const newTravelerCertificates = travelerCertificates.filter((row) => row.id !== foundRow.id);
                        setTravelerCertificates(newTravelerCertificates);
                    }).finally(() => {
                        setLoading(false);
                    });
                }
            }
        },
    };
    return (
        <>
            <Radio.Group value={selectedType} onChange={handleSelectType} style={{ marginBottom: 10 }}>
                {certificateTypes.map((row) => <Radio.Button key={row.id} value={row.id}>{row.name}</Radio.Button>)}
            </Radio.Group>
            <Table loading={loading} size="small" columns={[
                {
                    dataIndex: 'certificatesystem_id',
                    title: '證照系統',
                    width: 100,
                    render: (value, record) => certificateSystems.find((row) => row.id === value)?.name ?? '-'
                    //render: (value, record) => record.system.name
                },
                {
                    dataIndex: 'name',
                    title: '證照名稱',
                }
            ]} dataSource={certificates} rowKey="id" rowSelection={{
                hideSelectAll: true,
                selectedRowKeys:
                    travelerCertificates.map((row) => row.certificate_id),
                ...rowSelectionEvents
            }} pagination={false} />
        </>
    )
}

export default TravelerCertificatesPage