import './App.css';
import React from 'react';
import {
  HashRouter,
  Routes,
  Route,
} from 'react-router-dom';
import DefaultLayout from './containers/DefaultLayout';
import TourPage from './containers/admin/TourPage';
import TourEditPage from './containers/admin/TourEditPage';
import TravelerPage from './containers/admin/TravelerPage';
import PlacePage from './containers/admin/PlacePage';
import RegionPage from './containers/admin/RegionPage';
import TravelerEditPage from './containers/admin/TravelerEditPage';
import PlaceEditPage from './containers/admin/PlaceEditPage';
import RegionEditPage from './containers/admin/RegionEditPage';
import LoginPage from './containers/LoginPage';
import O2ToursAPI from './api/O2ToursAPI';
import { Skeleton } from 'antd';
import NotFoundPage from './containers/NotFoundPage';
import UserPage from './containers/admin/UserPage';
import CategoryPage from './containers/admin/CategoryPage';
import CategoryEditPage from './containers/admin/CategoryEditPage';
import PermissionManager from './PermissionManager';
import TravelerTourPage from './containers/traveler/TravelerTourPage';
import TravelerAttendsPage from './containers/traveler/TravelerAttendsPage';
import TravelerProfile from './containers/traveler/TravelerProfilePage';
import TravelerTourEditPage from './containers/traveler/TravelerTourEditPage';
import TravelerAchievementsPage from './containers/traveler/TravelerAchievementsPage';
import TravelerEquipmentsPage from './containers/traveler/TravelerEquipmentsPage';
import TravelerCertificatesPage from './containers/traveler/TravelerCertificatesPage';
import AdminAchievements from './containers/admin/AdminAchievements';
import AdminCertifications from './containers/admin/AdminCertifications';

function App() {
  const [logined, setLogined] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  O2ToursAPI.auth.init(setLogined);

  React.useEffect(() => {
    setLoading(true);
    O2ToursAPI.auth.loginWithToken().finally(() => {
      setLoading(false);
    });
  }, [setLoading]);

  const roles = PermissionManager.getRoles();
  console.log("roles", roles);
  return (
    <>
      {loading ? <Skeleton style={{ margin: 20 }} /> : <HashRouter>
        <Routes>
          {logined && roles.includes("admin") && [
            <Route key="11" path="/" element={<DefaultLayout redirect="/admin/tours" />} />,
            <Route key="12" path="/admin/tours" element={<DefaultLayout><TourPage /></DefaultLayout>} />,
            <Route key="13" path="/admin/tours/create" element={<DefaultLayout><TourEditPage /></DefaultLayout>} />,
            <Route key="14" path="/admin/tours/:tourId" element={<DefaultLayout><TourEditPage /></DefaultLayout>} />,

            <Route key="15" path="/admin/travelers" element={<DefaultLayout><TravelerPage /></DefaultLayout>} />,
            <Route key="16" path="/admin/travelers/create" element={<DefaultLayout><TravelerEditPage /></DefaultLayout>} />,
            <Route key="17" path="/admin/travelers/:travelerId" element={<DefaultLayout><TravelerEditPage /></DefaultLayout>} />,

            <Route key="18" path="/admin/places" element={<DefaultLayout><PlacePage /></DefaultLayout>} />,
            <Route key="19" path="/admin/places/create" element={<DefaultLayout><PlaceEditPage /></DefaultLayout>} />,
            <Route key="20" path="/admin/places/:placeId" element={<DefaultLayout><PlaceEditPage /></DefaultLayout>} />,

            <Route key="21" path="/admin/regions" element={<DefaultLayout><RegionPage /></DefaultLayout>} />,
            <Route key="22" path="/admin/regions/create" element={<DefaultLayout><RegionEditPage /></DefaultLayout>} />,
            <Route key="23" path="/admin/regions/:regionId" element={<DefaultLayout><RegionEditPage /></DefaultLayout>} />,

            <Route key="26" path="/admin/categories" element={<DefaultLayout><CategoryPage /></DefaultLayout>} />,
            <Route key="27" path="/admin/categories/create" element={<DefaultLayout><CategoryEditPage /></DefaultLayout>} />,
            <Route key="28" path="/admin/categories/:categoryId" element={<DefaultLayout><CategoryEditPage /></DefaultLayout>} />,

            <Route key="29" path="/admin/achievements" element={<DefaultLayout><AdminAchievements /></DefaultLayout>} />,
            <Route key="30" path="/admin/certifications" element={<DefaultLayout><AdminCertifications /></DefaultLayout>} />,

            <Route key="24" path="/admin/users" element={<DefaultLayout><UserPage /></DefaultLayout>} />,
            <Route key="25" path='*' element={<NotFoundPage />} />
          ]}
          {logined && roles.includes("traveler") && [
            <Route key="11" path="/" element={<DefaultLayout redirect="/traveler/tours" />} />,
            <Route key="31" path="/traveler/tours" element={<DefaultLayout><TravelerTourPage /></DefaultLayout>} />,
            <Route key="13" path="/traveler/tours/create" element={<DefaultLayout><TravelerTourEditPage /></DefaultLayout>} />,
            <Route key="14" path="/traveler/tours/:tourId" element={<DefaultLayout><TravelerTourEditPage /></DefaultLayout>} />,

            <Route key="32" path="/traveler/attends" element={<DefaultLayout><TravelerAttendsPage /></DefaultLayout>} />,
            <Route key="33" path="/traveler/profile" element={<DefaultLayout><TravelerProfile /></DefaultLayout>} />,
            <Route key="34" path="/traveler/achievements" element={<DefaultLayout><TravelerAchievementsPage /></DefaultLayout>} />,
            <Route key="35" path="/traveler/equipments" element={<DefaultLayout><TravelerEquipmentsPage /></DefaultLayout>} />,
            <Route key="36" path="/traveler/certificates" element={<DefaultLayout><TravelerCertificatesPage /></DefaultLayout>} />,
          ]}
          {!logined && [
            <Route key="40" path="/" element={<LoginPage />} />,
          ]}
          <Route key="41" path='*' element={<NotFoundPage />} />
        </Routes>
      </HashRouter>}
    </>
  );
}

export default App;
