import { notification } from "antd";
import axios from "axios";
import PermissionManager from "../PermissionManager";

axios.defaults.baseURL = 'http://localhost:8083/api';
if (process.env.REACT_APP_URL) {
    axios.defaults.baseURL = process.env.REACT_APP_URL + '/api';
}

// Important: If axios is used with multiple domains, the AUTH_TOKEN will be sent to all of them.
// See below for an example using Custom instance defaults instead.
//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.data) {
        const errorResponse = error.response.data;
        console.log(errorResponse);
        // Validation errors/
        if (errorResponse.message) {
            notification.error({
                title: 'Error',
                message: errorResponse.message
            });
        }
        if (typeof errorResponse.validation === "object") {
            for (let key in errorResponse.validation) {
                notification.error({
                    title: 'Error',
                    message: errorResponse.validation[key]
                });
            }
        }
    }
    return Promise.reject(error);
});

const defaultPageSize = 12;
export default class O2ToursAPI {
    /**
     * 將 Object 轉為 FormData
     * @param {*} data 
     * @returns FormData 
     */
    static toFormData = (data) => {
        var formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key] !== undefined ? data[key] : '');
        }
        return formData;
    }
    static sortDirection = (direction) => {
        if (direction === 'ascend') return 'asc';
        if (direction === 'descend') return 'desc';
    }
    static auth = class {
        _appLoginedCallback = null; // 登入完成要觸發的事件，通常是 App 元件，讓 App 能夠更新元件登入狀態
        _tokenData = null; // 儲存 token
        _userData = null; // 儲存使用者資料
        static getLoginUser = () => {
            return this._userData;
        };
        static init = (appLoginedCallback) => {
            this._appLoginedCallback = appLoginedCallback;
        }

        static login = (email, password, remember) => {
            let data = new FormData();
            let platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
            const detectBrowser = () => {
                if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
                    return 'Opera';
                } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
                    return 'Chrome';
                } else if (navigator.userAgent.indexOf("Safari") !== -1) {
                    return 'Safari';
                } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
                    return 'Firefox';
                } else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) {
                    return 'IE';//crap
                } else {
                    return 'Unknown';
                }
            }
            data.append('email', email);
            data.append('password', password);
            data.append('device_name', [platform, detectBrowser()].join('-'));
            return axios.post(`/auth/login`, data).then((response) => {
                const token = response.data.token;
                this._tokenData = token;
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                if (remember) {
                    localStorage.setItem('token', token);
                }
                return this.user().then((response) => {
                    this._appLoginedCallback(true);
                });
            });

        }
        static loginWithToken = async () => {
            if (this.getLoginUser()) return;
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const urlToken = urlParams.get('token')
            if (urlToken) {
                console.log("Url token saved", urlToken);
                localStorage.setItem('token', urlToken);
                window.location.href = window.location.href.replace(/(.*)\?token=[A-Za-z0-9|]+(.*)/, '$1$2');
                return;
            }
            const savedToken = localStorage.getItem('token');
            console.log("loginWithToken", savedToken);
            if (savedToken) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + savedToken;
                await this.user().then((response) => {
                    this._tokenData = savedToken;
                    this._appLoginedCallback(true);
                }).catch((error) => {
                    console.log("loginWithToken error", error);
                    localStorage.removeItem('token');
                });
            }
        }
        static logout = () => {
            if (!this._tokenData && !this._userData) return;
            this._tokenData = null;
            this._userData = null;
            localStorage.removeItem('token');
            PermissionManager.updateRoles([]);
            this._appLoginedCallback(false);
            return axios.post(`/auth/logout`);
        }
        static user = () => {
            return axios.get(`/auth/user`).then((response) => {
                this._userData = response.data;
                PermissionManager.updateRoles([this._userData.role]);
                //console.log(this._userData);
            });
        }
    }
    static travelers = class {
        static index = (query, page, pageSize, sort, direction) => {
            if (!query) query = {};
            return axios.get(`/travelers`, {
                params: {
                    id: query.id,
                    q: query.name,
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    sort: sort,
                    direction: O2ToursAPI.sortDirection(direction),
                },
            });
        };

        static show = (id) => axios.get(`/travelers/${id}`);
        static store = (data) => {
            const formData = O2ToursAPI.toFormData(data);
            return axios.post(`/travelers`, formData);
        }
        static update = (id, data) => {
            // const formData = O2ToursAPI.toFormData(data);
            // return axios.patch(`/travelers/${id}`, formData);
            return axios.patch(`/travelers/${id}`, data);
        }
        static destroy = (id) => axios.delete(`/travelers/${id}`);

        static avator = class {
            static store = (travelerId, coverFile) => {
                const formData = O2ToursAPI.toFormData({});
                formData.append("avator", coverFile);
                return axios.post(`/travelers/${travelerId}/avator`, formData);
            };
            static destroy = (travelerId) => {
                return axios.delete(`/travelers/${travelerId}/avator`);
            };
        }

        static socialmedias = class {
            static index = (travelerId, query, page, pageSize) => {
                if (!query) query = {};
                return axios.get(`/travelers/${travelerId}/socialmedias`, {
                    params: {
                        id: query.id,
                        q: query.name,
                        page: page || 1,
                        count: pageSize || defaultPageSize
                    },
                });
            };

            static show = (travelerId, id) => axios.get(`/travelers/${travelerId}/socialmedias/${id}`);
            static store = (travelerId, data) => {
                const formData = O2ToursAPI.toFormData(data);
                return axios.post(`/travelers/${travelerId}/socialmedias`, formData);
            }
            static update = (travelerId, id, data) => {
                return axios.patch(`/travelers/${travelerId}/socialmedias/${id}`, data);
            }
            static destroy = (travelerId, id) => axios.delete(`/travelers/${travelerId}/socialmedias/${id}`);
        }

        static follows = class {
            static index = (travelerId, query, page, pageSize) => {
                if (!query) query = {};
                return axios.get(`/travelers/${travelerId}/follows`, {
                    params: {
                        id: query.id,
                        q: query.name,
                        page: page || 1,
                        count: pageSize || defaultPageSize
                    },
                });
            };
        }
        static followers = class {
            static index = (travelerId, query, page, pageSize) => {
                if (!query) query = {};
                return axios.get(`/travelers/${travelerId}/followers`, {
                    params: {
                        id: query.id,
                        q: query.name,
                        page: page || 1,
                        count: pageSize || defaultPageSize
                    },
                });
            };
        }
    };
    static socialmedias = class {
        static index = (query, page, pageSize) => {
            if (!query) query = {};
            return axios.get(`/socialmedias`, {
                params: {
                    id: query.id,
                    q: query.name,
                    page: page || 1,
                    count: pageSize || defaultPageSize
                },
            });
        };
    }
    static tours = class {
        static async index(query, page, pageSize, sort, direction) {
            const results = await axios.get('/tours', {
                params: {
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    sort: sort,
                    direction: O2ToursAPI.sortDirection(direction),
                    ...query
                },
            });
            return results;
        }
        static show = (id) => axios.get(`/tours/${id}`);
        static store = (data) => {
            const formData = O2ToursAPI.toFormData(data);
            return axios.post(`/tours`, formData)
        };
        static update = (id, data, coverFile) => {
            //const formData = O2ToursAPI.toFormData(data);
            //if (coverFile) formData.append("image_file", coverFile);
            //return axios.patch(`/tours/${id}`, formData);
            return axios.patch(`/tours/${id}`, data);
        }
        static destroy = (id) => axios.delete(`/tours/${id}`);

        static image = class {
            static store = (tourId, coverFile) => {
                const formData = O2ToursAPI.toFormData({});
                formData.append("image_file", coverFile);
                return axios.post(`/tours/${tourId}/image`, formData);
            };
            static destroy = (tourId) => {
                return axios.delete(`/tours/${tourId}/image`);
            };
        }

        static days = class {
            static async index(tourId, page, pageSize) {
                const results = await axios.get(`/tours/${tourId}/days`, {
                    params: {
                        page: page || 1,
                        count: pageSize || defaultPageSize
                    },
                });
                return results;
            }
            static show = (tourId, id) => axios.get(`/tours/${tourId}/days/${id}`);
            static store = (tourId, data) => {
                const formData = O2ToursAPI.toFormData(data);
                return axios.post(`/tours/${tourId}/days`, formData)
            };
            static update = (tourId, id, data, coverFile) => {
                return axios.patch(`/tours/${tourId}/days/${id}`, data);
            }
            static destroy = (tourId, id) => axios.delete(`/tours/${tourId}/days/${id}`);

            static places = class {
                static index = (tourId, dayId, page, pageSize) => axios.get(`/tours/${tourId}/days/${dayId}/places`, {
                    params: {
                        page: page || 1,
                        count: pageSize || defaultPageSize
                    },
                });

                static show = (tourId, dayId, id) => axios.get(`/tours/${tourId}/days/${dayId}/places/${id}`);
                static store = (tourId, dayId, data) => {
                    const formData = O2ToursAPI.toFormData(data);
                    return axios.post(`/tours/${tourId}/days/${dayId}/places`, formData)
                };
                static update = (tourId, dayId, id, data) => {
                    // const formData = O2ToursAPI.toFormData(data);
                    // return axios.patch(`/tours/${tourId}/days/${dayId}/places/${id}`, formData);
                    return axios.patch(`/tours/${tourId}/days/${dayId}/places/${id}`, data);
                };
                static destroy = (tourId, dayId, id) => axios.delete(`/tours/${tourId}/days/${dayId}/places/${id}`);

                static photos = class {
                    static index = (tourId, dayId, tourPlaceId, page, pageSize) => axios.get(`/tours/${tourId}/days/${dayId}/places/${tourPlaceId}/photos`, {
                        params: {
                            page: page || 1,
                            count: pageSize || defaultPageSize
                        },
                    });
                    static store = (tourId, dayId, tourPlaceId, data, imageFile) => {
                        const formData = O2ToursAPI.toFormData(data);
                        formData.append("image_file", imageFile);
                        return axios.post(`/tours/${tourId}/days/${dayId}/places/${tourPlaceId}/photos`, formData);
                    };
                    static destroy = (tourId, dayId, tourPlaceId, id) => axios.delete(`/tours/${tourId}/days/${dayId}/places/${tourPlaceId}/photos/${id}`);
                }
            };

        };

        static tags = class {
            static index = (tourId, page, pageSize) => axios.get(`/tours/${tourId}/tags`, {
                params: {
                    page: page || 1,
                    count: pageSize || defaultPageSize
                },
            });

            static show = (tourId, id) => axios.get(`/tours/${tourId}/tags/${id}`);
            static store = (tourId, data) => {
                const formData = O2ToursAPI.toFormData(data);
                return axios.post(`/tours/${tourId}/tags`, formData);
            }
            static update = (tourId, id, data) => {
                // const formData = O2ToursAPI.toFormData(data);
                // return axios.patch(`/tours/${tourId}/tags/${id}`, formData);
                return axios.patch(`/tours/${tourId}/tags/${id}`, data);
            }
            static destroy = (tourId, id) => axios.delete(`/tours/${tourId}/tags/${id}`);
        };

        static categories = class {
            static index = (tourId, page, pageSize) => axios.get(`/tours/${tourId}/categories`, {
                params: {
                    page: page || 1,
                    count: pageSize || defaultPageSize
                },
            });

            static show = (tourId, id) => axios.get(`/tours/${tourId}/categories/${id}`);
            static store = (tourId, data) => {
                const formData = O2ToursAPI.toFormData(data);
                return axios.post(`/tours/${tourId}/categories`, formData);
            }
            static update = (tourId, id, data) => {
                // const formData = O2ToursAPI.toFormData(data);
                // return axios.patch(`/tours/${tourId}/categories/${id}`, formData);
                return axios.patch(`/tours/${tourId}/categories/${id}`, data);
            }
            static destroy = (tourId, id) => axios.delete(`/tours/${tourId}/categories/${id}`);
        };
        static interested = class {
            static index = (tourId, page, pageSize) => axios.get(`/tours/${tourId}/interested`, {
                params: {
                    page: page || 1,
                    count: pageSize || defaultPageSize
                },
            });

            static show = (tourId, id) => axios.get(`/tours/${tourId}/interested/${id}`);
            static store = (tourId, data) => {
                const formData = O2ToursAPI.toFormData(data);
                return axios.post(`/tours/${tourId}/interested`, formData);
            }
            static update = (tourId, id, data) => {
                // const formData = O2ToursAPI.toFormData(data);
                // return axios.patch(`/tours/${tourId}/interested/${id}`, formData);
                return axios.patch(`/tours/${tourId}/interested/${id}`, data);
            }
            static destroy = (tourId, id) => axios.delete(`/tours/${tourId}/interested/${id}`);
        };
    };
    static places = class {
        static index = (query, page, pageSize, sort, direction) => {
            if (!query) query = {};
            return axios.get(`/places`, {
                params: {
                    id: query.id,
                    q: query.name,
                    page: page || 1,
                    count: pageSize || defaultPageSize,
                    sort: sort,
                    direction: O2ToursAPI.sortDirection(direction),
                },
            });
        };

        static show = (id) => axios.get(`/places/${id}`);
        static store = (data) => {
            const formData = O2ToursAPI.toFormData(data);
            return axios.post(`/places`, formData);
        }
        static update = (id, data) => {
            // const formData = O2ToursAPI.toFormData(data);
            // return axios.patch(`/places/${id}`, formData);
            return axios.patch(`/places/${id}`, data);
        }
        static destroy = (id) => axios.delete(`/places/${id}`);

        static search = (input) => axios.get(`/places/search`, {
            params: {
                input: input
            },
        });
        static photos = class {
            static index = (placeId, page, pageSize) => {
                return axios.get(`/places/${placeId}/photos`, {
                    params: {
                        page: page || 1,
                        count: pageSize || defaultPageSize
                    },
                });
            };

            static show = (placeId, id) => axios.get(`/places/${placeId}/photos/${id}`);
            static store = (placeId, data, imageFile) => {
                const formData = O2ToursAPI.toFormData(data);
                formData.append("image_file", imageFile);
                return axios.post(`/places/${placeId}/photos`, formData);
            }
            static update = (placeId, id, data) => {
                // const formData = O2ToursAPI.toFormData(data);
                // return axios.patch(`/places/${placeId}/photos/${id}`, formData);
                return axios.patch(`/places/${placeId}/photos/${id}`, data);
            }
            static destroy = (placeId, id) => axios.delete(`/places/${placeId}/photos/${id}`);
        }
    };
    static tags = class {
        static index = (page, pageSize) => axios.get(`/tags`, {
            params: {
                page: page || 1,
                count: pageSize || defaultPageSize
            },
        });

        static show = (id) => axios.get(`/tags/${id}`);
        static store = (data) => {
            const formData = O2ToursAPI.toFormData(data);
            return axios.post(`/tags`, formData);
        }
        static update = (id, data) => {
            // const formData = O2ToursAPI.toFormData(data);
            // return axios.patch(`/tags/${id}`, formData);
            return axios.patch(`/tags/${id}`, data);
        }
        static destroy = (id) => axios.delete(`/tags/${id}`);
    };
    static categories = class {
        static index = (page, pageSize) => axios.get(`/categories`, {
            params: {
                page: page || 1,
                count: pageSize || defaultPageSize
            },
        });

        static show = (id) => axios.get(`/categories/${id}`);
        static store = (data) => {
            const formData = O2ToursAPI.toFormData(data);
            return axios.post(`/categories`, formData);
        }
        static update = (id, data) => {
            // const formData = O2ToursAPI.toFormData(data);
            // return axios.patch(`/categories/${id}`, formData);
            return axios.patch(`/categories/${id}`, data);
        }
        static destroy = (id) => axios.delete(`/categories/${id}`);

        static sortIndex = class {
            static update = (categories) => {
                return axios.post(`/categories/sort-index`, { categories: categories });
            }
        }
    };
    static regions = class {
        static index = (query, page, pageSize) => {
            if (!query) query = {};
            return axios.get(`/regions`, {
                params: {
                    id: query.id,
                    q: query.name,
                    page: page || 1,
                    count: pageSize || defaultPageSize
                },
            });
        };
        static show = (id) => axios.get(`/regions/${id}`);
        static store = (data) => {
            const formData = O2ToursAPI.toFormData(data);
            return axios.post(`/regions`, formData);
        }
        static update = (id, data) => {
            // const formData = O2ToursAPI.toFormData(data);
            // return axios.patch(`/regions/${id}`, formData);
            return axios.patch(`/regions/${id}`, data);
        }
        static destroy = (id) => axios.delete(`/regions/${id}`);
    };
    static users = class {
        static index = (query, page, pageSize) => {
            return axios.get(`/users`, {
                params: {
                    page: page || 1,
                    count: pageSize || 30,
                    ...query
                },
            });
        }
        static show = (id) => axios.get(`/users/${id}`)
        static store = (data) => {
            return axios.post(`/users`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        }
        static update = (id, data) => {
            return axios.patch(`/users/${id}`, data);
        }

        static destroy = (id) => axios.delete(`/users/${id}`);
    };
    static countries = class {
        static index = (page, pageSize) => axios.get(`/countries`, {
            params: {
                page: page || 1,
                count: pageSize || defaultPageSize
            },
        });
    }
    static achievements = class {
        static index = (query, page, pageSize) => {
            return axios.get(`/achievements`, {
                params: {
                    page: page || 1,
                    count: pageSize || 30,
                    ...query
                },
            });
        }
        static show = (id) => axios.get(`/achievements/${id}`)
        static store = (data) => {
            return axios.post(`/achievements`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        }
        static update = (id, data) => {
            return axios.patch(`/achievements/${id}`, data);
        }

        static destroy = (id) => axios.delete(`/achievements/${id}`);
    }
    static achievementTypes = class {
        static index = (query, page, pageSize) => {
            return axios.get(`/achievement-types`, {
                params: {
                    page: page || 1,
                    count: pageSize || 30,
                    ...query
                },
            });
        }
        static show = (id) => axios.get(`/achievement-types/${id}`)
        static store = (data) => {
            return axios.post(`/achievement-types`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        }
        static update = (id, data) => {
            return axios.patch(`/achievement-types/${id}`, data);
        }

        static destroy = (id) => axios.delete(`/achievement-types/${id}`);
    }
    static certificates = class {
        static index = (query, page, pageSize) => {
            return axios.get(`/certificates`, {
                params: {
                    page: page || 1,
                    count: pageSize || 30,
                    ...query
                },
            });
        }
        static show = (id) => axios.get(`/certificates/${id}`)
        static store = (data) => {
            return axios.post(`/certificates`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        }
        static update = (id, data) => {
            return axios.patch(`/certificates/${id}`, data);
        }

        static destroy = (id) => axios.delete(`/certificates/${id}`);
    }
    static certificateTypes = class {
        static index = (query, page, pageSize) => {
            return axios.get(`/certificate-types`, {
                params: {
                    page: page || 1,
                    count: pageSize || 30,
                    ...query
                },
            });
        }
        static show = (id) => axios.get(`/certificate-types/${id}`)
        static store = (data) => {
            return axios.post(`/certificate-types`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        }
        static update = (id, data) => {
            return axios.patch(`/certificate-types/${id}`, data);
        }

        static destroy = (id) => axios.delete(`/certificate-types/${id}`);
    }
    static certificateSystems = class {
        static index = (query, page, pageSize) => {
            return axios.get(`/certificate-systems`, {
                params: {
                    page: page || 1,
                    count: pageSize || 30,
                    ...query
                },
            });
        }
        static show = (id) => axios.get(`/certificate-systems/${id}`)
        static store = (data) => {
            return axios.post(`/certificate-systems`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        }
        static update = (id, data) => {
            return axios.patch(`/certificate-systems/${id}`, data);
        }

        static destroy = (id) => axios.delete(`/certificate-systems/${id}`);
    }
    static transportations = class {
        static index = (query, page, pageSize) => {
            return axios.get(`/transportations`, {
                params: {
                    page: page || 1,
                    count: pageSize || 30,
                    ...query
                },
            });
        }
        static show = (id) => axios.get(`/transportations/${id}`)
        static store = (data) => {
            return axios.post(`/transportations`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        }
        static update = (id, data) => {
            return axios.patch(`/transportations/${id}`, data);
        }

        static destroy = (id) => axios.delete(`/transportations/${id}`);
    }

    // 旅者相關 API
    static traveler = class {
        static index = () => {
            return axios.get(`/traveler`);
        }
        static show = () => axios.get(`/traveler`)
        static update = (data) => {
            return axios.patch(`/traveler`, data);
        }
        static tours = class {
            static index = (query, page, pageSize, sort, direction) => {
                if (!query) query = {};
                return axios.get(`/traveler/tours`, {
                    params: {
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                        sort: sort,
                        direction: O2ToursAPI.sortDirection(direction),
                        ...query
                    },
                });
            };

            static show = (id) => axios.get(`/traveler/tours/${id}`);
            static store = (data) => {
                // const formData = O2ToursAPI.toFormData(data);
                return axios.post(`/traveler/tours`, data);
            }
            static update = (id, data) => {
                // const formData = O2ToursAPI.toFormData(data);
                // return axios.patch(`/traveler/tours/${id}`, formData);
                return axios.patch(`/traveler/tours/${id}`, data);
            }
            static destroy = (id) => axios.delete(`/traveler/tours/${id}`);

            static initiate = (id, values) => axios.post(`/traveler/tours/${id}/initiate`, values);

            static image = class {
                static store = (tourId, coverFile) => {
                    const formData = O2ToursAPI.toFormData({});
                    formData.append("image_file", coverFile);
                    return axios.post(`/traveler/tours/${tourId}/image`, formData);
                };
                static destroy = (tourId) => {
                    return axios.delete(`/traveler/tours/${tourId}/image`);
                };
            }
            static interested = class {
                static index = (tourId, page, pageSize) => axios.get(`/traveler/tours/${tourId}/interested`, {
                    params: {
                        page: page || 1,
                        count: pageSize || defaultPageSize
                    },
                });

                static show = (tourId, id) => axios.get(`/traveler/tours/${tourId}/interested/${id}`);
                static store = (tourId, data) => {
                    const formData = O2ToursAPI.toFormData(data);
                    return axios.post(`/traveler/tours/${tourId}/interested`, formData);
                }
                static update = (tourId, id, data) => {
                    // const formData = O2ToursAPI.toFormData(data);
                    // return axios.patch(`/traveler/tours/${tourId}/interested/${id}`, formData);
                    return axios.patch(`/traveler/tours/${tourId}/interested/${id}`, data);
                }
                static destroy = (tourId, id) => axios.delete(`/traveler/tours/${tourId}/interested/${id}`);
            };

            static categories = class {

            }
            static days = class {
                static async index(tourId, page, pageSize) {
                    const results = await axios.get(`/traveler/tours/${tourId}/days`, {
                        params: {
                            page: page || 1,
                            count: pageSize || defaultPageSize
                        },
                    });
                    return results;
                }
                static show = (tourId, id) => axios.get(`/traveler/tours/${tourId}/days/${id}`);
                static store = (tourId, data) => {
                    const formData = O2ToursAPI.toFormData(data);
                    return axios.post(`/traveler/tours/${tourId}/days`, formData)
                };
                static update = (tourId, id, data, coverFile) => {
                    return axios.patch(`/traveler/tours/${tourId}/days/${id}`, data);
                }
                static destroy = (tourId, id) => axios.delete(`/traveler/tours/${tourId}/days/${id}`);

                static places = class {
                    static index = (tourId, dayId, page, pageSize) => axios.get(`/traveler/tours/${tourId}/days/${dayId}/places`, {
                        params: {
                            page: page || 1,
                            count: pageSize || defaultPageSize
                        },
                    });

                    static show = (tourId, dayId, id) => axios.get(`/traveler/tours/${tourId}/days/${dayId}/places/${id}`);
                    static store = (tourId, dayId, data) => {
                        const formData = O2ToursAPI.toFormData(data);
                        return axios.post(`/traveler/tours/${tourId}/days/${dayId}/places`, formData)
                    };
                    static update = (tourId, dayId, id, data) => {
                        // const formData = O2ToursAPI.toFormData(data);
                        // return axios.patch(`/traveler/tours/${tourId}/days/${dayId}/places/${id}`, formData);
                        return axios.patch(`/traveler/tours/${tourId}/days/${dayId}/places/${id}`, data);
                    };
                    static destroy = (tourId, dayId, id) => axios.delete(`/traveler/tours/${tourId}/days/${dayId}/places/${id}`);

                    static photos = class {
                        static index = (tourId, dayId, tourPlaceId, page, pageSize) => axios.get(`/traveler/tours/${tourId}/days/${dayId}/places/${tourPlaceId}/photos`, {
                            params: {
                                page: page || 1,
                                count: pageSize || defaultPageSize
                            },
                        });
                        static store = (tourId, dayId, tourPlaceId, data, imageFile) => {
                            const formData = O2ToursAPI.toFormData(data);
                            formData.append("image_file", imageFile);
                            return axios.post(`/traveler/tours/${tourId}/days/${dayId}/places/${tourPlaceId}/photos`, formData);
                        };
                        static destroy = (tourId, dayId, tourPlaceId, id) => axios.delete(`/traveler/tours/${tourId}/days/${dayId}/places/${tourPlaceId}/photos/${id}`);
                    }
                };

            };

            static plans = class {
                static index = (tourId, page, pageSize) => axios.get(`/traveler/tours/${tourId}/plans`, {
                    params: {
                        page: page || 1,
                        count: pageSize || defaultPageSize
                    },
                });
                static show = (tourId, id) => axios.get(`/traveler/tours/${tourId}/plans/${id}`);

                static store = (tourId, data) => {
                    return axios.post(`/traveler/tours/${tourId}/plans`, data);
                }
                static update = (tourId, id, data) => {
                    return axios.patch(`/traveler/tours/${tourId}/plans/${id}`, data);
                }
                static destroy = (tourId, id) => axios.delete(`/traveler/tours/${tourId}/plans/${id}`);
            }
            static periods = class {
                static index = (tourId, page, pageSize) => axios.get(`/traveler/tours/${tourId}/periods`, {
                    params: {
                        page: page || 1,
                        count: pageSize || defaultPageSize
                    },
                });
                static show = (tourId, id) => axios.get(`/traveler/tours/${tourId}/periods/${id}`);

                static store = (tourId, data) => {
                    return axios.post(`/traveler/tours/${tourId}/periods`, data);
                }
                static update = (tourId, id, data) => {
                    return axios.patch(`/traveler/tours/${tourId}/periods/${id}`, data);
                }
                static destroy = (tourId, id) => axios.delete(`/traveler/tours/${tourId}/periods/${id}`);

                static attendees = class {
                    static index = (tourId, periodId) => axios.get(`/traveler/tours/${tourId}/periods/${periodId}/attendees`);

                    static destroy = (tourId, periodId, id) => axios.delete(`/traveler/tours/${tourId}/periods/${periodId}/attendees/${id}`);
                }
            }
            static interestTravelers = class {

            }
        };
        static tourPeriods = class {
            static index = (query, page, pageSize) => {
                if (!query) query = {};
                return axios.get(`/traveler/tour-periods`, {
                    params: {
                        id: query.id,
                        q: query.name,
                        page: page || 1,
                        count: pageSize || defaultPageSize
                    },
                });
            };
            static show = (id) => axios.get(`/traveler/tour-periods/${id}`);

            static attendees = class {
                static index = (tourId, query, page, pageSize) => {
                    if (!query) query = {};
                    return axios.get(`/traveler/tour-periods/${tourId}/attendees`, {
                        params: {
                            id: query.id,
                            q: query.name,
                            page: page || 1,
                            count: pageSize || defaultPageSize
                        },
                    });
                };
                static show = (tourId, id) => axios.get(`/traveler/tour-periods/${tourId}/attendees/${id}`);

                static store = (tourId, data) => {
                    return axios.post(`/traveler/tour-periods/${tourId}/attendees`, data);
                }
                static update = (tourId, id, data) => {
                    return axios.patch(`/traveler/tour-periods/${tourId}/attendees/${id}`, data);
                }
                static destroy = (tourId, id) => axios.delete(`/traveler/tour-periods/${tourId}/attendees/${id}`);
            }
        }
        static socialmedias = class {
            static index = (query, page, pageSize) => {
                if (!query) query = {};
                return axios.get(`/traveler/socialmedias`, {
                    params: {
                        id: query.id,
                        q: query.name,
                        page: page || 1,
                        count: pageSize || defaultPageSize
                    },
                });
            };

            static show = (id) => axios.get(`/traveler/socialmedias/${id}`);
            static store = (data) => {
                const formData = O2ToursAPI.toFormData(data);
                return axios.post(`/traveler/socialmedias`, formData);
            }
            static update = (id, data) => {
                return axios.patch(`/traveler/socialmedias/${id}`, data);
            }
            static destroy = (id) => axios.delete(`/traveler/socialmedias/${id}`);
        }
        static follows = class {
            static index = (query, page, pageSize) => {
                if (!query) query = {};
                return axios.get(`/traveler/follows`, {
                    params: {
                        id: query.id,
                        q: query.name,
                        page: page || 1,
                        count: pageSize || defaultPageSize
                    },
                });
            };
        }
        static followers = class {
            static index = (query, page, pageSize) => {
                if (!query) query = {};
                return axios.get(`/traveler/followers`, {
                    params: {
                        id: query.id,
                        q: query.name,
                        page: page || 1,
                        count: pageSize || defaultPageSize
                    },
                });
            };
        }
        static avator = class {
            static store = (coverFile) => {
                const formData = O2ToursAPI.toFormData({});
                formData.append("avator", coverFile);
                return axios.post(`/traveler/avator`, formData);
            };
            static destroy = () => {
                return axios.delete(`/traveler/avator`);
            };
        }
        static achievements = class {
            static index = (query, page, pageSize) => {
                return axios.get(`/traveler/achievements`, {
                    params: {
                        page: page || 1,
                        count: pageSize || 30,
                        ...query
                    },
                });
            }
            static show = (id) => axios.get(`/traveler/achievements/${id}`)
            static store = (data, imageFile) => {
                const formData = O2ToursAPI.toFormData(data);
                formData.append("imagefile", imageFile);
                return axios.post(`/traveler/achievements`, formData);
            }
            static update = (id, data) => {
                return axios.patch(`/traveler/achievements/${id}`, data);
            }
            static destroy = (id) => axios.delete(`/traveler/achievements/${id}`);
        }
        static equipments = class {
            static index = (query, page, pageSize) => {
                return axios.get(`/traveler/equipment`, {
                    params: {
                        page: page || 1,
                        count: pageSize || 30,
                        ...query
                    },
                });
            }
            static show = (id) => axios.get(`/traveler/equipment/${id}`)
            static store = (data, imageFile) => {
                const formData = O2ToursAPI.toFormData(data);
                formData.append("imagefile", imageFile);
                return axios.post(`/traveler/equipment`, formData);
            }
            static update = (id, data, imageFile) => {
                // TODO: 目前無法修改圖片
                // const formData = O2ToursAPI.toFormData(data);
                // formData.append("imagefile", imageFile);
                return axios.patch(`/traveler/equipment/${id}`, data);
            }
            static destroy = (id) => axios.delete(`/traveler/equipment/${id}`);
        }
        static certificates = class {
            static index = (query, page, pageSize) => {
                return axios.get(`/traveler/certificates`, {
                    params: {
                        page: page || 1,
                        count: pageSize || 30,
                        ...query
                    },
                });
            }
            static show = (id) => axios.get(`/traveler/certificates/${id}`)
            static store = (data) => {
                return axios.post(`/traveler/certificates`, data);
            }
            static update = (id, data) => {
                return axios.patch(`/traveler/certificates/${id}`, data);
            }
            static destroy = (id) => axios.delete(`/traveler/certificates/${id}`);
        }
    }
}
