import { Button, DatePicker, Drawer, Form, Input, InputNumber, Modal, notification, Popconfirm, Space, Spin, Switch, Table, Typography, Row, Col } from 'antd'
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import O2ToursAPI from '../../../api/O2ToursAPI';
import dayjs from 'dayjs';
import TravelerTourPlanSelect from './TravelerTourPlanSelect';
import TextEditor from '../../../components/TextEditor';

function TravelerTourPlanTabPane({ tourId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPlans, setIsLoadingPlans] = useState(false);
  const [plans, setPlans] = useState([]);
  const [isLoadingPeriods, setIsLoadingPeriods] = useState(false);
  const [periods, setPeriods] = useState([]);

  const [editPeriod, setEditPeriod] = useState({ open: false, id: null });
  const [editPeriodForm] = useForm();

  const [editPlan, setEditPlan] = useState({ open: false, id: null });
  const [editPlanForm] = useForm();

  const [openGroupForm] = useForm();

  const [showAttendees, setShowAttendees] = useState({ open: false, id: null });
  const [isLoadingAttendees, setIsLoadingAttendees] = useState(false);
  const [attendees, setAttendees] = useState([]);

  const editPeriodPlanSelect = useRef();

  const refreshPlans = useCallback(() => {
    if (!tourId) return;
    setIsLoadingPlans(true);
    O2ToursAPI.traveler.tours.plans.index(tourId, 0, 999).then((response) => {
      const result = response.data.data;
      setPlans(result);
    }).finally(() => {
      setIsLoadingPlans(false);
    });

    if (editPeriodPlanSelect.current) {
      editPeriodPlanSelect.current.refresh();
    }
  }, [editPeriodPlanSelect, tourId])

  const refreshPeriods = useCallback(() => {
    if (!tourId) return;
    setIsLoadingPeriods(true);
    O2ToursAPI.traveler.tours.periods.index(tourId, 0, 999).then((response) => {
      const result = response.data.data;
      console.log("periods", result);
      setPeriods(result);
    }).finally(() => {
      setIsLoadingPeriods(false);
    });
  }, [tourId]);

  const refreshTour = useCallback(() => {
    setIsLoading(true);
    O2ToursAPI.traveler.tours.show(tourId).then((response) => {
      const tour = response.data;
      openGroupForm.setFieldsValue(tour);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [openGroupForm, tourId]);

  useEffect(() => {
    refreshPlans();
  }, [refreshPlans]);

  useEffect(() => {
    refreshPeriods();
  }, [refreshPeriods]);

  useEffect(() => {
    refreshTour();
  }, [refreshTour]);

  const handleAddPeriod = () => {
    setEditPeriod({
      open: true,
      id: null,
    });
    editPeriodForm.resetFields();
  }
  const handleEditPeriod = (id) => {
    setEditPeriod({
      open: true,
      id: id
    });
    O2ToursAPI.traveler.tours.periods.show(tourId, id).then((response) => {
      const result = response.data;
      console.log("result", result);
      editPeriodForm.setFieldsValue({
        ...result,
        regstartdate: result.regstartdate ? dayjs(result.regstartdate) : null,
        regenddate: result.regenddate ? dayjs(result.regenddate) : null,
        startdate: result.startdate ? dayjs(result.startdate) : null,
        enddate: result.enddate ? dayjs(result.enddate) : null,
      });
    }).finally(() => {

    });
  }
  const handleSaveEditPeriod = () => {
    editPeriodForm.validateFields().then((values) => {
      editPeriodForm.submit();
      setEditPeriod({
        open: false,
        id: null
      });
    });
  }
  const handleFinishEditPeriod = (values) => {
    console.log("handleFinish", values);
    const newValues = {
      ...values,
      regenddate: values.regenddate?.format("YYYY-MM-DD"),
      regstartdate: values.regstartdate?.format("YYYY-MM-DD"),
      startdate: values.startdate?.format("YYYY-MM-DD"),
      enddate: values.enddate?.format("YYYY-MM-DD"),
      ended: values.ended ? 1 : 0,
    }
    if (!editPeriod.id) {
      O2ToursAPI.traveler.tours.periods.store(tourId, newValues).then((response) => {
        notification.info({ message: '新增成功' });
      }).catch((error) => {
        notification.info({ message: '新增失敗' });
      }).finally(() => {
        refreshPeriods();
      });
    } else {
      O2ToursAPI.traveler.tours.periods.update(tourId, editPeriod.id, newValues).then((response) => {
        notification.info({ message: '修改成功' });
      }).catch((error) => {
        notification.info({ message: '修改失敗' });
      }).finally(() => {
        refreshPeriods();
      });
    }
  }
  const handleCancelEditPeriod = () => {
    setEditPeriod({
      open: false,
      id: null
    });
  }
  const handleDeletePeriod = (id) => {
    O2ToursAPI.traveler.tours.periods.destroy(tourId, id).then((response) => {
      notification.info({ message: '刪除成功' });
    }).finally(() => {
      refreshPeriods();
    })
  }
  const handleAddPlan = () => {
    setEditPlan({
      open: true,
      id: null,
    });
    editPlanForm.resetFields();
  }
  const handleEditPlan = (id) => {
    setEditPlan({
      open: true,
      id: id
    });
    O2ToursAPI.traveler.tours.plans.show(tourId, id).then((response) => {
      const result = response.data;
      console.log("result", result);
      editPlanForm.setFieldsValue({
        ...result,
      });
    }).finally(() => {

    });
  }
  const handleSaveEditPlan = () => {
    editPlanForm.submit();
    setEditPlan({
      open: false,
      id: null
    });
  }
  const handleFinishEditPlan = (values) => {
    console.log("handleFinishEditPlan", values);
    const newValues = {
      ...values,
    }
    if (!editPlan.id) {
      O2ToursAPI.traveler.tours.plans.store(tourId, values).then((response) => {
        notification.info({ message: '新增成功' });
      }).catch((error) => {
        notification.info({ message: '新增失敗' });
      }).finally(() => {
        refreshPlans();
      });
    } else {
      O2ToursAPI.traveler.tours.plans.update(tourId, editPlan.id, newValues).then((response) => {
        notification.info({ message: '修改成功' });
      }).catch((error) => {
        notification.info({ message: '修改失敗' });
      }).finally(() => {
        refreshPlans();
      });
    }
  }
  const handleCancelEditPlan = () => {
    setEditPlan({
      open: false,
      id: null
    });
  }
  const handleDeletePlan = (id) => {
    O2ToursAPI.traveler.tours.plans.destroy(tourId, id).then((response) => {
      notification.info({ message: "刪除成功" });
    }).finally(() => {
      refreshPlans();
    });
  }


  const handleSaveOpenGroup = () => {
    console.log("openGroupForm", openGroupForm);
    if (openGroupForm) {
      openGroupForm.submit();
    }
  }
  const handleFinishOpenGroupForm = (values) => {
    console.log("handleFinishOpenGroupForm", values);

    O2ToursAPI.traveler.tours.initiate(tourId, values).then((response) => {
      notification.info({ message: '儲存開團資訊' });
    }).finally(() => {

    });
  }

  const refreshAttendees = (periodId) => {
    const id = periodId || showAttendees.periodId;
    setIsLoadingAttendees(true);
    O2ToursAPI.traveler.tours.periods.attendees.index(tourId, id).then((response) => {
      const result = response.data;
      setAttendees(result.data);
    }).finally(() => {
      setIsLoadingAttendees(false);
    });
  }
  const handleShowAttendees = (periodId) => {
    setShowAttendees({
      open: true,
      periodId: periodId
    });
    refreshAttendees(periodId);
  }
  const handleHideAttendees = () => {
    setShowAttendees({
      open: false,
      id: null
    });
    setAttendees([]);
  }
  const handleDeleteAttendee = (id) => {
    O2ToursAPI.traveler.tours.periods.attendees.destroy(tourId, showAttendees.periodId, id).then((response) => {
      notification.info({ message: '刪除參加者成功' });
    }).finally(() => {
      refreshAttendees();
    });
  }
  return (
    <>
      <Row gutter={[10, 20]}>
        <Col span={24}>
          <h3>方案</h3>
          <Button onClick={handleAddPlan}>新增方案</Button>
          <Table dataSource={plans} columns={[
            {
              dataIndex: 'name',
              title: '方案名稱',
            },
            {
              dataIndex: 'description',
              title: '說明',
            },
            {
              dataIndex: 'price',
              title: '費用',
            },
            {
              dataIndex: '',
              title: '操作',
              render: (value, record) => <Space>
                <Typography.Link onClick={() => handleEditPlan(record.id)}>修改</Typography.Link>
                <Popconfirm title="確認要刪除方案?" onConfirm={() => handleDeletePlan(record.id)}>
                  <Typography.Link>刪除</Typography.Link>
                </Popconfirm>
              </Space>
            },
          ]} loading={isLoadingPlans} rowKey="id" pagination={false} />
        </Col>
        <Col span={24}>
          <h3>出發日</h3>
          <Button onClick={handleAddPeriod}>新增期間</Button>
          <Table dataSource={periods} columns={[
            {
              dataIndex: 'tourplan_id',
              title: '方案',
              render: (value, record) => record?.plan?.name,
            },
            {
              dataIndex: 'startdate',
              title: '出發日期',
            },
            {
              dataIndex: 'maxnumber',
              title: '人數限制',
              align: 'right',
            },
            {
              dataIndex: 'attendeecount',
              title: '已報名人數',
              align: 'right',
            },
            {
              dataIndex: 'regstartdate',
              title: '報名開始日期',
            },
            {
              dataIndex: 'regenddate',
              title: '報名結束日期',
            },
            {
              dataIndex: 'ended',
              title: '手動結束',
              render: (value) => value ? '是' : '否',
            },
            {
              dataIndex: 'totalamount',
              title: '費用總計',
              align: 'right',
            },
            {
              title: '操作',
              render: (value, record) => <Space>
                <Typography.Link onClick={() => handleShowAttendees(record.id)}>報名名單</Typography.Link>
                <Typography.Link onClick={() => handleEditPeriod(record.id)}>修改</Typography.Link>
                <Popconfirm title="確認要刪除期間?" onConfirm={() => handleDeletePeriod(record.id)}>
                  <Typography.Link>刪除</Typography.Link>
                </Popconfirm>
              </Space>
            },
          ]} loading={isLoadingPeriods} rowKey="id" pagination={false} scroll={{ x: 1000 }} />
        </Col>
        <Col span={24}>
          <h3>其他</h3>
          <Spin spinning={isLoading}>
            <Form form={openGroupForm} onFinish={handleFinishOpenGroupForm} labelCol={{ span: 3 }}>
              <Form.Item name="regnotice" label="報名注意事項">
                <TextEditor />
              </Form.Item>
              <Form.Item name="remitdesc" label="匯款資訊">
                <TextEditor />
              </Form.Item>
              <Form.Item name="initiated" label="開團" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Form>
            <div style={{ textAlign: 'center' }}>
              <Button type="primary" onClick={handleSaveOpenGroup}>儲存</Button>
            </div>
          </Spin>
        </Col>
      </Row>

      <Modal title="新增/修改期間" open={editPeriod.open} onOk={handleSaveEditPeriod} onCancel={handleCancelEditPeriod}>
        <Form form={editPeriodForm} onFinish={handleFinishEditPeriod} initialValues={{ maxnumber: 10 }} labelCol={{ span: 6 }}>
          <Form.Item name="tourplan_id" label="方案" rules={[{ required: true }]}>
            <TravelerTourPlanSelect tourId={tourId} ref={editPeriodPlanSelect} />
          </Form.Item>
          <Form.Item name="startdate" label="出發日期" rules={[{ required: true }]}>
            <DatePicker disabledDate={(currentDate) => {
              if (editPeriodForm.getFieldValue('regenddate')) {
                return currentDate.isAfter(editPeriodForm.getFieldValue('regenddate'));
              }
              return false;
            }} />
          </Form.Item>
          <Form.Item name="enddate" label="結束日期">
            <DatePicker disabledDate={(currentDate) => {
              if (editPeriodForm.getFieldValue('regenddate')) {
                return currentDate.isAfter(editPeriodForm.getFieldValue('regenddate')) || currentDate.isBefore(editPeriodForm.getFieldValue('startdate'));
              }
              return currentDate.isBefore(editPeriodForm.getFieldValue('startdate'));
            }} />
          </Form.Item>
          <Form.Item name="maxnumber" label="人數限制" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item name="regstartdate" label="報名開始日期" rules={[{ required: true }]}>
            <DatePicker disabledDate={(currentDate) => {
              var disabled = false;
              if (editPeriodForm.getFieldValue('startdate')) {
                disabled = disabled || currentDate.isAfter(editPeriodForm.getFieldValue('startdate'));
              }
              if (editPeriodForm.getFieldValue('regenddate')) {
                disabled = disabled || currentDate.isAfter(editPeriodForm.getFieldValue('regenddate'));
              }
              return disabled;
            }} />
          </Form.Item>
          <Form.Item name="regenddate" label="報名結束日期" rules={[{ required: true }]}>
            <DatePicker disabledDate={(currentDate) => {
              var disabled = false;
              if (editPeriodForm.getFieldValue('startdate')) {
                disabled = disabled || currentDate.isAfter(editPeriodForm.getFieldValue('startdate'));
              }
              if (editPeriodForm.getFieldValue('regstartdate')) {
                disabled = disabled || currentDate.isBefore(editPeriodForm.getFieldValue('regstartdate'));
              }
              return disabled;
            }} />
          </Form.Item>
          <Form.Item name="ended" label="手動結束" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
      <Modal title="新增/修改方案" open={editPlan.open} onOk={handleSaveEditPlan} onCancel={handleCancelEditPlan}>
        <Form form={editPlanForm} onFinish={handleFinishEditPlan} initialValues={{}} labelCol={{ span: 6 }}>
          <Form.Item name="name" label="方案名稱">
            <Input />
          </Form.Item>
          <Form.Item name="description" label="說明">
            <Input />
          </Form.Item>
          <Form.Item name="price" label="費用">
            <InputNumber min={0} />
          </Form.Item>
          {/* <Form.Item name="chargedesc" label="收費項目描述">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="nochargedesc" label="不包含收描述">
            <Input.TextArea />
          </Form.Item> */}
        </Form>
      </Modal>
      <Drawer title="參加者" open={showAttendees.open} onClose={handleHideAttendees} size="large">
        <Table dataSource={attendees} columns={[
          {
            dataIndex: 'name',
            title: '姓名',
          },
          {
            dataIndex: 'sex',
            title: '性別',
          },
          {
            dataIndex: 'birthdate',
            title: '生日',
          },
          {
            dataIndex: 'isforeignnational',
            title: '外國人?',
          },
          {
            dataIndex: 'country_id',
            title: '國藉',
          },
          {
            dataIndex: 'idnumber',
            title: '身份證/護照',
          },
          {
            dataIndex: 'cellphone',
            title: '手機號碼',
          },
          {
            dataIndex: 'meat',
            title: '葷/素'
          },
          {
            dataIndex: 'address',
            title: '地址'
          },
          {
            dataIndex: 'comment',
            title: '備註'
          },
          {
            dataIndex: 'emercontact',
            title: '緊急聯絡人'
          },
          {
            dataIndex: 'emercontacttel',
            title: '緊急聯絡人電話'
          },
          {
            dataIndex: 'experience',
            title: '登山經歷及運動習慣',
          },
          {
            dataIndex: 'historyofdrugallergy',
            title: '藥物過敏或疾病史'
          },
          {
            dataIndex: 'created_at',
            title: '新增日期',
            render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss")
          },
          // {
          //   dataIndex: 'createdtraveler_id',
          //   title: '新增旅者'
          // },
          // {
          //   dataIndex: 'createdtravelerip',
          //   title: '新增 IP 位址'
          // },
          // {
          //   dataIndex: 'state',
          //   title: '報名狀態'
          // },
          // {
          //   dataIndex: 'totalamount',
          //   title: '金額'
          // },
          {
            title: '操作',
            render: (value, record) => <Space>
              <Popconfirm title="您是否確定刪除參加者?" onConfirm={() => handleDeleteAttendee(record.id)}>
                <Typography.Link>刪除</Typography.Link>
              </Popconfirm>
            </Space>
          }
        ]} rowKey="id" loading={isLoadingAttendees} scroll={{ x: 2000 }} />
      </Drawer>
    </>
  )
}

export default TravelerTourPlanTabPane