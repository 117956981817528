import { Checkbox, Input, Tabs } from 'antd'
import dayjs from 'dayjs';
import React from 'react'
import O2ToursAPI from '../../api/O2ToursAPI'
import CRUDTableForm from '../../components/CRUDTableForm'

function AdminCertifications() {
    return (
        <>
            <Tabs defaultActiveKey="certificates" items={[
                {
                    key: 'certificates',
                    label: '證照',
                    children: <>
                        <CRUDTableForm
                            resourceName="證照"
                            onIndex={() => O2ToursAPI.certificates.index({}, 1, 999)}
                            onShow={(id) => O2ToursAPI.certificates.show(id)}
                            onStore={(values) => O2ToursAPI.certificates.store(values)}
                            onUpdate={(id, values) => O2ToursAPI.certificates.update(id, values)}
                            onDestroy={(id) => O2ToursAPI.certificates.destroy(id)}
                            columns={[
                                {
                                    dataIndex: 'id',
                                    title: 'ID'
                                },
                                {
                                    dataIndex: 'name',
                                    title: '證照名稱',
                                    formChildren: <Input />,
                                    formRules: [{ required: true }],
                                },
                                {
                                    dataIndex: 'alloweditname',
                                    title: '允許自訂名稱',
                                    width: 100,
                                    render: (value) => value ? '是' : '否',
                                    formChildren: <Checkbox />,
                                    formRules: [{ required: true }],
                                    formValuePropName: 'checked',
                                },
                                {
                                    dataIndex: 'certificatesystem_id',
                                    title: '系統',
                                    formChildren: <Input />,
                                },
                                {
                                    dataIndex: 'certificatetype_id',
                                    title: '類型',
                                    formChildren: <Input />,
                                },
                                {
                                    dataIndex: 'updated_at',
                                    title: '更新時間',
                                    width: 160,
                                    render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                                    sorter: true,
                                },
                                {
                                    dataIndex: 'created_at',
                                    title: '建立時間',
                                    width: 160,
                                    render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                                    sorter: true,
                                },
                            ]}
                        />
                    </>
                },
                {
                    key: 'types',
                    label: '類型',
                    children: <>
                        <CRUDTableForm
                            resourceName="證照類型"
                            onIndex={() => O2ToursAPI.certificateTypes.index({}, 1, 999)}
                            onShow={(id) => O2ToursAPI.certificateTypes.show(id)}
                            onStore={(values) => O2ToursAPI.certificateTypes.store(values)}
                            onUpdate={(id, values) => O2ToursAPI.certificateTypes.update(id, values)}
                            onDestroy={(id) => O2ToursAPI.certificateTypes.destroy(id)}
                            columns={[
                                {
                                    dataIndex: 'id',
                                    title: 'ID',
                                },
                                {
                                    dataIndex: 'name',
                                    title: '名稱',
                                    formChildren: <Input />,
                                    formRules: [{ required: true }],
                                },
                                {
                                    dataIndex: 'updated_at',
                                    title: '更新時間',
                                    width: 160,
                                    render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                                    sorter: true,
                                },
                                {
                                    dataIndex: 'created_at',
                                    title: '建立時間',
                                    width: 160,
                                    render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                                    sorter: true,
                                },
                            ]}
                        />
                    </>
                },
                {
                    key: 'systems',
                    label: '系統',
                    children: <>
                        <CRUDTableForm
                            resourceName="證照系統"
                            onIndex={() => O2ToursAPI.certificateSystems.index({}, 1, 999)}
                            onShow={(id) => O2ToursAPI.certificateSystems.show(id)}
                            onStore={(values) => O2ToursAPI.certificateSystems.store(values)}
                            onUpdate={(id, values) => O2ToursAPI.certificateSystems.update(id, values)}
                            onDestroy={(id) => O2ToursAPI.certificateSystems.destroy(id)}
                            columns={[
                                {
                                    dataIndex: 'id',
                                    title: 'ID',
                                },
                                {
                                    dataIndex: 'name',
                                    title: '名稱',
                                    formChildren: <Input />,
                                    formRules: [{ required: true }],
                                },
                                {
                                    dataIndex: 'updated_at',
                                    title: '更新時間',
                                    width: 160,
                                    render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                                    sorter: true,
                                },
                                {
                                    dataIndex: 'created_at',
                                    title: '建立時間',
                                    width: 160,
                                    render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                                    sorter: true,
                                },
                            ]}
                        />
                    </>
                },
            ]} />
        </>
    )
}

export default AdminCertifications