import dayjs from 'dayjs';
import React, { Component } from 'react'
import { Form, Input, Button, InputNumber, TimePicker, Spin, notification, Checkbox } from 'antd';
import TourDayPlacePhotoUpload from './TourDayPlacePhotoUpload';
import PlaceEditModal from './PlaceEditModal';
import PlaceSelect from './PlaceSelect';
import TextEditor from './TextEditor';

export class TourDayPlaceCard extends Component {
    initialValues = {
        day: 1,
        //place_id: 1,
        arrivetime: dayjs(),
        name: "待定",
        stayduration: 60,
        // gpslocation: "22.6304461,120.2844837",
        // description: "車城福安宮是東南亞最大的土地公廟，香火鼎盛，至今已經超過35"
    }
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.placeSelect = React.createRef();
        this.state = {
            mode: 'store',
            id: null,
            tourId: props.tourId,
            isLoading: false,
            value: undefined,
            placeCardVisible: false,
            showPlaceOptions: true,
        };
    }
    componentDidMount() {
        const tourId = this.props.tourId;
        const tourDayId = this.props.tourDayId;
        const id = this.props.id;
        this.fetchData(tourId, tourDayId, id);
    }
    fetchData = (tourId, tourDayId, id) => {
        if (!tourId || !tourDayId || !id) {
            // 若於 store 模式則會使用 props 傳來的預設值
            const defaultValues = this.props.defaultValues;
            defaultValues.arrivetime = dayjs(defaultValues.arrivetime, "HH:mm:ss");
            this.form.current.setFieldsValue(defaultValues);
            return;
        }
        console.log('fetchData', tourId, id);
        this.setState({ isLoading: true, mode: 'update', tourId: tourId, id: id });

        if (this.props.onPlacesShow) {
            this.props.onPlacesShow(tourId, tourDayId, id).then((response) => {
                const tourPlace = response.data;
                // Load data to form.
                tourPlace.arrivetime = dayjs(tourPlace.arrivetime, "HH:mm:ss");
                this.form.current.setFieldsValue(tourPlace);
                this.placeSelect.current.fetchByValue();
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        }
    }
    onFinish = (values) => {
        const tourId = this.props.tourId;
        const tourDayId = this.props.tourDayId;
        const id = this.state.id;
        console.log('TourPlaceCard: onFinish:', values);
        values.arrivetime = values.arrivetime.format('HH:mm:ss');
        values.displayplace = values.displayplace ? 1 : 0;
        if (this.state.mode === 'store') {
            this.setState({ isLoading: true });
            if (this.props.onPlacesStore) {
                this.props.onPlacesStore(tourId, tourDayId, values).then((response) => {
                    notification.info({
                        message: "新增成功"
                    });
                    if (this.props.onChange) {
                        console.log("TourPlaceCard: onChange: ", response.data);
                        this.props.onChange(response.data);
                    }
                    this.fetchData(tourId, tourDayId, response.data.id);
                }).finally(() => {
                    this.setState({ isLoading: false });
                });
            }
        } else {
            this.setState({ isLoading: true });
            if (this.props.onPlacesUpdate) {
                this.props.onPlacesUpdate(tourId, tourDayId, id, values).then((response) => {
                    notification.info({
                        message: "修改成功"
                    });
                    if (this.props.onChange) {
                        this.props.onChange(response.data);
                    }
                }).finally(() => {
                    this.setState({ isLoading: false });
                });
            }
        }
    }
    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }
    setPlaceCardVisible = (visible) => {
        this.setState({ placeCardVisible: visible });
    }
    handleAddPlace = () => {
        this.setPlaceCardVisible(true);
    }
    onAddPlace = (place) => {
        this.setPlaceCardVisible(false);
        // 自動代入 PlaceSelect
        console.log("onAddPlace", place);
        this.form.current.setFieldsValue({ place_id: place.id })
        this.placeSelect.current.fetchByValue();
    }
    handleUpdatePlace = (placeId) => {
        this.setPlaceCardVisible(true);
        this.setState({ updatePlaceId: placeId });
    }
    onUpdatePlace = (place) => {
        console.log("onUpdatePlace", place);
        this.setPlaceCardVisible(false);
        this.setState({ updatePlaceId: null });
        this.form.current.setFieldsValue({ place_id: place.id })
        this.placeSelect.current.fetchByValue();
    }
    handleShowPlaceOptions = (e) => {
        const checked = e.target.checked;
        console.log("handleShowPlaceOptions", e.target.checked);
        this.setState({ showPlaceOptions: checked });
    }
    render() {
        return (
            <div>
                <Spin spinning={this.state.isLoading}>
                    <h2>{this.state.mode === 'store' ? '新增行程景點' : '編輯行程景點'}</h2>
                    <Form
                        ref={this.form}
                        labelCol={{ span: 4 }}
                        initialValues={this.initialValues}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Form.Item label="到達時間" name="arrivetime">
                            <TimePicker />
                        </Form.Item>
                        <Form.Item label="行程地點名稱" name="name">
                            <Input placeholder="車城福安宮" />
                        </Form.Item>
                        <Form.Item label="GPS 位置" name="gpslocation">
                            <Input placeholder="22.6304461,120.2844837" />
                        </Form.Item>
                        <Form.Item label="建議停留時間 (分)" name="stayduration">
                            <InputNumber min={1} max={999} />
                        </Form.Item>
                        <Form.Item label="行程地點描述" name="description">
                            {/* <Input.TextArea rows={8} placeholder="車城福安宮是東南亞最大的土地公廟，香火鼎盛，至今已經超過35..." /> */}
                            <TextEditor rows={8} placeholder="車城福安宮是東南亞最大的土地公廟，香火鼎盛，至今已經超過35..." />
                        </Form.Item>
                        {this.state.mode === 'update' &&
                            <Form.Item label="行程圖片">
                                <TourDayPlacePhotoUpload
                                    tourId={this.props.tourId}
                                    tourDayId={this.props.tourDayId}
                                    tourPlaceId={this.state.id}
                                    placeId={this.form.current.getFieldValue('place_id')}
                                    onPlacePhotosIndex={this.props.onPlacePhotosIndex}
                                    onPlacePhotosDestroy={this.props.onPlacePhotosDestroy}
                                    onPlacePhotosStore={this.props.onPlacePhotosStore}
                                />
                            </Form.Item>
                        }
                        <Form.Item label="顯示地點">
                            <Form.Item valuePropName="checked" name="displayplace" style={{ display: 'inline-block', marginBottom: 0, marginRight: 10 }}>
                                <Checkbox onChange={this.handleShowPlaceOptions} />
                            </Form.Item>
                            {this.state.showPlaceOptions && <>
                                <Form.Item name="place_id" style={{ display: 'inline-block' }}>
                                    <PlaceSelect
                                        ref={this.placeSelect}
                                        showSearch
                                        placeholder="搜尋地點"
                                        style={{ width: 200 }}
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onAddItem={this.handleAddPlace}
                                        onUpdateItem={this.handleUpdatePlace}
                                    />
                                </Form.Item>
                            </>}
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit">
                                送出
                            </Button>
                        </Form.Item>
                    </Form>
                    <PlaceEditModal
                        open={this.state.placeCardVisible}
                        placeId={this.state.updatePlaceId}
                        onAdd={this.onAddPlace}
                        onUpdate={this.onUpdatePlace}
                        onClose={() => this.setPlaceCardVisible(false)} />
                </Spin>
            </div>
        )
    }
}

export default TourDayPlaceCard
