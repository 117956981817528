import { Button, Spin, notification, Table, Space, Typography, Modal, Result } from 'antd'
import React, { Component } from 'react'
import O2ToursAPI from '../../api/O2ToursAPI'
import TravelerForm from '../../components/forms/TravelerForm'
import TravelerSocialMediaModal from '../../components/TravelerSocialMediaModal'
import withRouterParamsHook from '../../withRouterParamsHook'


export class TravelerEditPage extends Component {
    state = {
        mode: 'store', // 'update'
        isLoading: false,
        id: null,
        socialmedias: [],
        follows: [],
        followers: [],
        travelerSocialMedias: [],
        addOrUpdateTravelerSocialMediaModalVisible: false,
        updateTravelerSocialMediaId: null,
        deleteTravelerSocialMediaModalVisible: false,
        deleteTravelerSocialMediaId: null,
        selectedFile: null,
    }
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    componentDidMount() {
        const travelerId = this.props.params.travelerId;
        this.fetchSocialMedias();
        this.fetchData(travelerId);
    }
    fetchData = (travelerId) => {
        if (!travelerId) return;
        this.setState({ isLoading: true, mode: 'update', id: travelerId });
        O2ToursAPI.travelers.show(travelerId).then((response) => {
            const data = response.data;
            console.log(data);
            this.form.current.setFieldsValue(data);
        }).finally(() => {
            this.setState({ isLoading: false });
        });
        this.fetchTravelerSocialMedias(travelerId);
        this.fetchFollows(travelerId);
        this.fetchFollowers(travelerId);
    }
    fetchSocialMedias = () => {
        return O2ToursAPI.socialmedias.index({}, 1, 999).then((response) => {
            const result = response.data;
            this.setState({ socialmedias: result });
        });
    }
    fetchTravelerSocialMedias = async (travelerId) => {
        O2ToursAPI.travelers.socialmedias.index(travelerId, {}, 1, 999).then((response) => {
            const result = response.data;
            this.setState({ travelerSocialMedias: result.data });
        })
    }
    fetchFollows = (travelerId) => {
        O2ToursAPI.travelers.follows.index(travelerId, {}, 1, 999).then((response) => {
            const result = response.data;
            this.setState({ follows: result.data });
        });
    }
    fetchFollowers = (travelerId) => {
        O2ToursAPI.travelers.followers.index(travelerId, {}, 1, 999).then((response) => {
            const result = response.data;
            this.setState({ followers: result.data });
        });
    }
    onFinish = (values) => {
        if (this.state.mode === 'store') {
            this.setState({ isLoading: true });
            O2ToursAPI.travelers.store(values).then((response) => {
                const travelerId = response.data.id;
                notification.info({
                    message: "新增成功"
                });
                // switch to update mode
                this.fetchData(response.data.id);
                this.uploadAvator(travelerId);
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        } else {
            this.setState({ isLoading: true });
            O2ToursAPI.travelers.update(this.state.id, values).then((response) => {
                notification.info({
                    message: "修改成功"
                });
                this.uploadAvator(this.state.id);
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        }
    }
    showTravelerSocialMediaAddModal = () => {
        console.log("showTravelerSocialMediaAddModal");
        this.setState({ addOrUpdateTravelerSocialMediaModalVisible: true, updateTravelerSocialMediaId: null });
    }
    showTravelerSocialMediaUpdateModal = (id) => {
        console.log("showTravelerSocialMediaUpdateModal", id);
        this.setState({ addOrUpdateTravelerSocialMediaModalVisible: true, updateTravelerSocialMediaId: id });
    }
    hideTravelerSocialMediaModal = () => {
        this.setState({ addOrUpdateTravelerSocialMediaModalVisible: false });
    }
    handleUpdateTravelerSocialMedia = (values) => {
        console.log("addTravelerSocialMedia", values);
        this.hideTravelerSocialMediaModal();
        this.fetchTravelerSocialMedias(this.state.id);
    }
    showTravelerSocialMediaDeleteModal = (id) => {
        console.log("confirmDeleteTravelerSocialMedia", id);
        this.setState({ deleteTravelerSocialMediaModalVisible: true, deleteTravelerSocialMediaId: id });
    }
    hideTravelerSocialMediaDeleteModal = () => {
        this.setState({ deleteTravelerSocialMediaModalVisible: false, deleteTravelerSocialMediaId: null });
    }
    deleteTravelerSocialMedia = () => {
        const id = this.state.deleteTravelerSocialMediaId;
        O2ToursAPI.travelers.socialmedias.destroy(this.state.id, id).then((response) => {
            console.log("O2ToursAPI.travelers.socialmedias.destroy", response);
            this.fetchData(this.state.id);
        }).finally(() => {
            this.hideTravelerSocialMediaDeleteModal();
        });
    }
    onSelectedAvatorFile = (file) => {
        console.log("Selected avator file", file);
        this.setState({ selectedFile: file });
    }
    uploadAvator = (travelerId) => {
        const coverImage = this.state.selectedFile;
        if (!travelerId || !coverImage) return;
        this.setState({ isLoading: true });
        O2ToursAPI.travelers.avator.store(travelerId, coverImage).then((response) => {
            notification.info({
                message: "圖片上傳成功"
            });
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }
    render() {
        const columns = [
            {
                title: '類型',
                dataIndex: 'socialmedia_id',
                key: 'socialmedia_id',
                render: (value) => {
                    const row = this.state.socialmedias.find((row) => row.id === value);
                    return row ? row.name : value;
                }
            },
            {
                title: '網址',
                dataIndex: 'socialmedia_url',
                key: 'socialmedia_url',
            },
            {
                title: '動作',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <Typography.Link onClick={this.showTravelerSocialMediaUpdateModal.bind(this, record.id)}>修改</Typography.Link>
                        <Typography.Link onClick={this.showTravelerSocialMediaDeleteModal.bind(this, record.id)}>刪除</Typography.Link>
                    </Space>
                ),
            },
        ];

        return (
            <div>
                <Spin spinning={this.state.isLoading}>
                    <h1>{this.state.mode === 'store' ? '新增旅者' : '編輯旅者: ' + this.state.id}</h1>
                    <TravelerForm ref={this.form} labelCol={{ span: 4 }} onFinish={this.onFinish} onSelectedAvatorFile={this.onSelectedAvatorFile} />
                    <h3>社交媒體</h3>
                    {this.state.id ? <>
                        <div style={{ textAlign: 'right' }}>
                            <Button onClick={this.showTravelerSocialMediaAddModal}>新增</Button>
                        </div>
                        <Table size="small" dataSource={this.state.travelerSocialMedias} columns={columns} rowKey="id" pagination={false} />
                        <TravelerSocialMediaModal
                            id={this.state.updateTravelerSocialMediaId}
                            open={this.state.addOrUpdateTravelerSocialMediaModalVisible}
                            onShow={(id) => O2ToursAPI.travelers.socialmedias.show(this.state.id, id)}
                            onStore={(values) => O2ToursAPI.travelers.socialmedias.store(this.state.id, values)}
                            onUpdate={(id, values) => O2ToursAPI.travelers.socialmedias.update(this.state.id, id, values)}
                            onOk={this.handleUpdateTravelerSocialMedia}
                            onCancel={this.hideTravelerSocialMediaModal} />
                        <Modal
                            title="刪除社交媒體"
                            open={this.state.deleteTravelerSocialMediaModalVisible}
                            onOk={this.deleteTravelerSocialMedia}
                            onCancel={this.hideTravelerSocialMediaDeleteModal}
                            okText="確定"
                            cancelText="取消"
                            confirmLoading={this.state.isLoading}
                        >
                            <p>你確定要刪除該社交媒體?</p>
                        </Modal>
                    </> : <Result status="info" title="請先新增旅者才能編輯社交媒體" />}
                    <br />
                    <h3>追蹤</h3>
                    <Table size="small" columns={[
                        {
                            title: '名稱',
                            dataIndex: 'traveler',
                            key: 'traveler',
                            render: (value, record) => value.name
                        },
                        {
                            title: '帳號',
                            dataIndex: 'traveler',
                            key: 'traveler',
                            render: (value, record) => value.account
                        },
                        {
                            title: 'E-mail',
                            dataIndex: 'traveler',
                            key: 'traveler',
                            render: (value, record) => value.email
                        },
                    ]} dataSource={this.state.follows} rowKey="traveler_id" pagination={false} />
                    <br />
                    <h3>被追蹤</h3>
                    <Table size="small" columns={[
                        {
                            title: '名稱',
                            dataIndex: 'follower',
                            key: 'follower',
                            render: (value, record) => value.name
                        },
                        {
                            title: '帳號',
                            dataIndex: 'follower',
                            key: 'follower',
                            render: (value, record) => value.account
                        },
                        {
                            title: 'E-mail',
                            dataIndex: 'follower',
                            key: 'follower',
                            render: (value, record) => value.email
                        },
                    ]} dataSource={this.state.followers} rowKey="follower_id" pagination={false} />
                </Spin>
            </div>
        )
    }
}

export default withRouterParamsHook(TravelerEditPage)
