import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import O2ToursAPI from '../api/O2ToursAPI'

function CountrySelect({ ...props }) {
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        O2ToursAPI.countries.index(1, 999).then((response) => {
            const result = response.data;
            setCountries(result.data);
        }).finally(() => {
            setLoading(false);
        });
    }, []);
    return (
        <Select loading={loading} {...props}>
            {countries.map((country) =>
                <Select.Option key={country.id} value={country.id}>{country.cn} {country.code}</Select.Option>
            )}
        </Select>
    )
}

export default CountrySelect