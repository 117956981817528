import { Button, DatePicker, Input, InputNumber, Radio, Select, Spin, Switch, Table, Tabs, Form, notification } from 'antd';
import React, { useEffect } from 'react'
import { useRef } from 'react';
import { useState } from 'react'
import dayjs from 'dayjs';
import AvatorUpload from '../../components/AvatorUpload';
import CategoriesSelect from '../../components/CategoriesSelect';
import RegionSelect from '../../components/RegionSelect';
import TextEditor from '../../components/TextEditor';
import TourDayEditor from '../../components/TourDayEditor';
import O2ToursAPI from '../../api/O2ToursAPI';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';
import TravelerTourPlanTabPane from './components/TravelerTourPlanTabPane';

function TravelerTourEditPage() {
  const [isLoading, setLoading] = useState(false);
  const [mode, setMode] = useState('store');
  const [previewUrl, setPreviewUrl] = useState();
  const [travelerPageUrl, setTravelerPageUrl] = useState();
  const [id, setId] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [data, setData] = useState();

  const [interested, setInterested] = useState([]);
  const form = useRef();
  const regionSelect = useRef();

  const navigate = useNavigate();
  const params = useParams();

  const updateTravelerPageUrl = async (travelerId, travelerAccount) => {
    setTravelerPageUrl(null);
    if (!travelerAccount) {
      const response = await O2ToursAPI.travelers.show(travelerId);
      travelerAccount = response.data.user.account;
    }
    if (!travelerAccount) return;
    const url = process.env.REACT_APP_URL + '/@' + travelerAccount + '/';
    setTravelerPageUrl(url);
  }

  const updatePreviewUrl = useCallback((tour) => {
    setPreviewUrl(null);
    console.log(tour.traveler);
    const travelerAccount = O2ToursAPI.auth.getLoginUser()?.account;
    if (!travelerAccount) return;
    const url = process.env.REACT_APP_URL + '/@' + travelerAccount + '/' + tour.urlroutename;
    setPreviewUrl(url);
    updateTravelerPageUrl(null, travelerAccount); // 若取得 TravelerAccount 則同時更新 TravelerPageUrl
  }, []);

  const handlePreview = () => {
    if (!previewUrl) {
      return;
    }
    window.open(previewUrl, "_blank");
  }
  const refreshSelect = () => {
    regionSelect.current.fetchByValue();
  }
  const fetchInterested = (tourId) => {
    O2ToursAPI.traveler.tours.interested.index(tourId, 1, 999).then((response) => {
      const result = response.data;
      setInterested(result.data);
    });
  }
  const fetchData = useCallback((id) => {
    if (!id) return;
    const genPath = generatePath('/traveler/tours/:id', { id: id });
    navigate(genPath, { replace: true });

    setMode('update');
    setId(id);
    setLoading(true);
    O2ToursAPI.traveler.tours.show(id).then((response) => {
      const tour = response.data;
      tour.starttime = dayjs(tour.starttime);
      tour.endtime = dayjs(tour.endtime);
      setData(tour);
      form.current.setFieldsValue(tour);
      updatePreviewUrl(tour);
      refreshSelect();
    }).finally(() => {
      setLoading(false);
    });
    fetchInterested(id);
  }, [navigate, updatePreviewUrl]);

  useEffect(() => {
    const id = params.tourId;
    fetchData(id);
  }, [fetchData, params.tourId]);

  const uploadCover = (tourId) => {
    const coverImage = selectedFile;
    console.log("uploadCover", tourId, coverImage);
    if (!tourId || !coverImage) return;
    setLoading(true);
    O2ToursAPI.traveler.tours.image.store(tourId, coverImage).then((response) => {
      notification.info({
        message: "圖片上傳成功"
      });
    }).finally(() => {
      setLoading(false);
    });

  }
  const onFinish = (values) => {
    values.starttime = dayjs(values.starttime).format('YYYY-MM-DD HH:mm:ss');
    values.endtime = dayjs(values.endtime).format('YYYY-MM-DD HH:mm:ss');
    values.published = values.published ? 1 : 0;
    console.log('Send:', values);

    if (mode === 'store') {
      setLoading(true);
      O2ToursAPI.traveler.tours.store(values).then((response) => {
        const tourId = response.data.id;
        notification.info({
          message: "新增成功"
        });
        uploadCover(tourId);
        fetchData(response.data.id);
      }).finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(true);
      O2ToursAPI.traveler.tours.update(id, values).then((response) => {
        notification.info({
          message: "修改成功"
        });
        uploadCover(id);
      }).finally(() => {
        setLoading(false);
      });
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  }
  const onValuesChange = () => {

  }
  const onSelectedAvatorFile = (file) => {
    console.log("Selected avator file", file);
    setSelectedFile(file);
  }

  const disabledStartDate = (current) => {
    const endtime = form.current.getFieldValue('endtime');
    if (current && endtime) {
      return current.diff(endtime) > 0;
    }
    return false;
  }
  const disabledEndDate = (current) => {
    const starttime = form.current.getFieldValue('starttime');
    if (current && starttime) {
      return current.diff(starttime) < 0;
    }
    return false;
  }
  const initialValues = {};
  return (
    <div>
      <Spin spinning={isLoading}>
        <h1>{mode === 'store' ? '新增行程' : `編輯行程: ${((data?.title) ?? '未命名')}`}</h1>
        <div style={{ textAlign: 'right' }}>
          <Button disabled={!previewUrl} style={{ marginLeft: '10px' }} size="small" onClick={handlePreview}>前台檢視</Button>
        </div>
        <Tabs items={[
          {
            label: '行程資訊',
            key: '1',
            children: <>
              <Form
                ref={form}
                labelCol={{ span: 3 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
                initialValues={initialValues}>
                <Form.Item label="發佈?" name="published" valuePropName="checked">
                  <Switch checkedChildren="發佈" unCheckedChildren="草稿" />
                </Form.Item>
                <Form.Item label="頁面網址" name="urlroutename">
                  <Input addonBefore={travelerPageUrl} />
                </Form.Item>
                <Form.Item label="行程名稱" name="title" rules={[{ required: true }]}>
                  <Input placeholder="【2021 屏東四重溪溫泉季】賞大片燈海，兩天一夜溫泉住宿行程" />
                </Form.Item>
                <Form.Item label="出發日期" name="starttime" rules={[{ required: true }]}>
                  <DatePicker minuteStep={15} disabledDate={disabledStartDate} />
                </Form.Item>
                <Form.Item label="結束日期" name="endtime" rules={[{ required: true }]}>
                  <DatePicker minuteStep={15} disabledDate={disabledEndDate} />
                </Form.Item>
                <Form.Item label="地區" name="region_id" rules={[{ required: true }]}>
                  <RegionSelect
                    ref={regionSelect}
                    placeholder="搜尋地區"
                    style={{ width: 200 }}
                  />
                </Form.Item>
                <Form.Item label="集合地點" name="originplace">
                  <Input placeholder="高雄" />
                </Form.Item>
                <Form.Item label="目的地 (略)" name="destplace">
                  <Input placeholder="屏東" />
                </Form.Item>
                <Form.Item label="預算" name="budget">
                  <InputNumber
                    min={0}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item label="是否含餐" name="includefood" rules={[{ required: true }]}>
                  <Radio.Group value={1}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="YouTube 影片嵌入網址" name="video_url">
                  <Input placeholder="https://www.youtube.com/embed/..." />
                </Form.Item>
                <Form.Item label="行程描述" name="description" rules={[{ required: true }]}>
                  <TextEditor />
                </Form.Item>
                <Form.Item label="預計人數" name="numofppl">
                  <InputNumber min={0} />
                </Form.Item>
                <Form.Item label="參加數" shouldUpdate>
                  {({ getFieldValue }) => getFieldValue('attendees')}
                </Form.Item>
                <Form.Item label="追蹤數" shouldUpdate>
                  {({ getFieldValue }) => getFieldValue('follows')}
                </Form.Item>
                <Form.Item label="是否結束?	" name="ended">
                  <Radio.Group value={1}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="交通方式說明" name="transportdesc">
                  <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item label="分類" name="tour_category" rules={[{ required: true }]}>
                  <CategoriesSelect />
                </Form.Item>
                <Form.Item label="標籤" name="tour_tag">
                  <Select
                    mode="tags"
                    placeholder="請輸入標籤"
                    style={{ width: '100%' }}
                  >
                  </Select>
                </Form.Item>
                <Form.Item label="封面圖">
                  <AvatorUpload onChange={onSelectedAvatorFile} />
                </Form.Item>
                <div style={{ textAlign: 'center' }}>
                  <Button type="primary" htmlType="submit">
                    送出
                  </Button>
                </div>
              </Form>
            </>,
          },
          {
            label: '行程地點',
            key: '2',
            disabled: !id,
            children: <>
              {/* TravelerTourEditPage -> TourDayEditor -> TourDayPlaceCard -> TourDayPlacePhotoUpload */}
              <TourDayEditor
                tourId={id}
                onDaysIndex={(tourId, page, pageSize) => O2ToursAPI.traveler.tours.days.index(tourId, page, pageSize)}
                onDaysShow={(tourId, tourDayId) => O2ToursAPI.traveler.tours.days.show(tourId, tourDayId)}
                onDaysStore={(tourId, values) => O2ToursAPI.traveler.tours.days.store(tourId, values)}
                onDaysUpdate={(tourId, tourDayId, values) => O2ToursAPI.traveler.tours.days.update(tourId, tourDayId)}
                onDaysDestroy={(tourId, tourDayId) => O2ToursAPI.traveler.tours.days.destroy(tourId, tourDayId)}
                onPlacesIndex={(tourId, tourDayId, page, pageSize) => O2ToursAPI.traveler.tours.days.places.index(tourId, tourDayId, page, pageSize)}
                onPlacesDestroy={(tourId, tourDayId, id) => O2ToursAPI.traveler.tours.days.places.destroy(tourId, tourDayId, id)}
                onPlacesShow={(tourId, tourDayId, id) => O2ToursAPI.traveler.tours.days.places.show(tourId, tourDayId, id)}
                onPlacesStore={(tourId, tourDayId, values) => O2ToursAPI.traveler.tours.days.places.store(tourId, tourDayId, values)}
                onPlacesUpdate={(tourId, tourDayId, id, values) => O2ToursAPI.traveler.tours.days.places.update(tourId, tourDayId, id, values)}
                onPlacePhotosIndex={(tourId, tourDayId, tourPlaceId, page, pageSize) => O2ToursAPI.traveler.tours.days.places.photos.index(tourId, tourDayId, tourPlaceId, page, pageSize)}
                onPlacePhotosStore={(tourId, tourDayId, tourPlaceId, id, file) => O2ToursAPI.traveler.tours.days.places.photos.store(tourId, tourDayId, tourPlaceId, id, file)}
                onPlacePhotosDestroy={(tourId, tourDayId, tourPlaceId, id) => O2ToursAPI.traveler.tours.days.places.photos.destroy(tourId, tourDayId, tourPlaceId, id)}
              />
            </>,
          },
          {
            label: '追蹤者',
            key: '4',
            disabled: !id,
            children: <>
              <Table columns={[
                {
                  title: '名稱',
                  dataIndex: 'traveler',
                  key: 'traveler',
                  render: (value, record) => value?.name
                },
                {
                  title: '帳號',
                  dataIndex: 'traveler',
                  key: 'traveler',
                  render: (value, record) => value?.account
                },
                {
                  title: 'E-mail',
                  dataIndex: 'traveler',
                  key: 'traveler',
                  render: (value, record) => value?.email
                },
              ]} dataSource={interested} rowKey="id" />
            </>,
          },
          {
            label: '開團',
            key: '5',
            disabled: !id,
            children: <TravelerTourPlanTabPane tourId={id} />
          }
        ]}>
        </Tabs>
      </Spin>
    </div>
  )
}

export default TravelerTourEditPage