import React, { Component } from 'react'
import "./DefaultLayout.css";
import { Col, Layout, Row, Space } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import logo from '../images/o2.png';
import fullLogo from '../images/o2-full.svg';
import O2ToursAPI from '../api/O2ToursAPI';
import withRouterNavigateHook from '../hoc/react-router-dom/withRouterNavigateHook';
import Menu from '../components/Menu';

const { Header, Sider, Content } = Layout;

class DefaultLayout extends Component {
  state = {
    collapsed: false,
    isMobile: false,
  };
  onBreakpoint = (breakpoint) => {
    this.setState({ isMobile: breakpoint });
  }
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleLogout = () => {
    const navigate = this.props.navigate;
    O2ToursAPI.auth.logout();
    navigate("/");
  }

  componentDidMount() {
    const navigate = this.props.navigate;

    if (this.props.redirect) {
      setTimeout(() => {
        console.log("redirecting to", this.props.redirect);
        navigate(this.props.redirect);
      }, 300);
    }
  }

  getChildren = () => {
    if (this.props.children) return this.props.children;
    if (this.props.redirect) return <>重導向至 {this.props.redirect}</>
    return '無內容';
  }

  render() {
    return (
      <Layout style={{ height: "100vh" }}>
        <Sider
          style={{ position: (this.state.isMobile && !this.state.collapsed) ? 'absolute' : 'unset', height: '100vh', zIndex: 100 }}
          trigger={null}
          breakpoint="md"
          onBreakpoint={this.onBreakpoint}
          collapsible
          collapsed={this.state.collapsed}
          collapsedWidth={this.state.isMobile ? 0 : 60}>
          <a href={process.env.REACT_APP_URL}>
            <div className="logo">
              <img src={this.state.collapsed ? logo : fullLogo} style={{ height: '100%' }} alt="Logo" />
            </div>
          </a>
          <Menu />
        </Sider>
        {this.state.isMobile &&
          !this.state.collapsed &&
          <div style={{ position: 'absolute', height: '100%', width: '100%', backgroundColor: 'black', opacity: 0.5, zIndex: 50 }} onClick={this.toggle}></div>}
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0, overflowX: 'auto', zIndex: 3, boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' }}>
            <Row>
              <Col>
                {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  className: 'trigger',
                  onClick: this.toggle,
                })}
              </Col>
              <Col flex={1} style={{ textAlign: 'center', paddingRight: 64 }}>
                {/* {!this.state.isMobile &&
                  process.env.NODE_ENV !== 'production' &&
                  <span>{process.env.REACT_APP_URL} ({process.env.NODE_ENV}) - {O2ToursAPI.auth.getLoginUser().email}</span>} */}
                <a href={process.env.REACT_APP_URL}>
                  <img src={fullLogo} style={{ height: 30 }} alt="Logo" />
                </a>
              </Col>
              <Col style={{ padding: '0px 10px 0px 10px' }}>
                <Space>
                  {/* <Typography.Link href={process.env.REACT_APP_URL} target="o2tours-frontend-preview">
                    <Button>前台預覽</Button>
                  </Typography.Link> */}
                  {/* <Button onClick={this.handleLogout}>登出</Button> */}
                </Space>
              </Col>
            </Row>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              minHeight: 280,
              overflowY: 'auto',
            }}
          >
            {this.getChildren()}
          </Content>
        </Layout>
      </Layout>
    );
  }
}
export default withRouterNavigateHook(DefaultLayout);
