import { Button, Card, Collapse, DatePicker, Empty, Form, Modal, notification, Popconfirm, Space, Spin, Tag, TimePicker } from 'antd';
import React from 'react'
import { useRef } from 'react';
import { useState } from 'react'
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import TourDayPlaceCard from './TourDayPlaceCard';

var placeCount = 0;

function TourDayEditor({
    onDaysIndex,
    onDaysShow,
    onDaysStore,
    onDaysUpdate,
    onDaysDestroy,
    onPlacesIndex,
    onPlacesDestroy,
    onPlacesShow,
    onPlacesStore,
    onPlacesUpdate,
    onPlacePhotosIndex,
    onPlacePhotosStore,
    onPlacePhotosDestroy,
    ...props }) {

    const [isLoading, setIsLoading] = useState(false);
    const [days, setDays] = useState([]);
    const [showDayModal, setShowDayModal] = useState();
    const [dayModelMode, setDayModelMode] = useState('store');
    const [dayModelIsLoading, setDayModelIsLoading] = useState(false);
    const [updateDayId, setUpdateDayId] = useState(null);
    const dayForm = useRef();
    const params = useParams();

    const addPlace = (tourDayId) => {
        const newDays = [...days];
        const tourDay = newDays.find((day) => day.id === tourDayId);
        let places = tourDay.places;
        if (!places) places = [];
        // let latestDay = 1;
        let latestTime = dayjs('08:00:00', "HH:mm:ss").unix();
        if (places.length > 0) {
            // latestDay = Math.max.apply(Math, places.map(function (o) { return o.day; }));
            latestTime = Math.max.apply(Math, places.map(function (o) { return dayjs(o.arrivetime, "HH:mm:ss").unix(); }));
        }
        const defaultStayDuration = 30;
        const defaultDrivingDuration = 30;
        const defaultArriveTime = (latestTime - latestTime % (60 * 30)) + defaultDrivingDuration * 60 + defaultStayDuration * 60;
        places.push({
            tid: placeCount++, // 前端用 id
            // day: latestDay,
            name: '待定',
            navigatorcount: 0,
            stayduration: defaultStayDuration,
            arrivetime: dayjs.unix(defaultArriveTime).format("HH:mm:ss"),
        });
        tourDay.places = places;
        setDays(newDays);
    }
    const removePlace = (tourDayId, index, row) => {
        const tourId = props.tourId;
        const newDays = [...days];
        const tourDay = newDays.find((day) => day.id === tourDayId);
        const places = tourDay.places;
        console.log("removePlace", index, row);
        if (!row.id) {
            places.splice(index, 1);
            setDays(newDays);
            return;
        }
        setIsLoading(true);
        if (onPlacesDestroy) {
            onPlacesDestroy(tourId, tourDayId, row.id).then(() => {
                places.splice(index, 1);
                setDays(days);
            }).catch((error) => {
                notification.error({ message: error });
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    const fetchTourDays = () => {
        setIsLoading(true);
        if (onDaysIndex) {
            onDaysIndex(props.tourId, 1, 1000).then((response) => {
                const result = response.data;
                console.log("tour_days", result.data);
                setDays(result.data);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    // 載入日期後自動讀取 place 資訊
    useEffect(() => {
        const loadPlace = (tourDayId, place) => {
            const newDays = [...days];
            const tourDay = newDays.find((day) => day.id === tourDayId);
            let places = tourDay.places;
            if (!places) {
                places = [];
            }
            place.tid = placeCount++;
            places.push(place);
            tourDay.places = places;
            setDays(newDays);
        }

        const fetchTourDayPlaces = (tourDayId) => {
            console.log("fetchTourDayPlaces: ", tourDayId);
            setIsLoading(true);
            if (onPlacesIndex) {
                onPlacesIndex(props.tourId, tourDayId, 1, 1000).then((response) => {
                    const places = response.data.data;
                    console.log("fetchTourDayPlaces: places", places);
                    places.forEach(place => {
                        loadPlace(tourDayId, place);
                    });
                }).finally(() => {
                    setIsLoading(false);
                });
            }
        }

        days.forEach((day) => {
            if (!day.places) {
                fetchTourDayPlaces(day.id);
            }
        });
        /* eslint-disable */
    }, [onPlacesIndex, props.tourId, days.length]); // eslint-disable-line

    const refresh = () => {
        console.log("TourPlaceEditPage: refresh: tourId: ", props.tourId);
        fetchTourDays();
    }
    useState(() => {
        if (!params.tourId) return;
        refresh();
    }, [params.tourId]);

    const showAddDayModal = () => {
        const lastDay = days.length > 0 ? dayjs(days[days.length - 1].date).add(1, 'day') : dayjs();
        lastDay.set('hour', 8);
        lastDay.set('minute', 0);
        lastDay.set('second', 0);

        setShowDayModal(true);
        setDayModelMode('store');
        dayForm.current.setFieldsValue({
            date: lastDay,
            departuretime: null
        });
    }
    const showUpdateDayModal = (tourDayId) => {
        setIsLoading(true);
        if (onDaysShow) {
            onDaysShow(props.tourId, tourDayId).then((response) => {
                const result = response.data;
                result.date = dayjs(result.date, "YYYY-MM-DD");
                result.departuretime = result.departuretime ? dayjs(result.departuretime, "HH:mm:ss") : null;

                setShowDayModal(true);
                setDayModelMode('update');
                setUpdateDayId(tourDayId);
                dayForm.current.setFieldsValue(result);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }
    const hideDayModal = () => {
        setShowDayModal(false);
        setUpdateDayId(null);
    }
    const addOrUpdateDay = (values) => {
        console.log(values);
        if (!values.date) {
            notification.warn({
                message: '請輸入日期'
            });
            return;
        }
        setDayModelIsLoading(true);

        values.date = values.date.format("YYYY-MM-DD");
        values.departuretime = values.departuretime ? values.departuretime.format("HH:mm:ss") : null;
        if (dayModelMode === 'store') {
            if (onDaysStore) {
                onDaysStore(props.tourId, values).then((response) => {
                    notification.info({
                        message: '新增成功'
                    });
                }).finally(() => {
                    setDayModelIsLoading(false);
                    refresh();
                    hideDayModal();
                });
            }
        } else {
            const tourDayId = updateDayId;
            if (onDaysUpdate) {
                onDaysUpdate(props.tourId, tourDayId, values).then((response) => {
                    notification.info({
                        message: '修改成功'
                    });
                }).finally(() => {
                    setDayModelIsLoading(false);
                    refresh();
                    hideDayModal();
                });
            }
        }

    }
    const destoryDay = (tourDayId) => {
        setIsLoading(true);
        if (onDaysDestroy) {
            onDaysDestroy(props.tourId, tourDayId).then((response) => {
                notification.info({
                    message: '刪除成功'
                });
                refresh();
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }
    const handleTourPlaceChange = (tourDayId, tid, row) => {
        const newRow = Object.assign({}, row);
        console.log('handleTourPlaceChange', tourDayId, tid, newRow);
        if (newRow) {
            const newDays = [...days];
            const tourDay = newDays.find((day) => day.id === tourDayId);
            const places = tourDay.places;
            const index = places.findIndex((row) => row.tid === tid);
            if (index >= 0) {
                newRow.tid = tid;
                places[index] = newRow;
                setDays(newDays);
            }
        } else {
            refresh();
        }
    }
    const genCollapseExtra = (tourDayId, index, row) => {
        return <div>
            {!row.id && <Tag color="cyan">草稿</Tag>}
            <Popconfirm title="刪除地點後將無法還原，您是否確定要刪除?" onCancel={(event) => {
                event.stopPropagation(); // Stop to call parent onClick event.
            }} onConfirm={(event) => {
                // Remove specific index from places
                removePlace(tourDayId, index, row);
                event.stopPropagation(); // Stop to call parent onClick event.
            }}>
                <Button type="primary" size="small" danger onClick={(event) => {
                    event.stopPropagation(); // Stop to call parent onClick event.
                }}>刪除</Button>
            </Popconfirm>
        </div>;
    }

    return (
        <div>
            <Spin spinning={isLoading}>
                <div style={{ marginBottom: 10, textAlign: 'right' }}>
                    <Button type="primary" onClick={showAddDayModal}>新增天</Button>
                </div>
                {days.map((day) => {
                    return <Card key={day.id} title={`第 ${day.day} 天 - ${day.date} - 出發時間 ${day.departuretime ?? '無'}`} size="small" style={{ marginBottom: 10 }} extra={<Space>
                        <Button size="small" onClick={() => addPlace(day.id)}>新增地點</Button>
                        <Button size="small" onClick={() => showUpdateDayModal(day.id)}>修改天</Button>
                        <Popconfirm onConfirm={() => destoryDay(day.id)} title="刪除天會一併將當天行程全部刪除，是否確定要刪除?">
                            <Button size="small" danger>刪除天</Button>
                        </Popconfirm>
                    </Space>}>
                        {!day.places ? (isLoading ? <div style={{ textAlign: 'center ' }}><Spin spinning={true} /></div> : <Empty />) :
                            <Collapse>
                                {day.places.map((row, index) =>
                                    <Collapse.Panel header={`${row.arrivetime} (停留 ${row.stayduration} 分) ${row.name} (導航次數: ${row.navigatorcount})`} key={row.tid} extra={genCollapseExtra(day.id, index, row)}>
                                        <TourDayPlaceCard
                                            tourId={props.tourId}
                                            tourDayId={day.id}
                                            id={row.id}
                                            defaultValues={row}
                                            onPlacesShow={onPlacesShow}
                                            onPlacesStore={onPlacesStore}
                                            onPlacesUpdate={onPlacesUpdate}
                                            onPlacePhotosIndex={onPlacePhotosIndex}
                                            onPlacePhotosDestroy={onPlacePhotosDestroy}
                                            onPlacePhotosStore={onPlacePhotosStore}
                                            onChange={(result) => handleTourPlaceChange(day.id, row.tid, result)}
                                        />
                                    </Collapse.Panel>)}
                            </Collapse>
                        }
                    </Card>
                })}
                <Modal title={`${dayModelMode === 'store' ? '新增' : '修改'}天`}
                    open={showDayModal}
                    confirmLoading={dayModelIsLoading}
                    onOk={() => dayForm.current.submit()}
                    onCancel={hideDayModal}>
                    <Form ref={dayForm} labelCol={{ span: 4 }} onFinish={addOrUpdateDay}>
                        <Form.Item label="日期" name="date" rules={[{ required: true }]}>
                            <DatePicker />
                        </Form.Item>
                        <Form.Item label="出發時間" name="departuretime" rules={[{ required: true }]}>
                            <TimePicker />
                        </Form.Item>
                    </Form>
                </Modal>
            </Spin>
        </div>
    )
}

export default TourDayEditor