import { Table, Space, Button, notification, Typography, Avatar } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import O2ToursAPI from '../../api/O2ToursAPI';
import dayjs from 'dayjs';
export class TravelerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            pagination: {
                current: null,
                total: null,
                pageSize: null,
                //onChange: (page, pageSize) => this.refresh(page, pageSize)
            },
            sorter: {},
            data: [],
            confirmDeleteId: null,
        };
    }
    componentDidMount() {
        this.refresh();
    }
    refresh = (page, pageSize, sort, direction) => {
        if (!sort) sort = this.state.sorter.columnKey;
        if (!direction) direction = this.state.sorter.order;

        console.log("refresh");
        this.setState({ isLoading: true });
        O2ToursAPI.travelers.index({}, page, pageSize, sort, direction).then((response) => {
            const results = response.data.data;
            console.log(results);
            const pagination = this.state.pagination;
            pagination.current = response.data.current_page;
            pagination.pageSize = response.data.per_page;
            pagination.total = response.data.total;
            this.setState({
                pagination: pagination,
                data: results
            });
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }
    confirmDelete = (id) => {
        console.log("confirmDelete", id);
        this.setState({ confirmDeleteModalVisible: true, confirmDeleteId: id });
    }
    hideDelete = () => {
        console.log("hideDelete");
        this.setState({ confirmDeleteModalVisible: false, confirmDeleteId: null });
    }
    handleDelete = () => {
        const id = this.state.confirmDeleteId;
        this.setState({ isLoading: true });
        O2ToursAPI.travelers.destroy(id).then((response) => {
            console.log("handleDelete", id, response);
            notification.info({
                message: "刪除成功"
            });
        }).finally(() => {
            this.setState({ isLoading: false, confirmDeleteModalVisible: false });
            this.refresh();
        });
    }
    handleTableChange = (newPagination, filters, sorter) => {
        this.setState({
            sorter: sorter
        }, () => this.refresh(newPagination.current, newPagination.pageSize));
    }
    render() {
        const columns = [
            {
                title: '排行',
                dataIndex: 'top',
                key: 'top',
                // sorter: (a, b) => a.top - b.top,
                sorter: true,
            },
            {
                title: '頭像',
                dataIndex: 'avator_file',
                key: 'avator_file',
                render: (value, record) => {
                    const url = process.env.REACT_APP_URL + '/' + value;
                    return <Avatar src={url} />;
                }
            },
            {
                title: '帳號',
                dataIndex: 'account',
                key: 'account',
                render: (text, record) => <Link to={'/admin/travelers/' + record.id}>{text}</Link>,
            },
            {
                title: '名稱',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '電子信箱',
                dataIndex: 'email',
                key: 'email',
                width: 200
            },
            {
                title: '公開顯示',
                dataIndex: 'display',
                key: 'display',
                render: (value) => value ? '是' : '否',
                // sorter: (a, b) => a.display - b.display
                sorter: true,
            },
            {
                title: '描述',
                dataIndex: 'description',
                key: 'description',
                textWrap: 'word-break',
                ellipsis: true,
                render: (value) => <div style={{ width: 200, textOverflow: 'ellipsis', wordBreak: 'break-world' }}>{value}</div>
            },
            {
                title: '追蹤',
                dataIndex: 'follows_count',
                key: 'follows_count',
                // sorter: (a, b) => a.follows_count - b.follows_count,
                sorter: true,
            },
            {
                title: '被追蹤',
                dataIndex: 'followers_count',
                key: 'followers_count',
                // sorter: (a, b) => a.followers_count - b.followers_count
                sorter: true,
            },
            {
                title: '顯示被追蹤',
                dataIndex: 'followers',
                key: 'followers',
                // sorter: (a, b) => a.followers - b.followers
                sorter: true,
            },
            {
                title: '建立日期',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                // sorter: (a, b) => dayjs(a.created_at).diff(b.created_at)
                sorter: true,
            },
            {
                title: '動作',
                key: 'action',
                fixed: 'right',
                render: (text, record) => (
                    <Space size="middle">
                        <Link to={'/admin/travelers/' + record.id}>修改</Link>
                        <Typography.Link onClick={this.confirmDelete.bind(this, record.id)}>刪除</Typography.Link>
                    </Space>
                ),
            },
        ];
        return (
            <div>
                <Link to="/admin/travelers/create">
                    <Button type="primary">新增</Button>
                </Link>
                <Table
                    columns={columns}
                    dataSource={this.state.data}
                    loading={this.state.isLoading}
                    onChange={this.handleTableChange}
                    rowKey="id"
                    pagination={this.state.pagination}
                    scroll={{ x: 'max-content' }} />
                <Modal
                    title="刪除旅者"
                    open={this.state.confirmDeleteModalVisible}
                    onOk={this.handleDelete}
                    onCancel={this.hideDelete}
                    okText="確定"
                    cancelText="取消"
                    confirmLoading={this.state.isLoading}
                >
                    <p>你確定要刪除該旅者?</p>
                </Modal>
            </div>
        )
    }
}

export default TravelerPage
