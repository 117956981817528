import { Table, Tag, Space, Button, notification, Typography, Col, Row, Form } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import O2ToursAPI from '../../api/O2ToursAPI';
import dayjs from 'dayjs';
import CategoriesSelect from '../../components/CategoriesSelect';

export class TourPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            query: {},
            pagination: {
                current: null,
                total: null,
                pageSize: null,
                // onChange: (page, pageSize) => this.refresh(page, pageSize)
            },
            sorter: {},
            data: [],
            confirmDeleteId: null,
        };
        this.form = React.createRef();
    }
    componentDidMount() {
        this.refresh();
    }
    refresh = (page, pageSize, sort, direction) => {
        if (!sort) sort = this.state.sorter.columnKey;
        if (!direction) direction = this.state.sorter.order;
        // console.log("refresh");
        this.setState({ isLoading: true });
        O2ToursAPI.tours.index(this.state.query, page, pageSize, sort, direction).then((response) => {
            const results = response.data.data;
            results.forEach(element => {
                element.tags = [];
            });
            console.log(results);
            const pagination = this.state.pagination;
            pagination.current = response.data.current_page;
            pagination.pageSize = response.data.per_page;
            pagination.total = response.data.total;
            this.setState({
                pagination: pagination,
                data: results
            });
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }
    confirmDelete = (id) => {
        // console.log("confirmDelete", id);
        this.setState({ confirmDeleteModalVisible: true, confirmDeleteId: id });
    }
    hideDelete = () => {
        // console.log("hideDelete");
        this.setState({ confirmDeleteModalVisible: false, confirmDeleteId: null });
    }
    handleDelete = () => {
        const id = this.state.confirmDeleteId;
        this.setState({ isLoading: true });
        O2ToursAPI.tours.destroy(id).then((response) => {
            // console.log("handleDelete", id, response);
            notification.info({
                message: "刪除成功"
            });
        }).finally(() => {
            this.setState({ isLoading: false, confirmDeleteModalVisible: false });
            this.refresh();
        });
    }
    handleSearch = (values) => {
        values.category_ids = values.category_ids.join(",");
        this.setState({ query: values }, () => this.refresh());
    }
    handleTableChange = (newPagination, filters, sorter) => {
        this.setState({
            sorter: sorter
        }, () => this.refresh(newPagination.current, newPagination.pageSize));
    }
    render() {
        const columns = [
            {
                title: '行程名稱',
                dataIndex: 'title',
                key: 'title',
                render: (text, record) => <Link to={'/admin/tours/' + record.id}>{text}</Link>,
            },
            {
                title: '網址',
                dataIndex: 'urlroutename',
                key: 'urlroutename',
            },
            {
                title: '發佈?',
                key: 'published',
                dataIndex: 'published',
                render: published => (
                    <>
                        {published ? <Tag color='green'>已發佈</Tag> : <Tag color='volcano'>草稿</Tag>}
                    </>
                ),
            },
            {
                title: '參加數',
                dataIndex: 'attendees_count',
                key: 'attendees_count',
                // sorter: (a, b) => a.attendees_count - b.attendees_count
                sorter: true,
            },
            {
                title: '顯示參加數',
                dataIndex: 'attendees',
                key: 'attendees',
                // sorter: (a, b) => a.attendees - b.attendees
                sorter: true,
            },
            {
                title: '檢視數',
                dataIndex: 'viewercount',
                key: 'viewercount',
                // sorter: (a, b) => a.viewercount - b.viewercount
                sorter: true,
            },
            {
                title: '平均每日點擊',
                render: (value, record) => (record.viewercount / dayjs().diff(record.created_at, 'days')).toFixed(2),
                sorter: (a, b) => (a.viewercount / dayjs().diff(a.created_at, 'days')) - (b.viewercount / dayjs().diff(b.created_at, 'days'))
            },
            {
                title: '建立日期',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                // sorter: (a, b) => dayjs(a.created_at).diff(b.created_at)
                sorter: true,
            },
            {
                title: '修改日期',
                dataIndex: 'updated_at',
                key: 'updated_at',
                render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                // sorter: (a, b) => dayjs(a.updated_at).diff(b.updated_at)
                sorter: true,
            },
            {
                title: '動作',
                key: 'action',
                fixed: 'right',
                render: (text, record) => (
                    <Space size="middle">
                        <Link to={'/admin/tours/' + record.id}>修改</Link>
                        <Typography.Link onClick={this.confirmDelete.bind(this, record.id)}>刪除</Typography.Link>
                    </Space>
                ),
            },
        ];

        return (
            <div>
                <Form ref={this.form} onFinish={this.handleSearch}>
                    <Row gutter={10} style={{ marginBottom: 10 }}>
                        <Col span={20}>
                            <Form.Item name="category_ids">
                                <CategoriesSelect style={{ width: 300, maxWidth: '100%' }} onChange={() => this.form.current.submit()} />
                            </Form.Item>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }}>
                            <Link to="/admin/tours/create">
                                <Button type="primary">新增</Button>
                            </Link>
                        </Col>
                    </Row>
                </Form>
                <Table
                    columns={columns}
                    dataSource={this.state.data}
                    loading={this.state.isLoading}
                    onChange={this.handleTableChange}
                    rowKey="id"
                    pagination={this.state.pagination}
                    scroll={{ x: 'max-content' }} />
                <Modal
                    title="刪除行程"
                    open={this.state.confirmDeleteModalVisible}
                    onOk={this.handleDelete}
                    onCancel={this.hideDelete}
                    okText="確定"
                    cancelText="取消"
                    confirmLoading={this.state.isLoading}
                >
                    <p>你確定要刪除該行程?</p>
                </Modal>
            </div>
        )
    }
}

export default TourPage
