import { Layout } from 'antd'
import React, { Component } from 'react'

export class LoginPageLayout extends Component {
    render() {
        return (
            <Layout style={{ height: '100%' }}>
                <Layout>
                    <Layout.Content style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: "url(./images/login-bg.png)" }}>
                    </Layout.Content>
                </Layout>
                <Layout.Sider style={{ backgroundColor: 'white' }} width={400}>
                    <Layout style={{ height: '100%' }}>
                        <Layout.Content style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
                            <div style={{ flex: 1 }}></div>
                            <div style={{ flex: 1, textAlign: 'center', padding: 20 }}>
                                {this.props.children}
                            </div>
                            <div style={{ flex: 1 }}></div>
                        </Layout.Content>
                        <Layout.Footer style={{ backgroundColor: 'white', textAlign: 'center' }}>
                            Copyright © 2022 ZSCorp.
                        </Layout.Footer>
                    </Layout>
                </Layout.Sider>
            </Layout>
        )
    }
}

export default LoginPageLayout