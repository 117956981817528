import { Tabs, Input } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react'
import O2ToursAPI from '../../api/O2ToursAPI';
import AchivementTypeSelect from '../../components/AchivementTypeSelect';
import CRUDTableForm from '../../components/CRUDTableForm';

function AdminAchievements() {
    const [selectedAchievementType, setSelectedAchievementType] = useState();
    const handleTypeChange = (value) => {
        console.log("handleTypeChange", value);
        setSelectedAchievementType(value);
    }
    return (
        <>
            <Tabs defaultActiveKey="achievements" items={[
                {
                    key: 'achievement',
                    label: '成就',
                    children: <>
                        <CRUDTableForm
                            resourceName="成就"
                            pageStartContainer={<>
                                <span>成就類型: </span><AchivementTypeSelect value={selectedAchievementType} onChange={handleTypeChange} style={{ width: 200 }} />
                            </>}
                            onIndex={() => O2ToursAPI.achievements.index({
                                achievementtype_id: selectedAchievementType,
                            }, 1, 999)}
                            onShow={(id) => O2ToursAPI.achievements.show(id)}
                            onStore={(values) => O2ToursAPI.achievements.store(values)}
                            onUpdate={(id, values) => O2ToursAPI.achievements.update(id, values)}
                            onDestroy={(id) => O2ToursAPI.achievements.destroy(id)}
                            columns={[
                                {
                                    dataIndex: 'achievementtype_id',
                                    title: '類型',
                                    formChildren: <AchivementTypeSelect />,
                                },
                                {
                                    dataIndex: 'id',
                                    title: 'ID',
                                    width: 100,
                                    sorter: true,
                                },
                                {
                                    dataIndex: 'medal',
                                    title: '獎牌',
                                    width: 100,
                                    sorter: true,
                                    formChildren: <Input />,
                                },
                                {
                                    dataIndex: 'name',
                                    title: '名稱',
                                    width: 300,
                                    ellipsis: true,
                                    formChildren: <Input />,
                                    formRules: [{ required: true }],
                                },
                                {
                                    dataIndex: 'description',
                                    title: '說明',
                                    ellipsis: true,
                                    formChildren: <Input.TextArea />,
                                },
                                {
                                    dataIndex: 'updated_at',
                                    title: '更新時間',
                                    width: 160,
                                    render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                                    sorter: true,
                                },
                                {
                                    dataIndex: 'created_at',
                                    title: '建立時間',
                                    width: 160,
                                    render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                                    sorter: true,
                                },
                            ]}
                        />
                    </>
                },
                {
                    key: 'types',
                    label: '成就類型',
                    children: <>
                        <CRUDTableForm
                            resourceName="成就類型"
                            onIndex={() => O2ToursAPI.achievementTypes.index({}, 1, 999)}
                            onShow={(id) => O2ToursAPI.achievementTypes.show(id)}
                            onStore={(values) => O2ToursAPI.achievementTypes.store(values)}
                            onUpdate={(id, values) => O2ToursAPI.achievementTypes.update(id, values)}
                            onDestroy={(id) => O2ToursAPI.achievementTypes.destroy(id)}
                            columns={[
                                {
                                    dataIndex: 'id',
                                    title: 'ID',
                                    sorter: true,
                                },
                                {
                                    dataIndex: 'index',
                                    title: '排序',
                                    sorter: true,
                                },
                                {
                                    dataIndex: 'name',
                                    title: '名稱',
                                    formChildren: <Input />,
                                    formRules: [{ required: true }],
                                },
                                {
                                    dataIndex: 'updated_at',
                                    title: '更新時間',
                                    render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                                    sorter: true,
                                },
                                {
                                    dataIndex: 'created_at',
                                    title: '建立時間',
                                    render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                                    sorter: true,
                                },
                            ]}
                        />
                    </>
                },
            ]} />
        </>
    )
}

export default AdminAchievements