import { Avatar, Button, Form, Input, Modal, notification, Radio, Space, Table, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react'
import O2ToursAPI from '../../api/O2ToursAPI';
import AchivementTypeSelect from '../../components/AchivementTypeSelect';
import AvatorUpload from '../../components/AvatorUpload';

function TravelerAchievementsPage() {
    const [achievements, setAchievements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedType, setSelectedType] = useState();
    const [searchText, setSearchText] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [editAchievementModal, setEditAchievementModal] = useState({ open: false, id: null });
    const [editAchievementForm] = useForm();
    const [achievedFilter, setAchievedFilter] = useState('not-achieved');
    const medalSortOrder = ['bronze', 'silver', 'gold'];


    const refresh = useCallback(() => {
        setLoading(true);
        O2ToursAPI.traveler.achievements.index({
            name: searchText,
            achievementtype_id: selectedType,
            achieved: achievedFilter,
        }, 1, 999).then((response) => {
            const result = response.data;
            console.log("achievements", result.data);
            setAchievements(result.data);
        }).finally(() => {
            setLoading(false);
        });
    }, [searchText, selectedType, achievedFilter]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const handleTypeChange = (value) => {
        setSelectedType(value);
    }
    const handleSearchNameChange = (e) => {
        setSearchText(e.target.value);
    }
    const handleShowEditAchivementModal = (achievementId, type) => {
        setEditAchievementModal({
            open: true,
            id: type === 'update' ? achievementId : null,
        })
        if (type === 'update') {
            O2ToursAPI.traveler.achievements.show(achievementId).then((response) => {
                const result = response.data;
                console.log("handleShowEditAchivementModal", result);
                editAchievementForm.setFieldsValue(result);
            }).finally(() => {

            });
        } else {
            editAchievementForm.setFieldValue('achievement_id', achievementId);
        }
    }
    const handleCloseEditAchievementModal = () => {
        setEditAchievementModal({
            open: false,
            id: null,
        });
        editAchievementForm.resetFields();
        setSelectedFile(null);
    }
    const handleOkEditAchievementModal = () => {
        editAchievementForm?.validateFields().then((value) => {
            editAchievementForm.submit();
        });
    }
    const handleSaveAchievement = (values) => {
        console.log("handleSaveAchivement", values);
        O2ToursAPI.traveler.achievements.store(values, selectedFile).then((response) => {
            console.log("handleSaveAchivement", response);
            notification.info({ message: '成就新增成功' });
        }).finally(() => {
            handleCloseEditAchievementModal();
            refresh();
        });
    }
    const handleDeleteAchievement = (id) => {
        O2ToursAPI.traveler.achievements.destroy(id).then((response) => {
            notification.info({ message: '成就刪除成功' });
        }).finally(() => {
            handleCloseEditAchievementModal();
            refresh();
        });
    }
    const onSelectedAvatorFile = (file) => {
        console.log("Selected avator file", file);
        setSelectedFile(file);
    }
    const handleChangeFilter = (e) => {
        setAchievedFilter(e.target.value);
    }
    return (
        <>
            <Form>
                <Form.Item label="成就類型">
                    <AchivementTypeSelect style={{ width: 300 }} onChange={handleTypeChange} />
                </Form.Item>
                <Form.Item label="名稱搜尋">
                    <Input onChange={handleSearchNameChange} />
                </Form.Item>
                <Form.Item>
                    <Radio.Group value={achievedFilter} onChange={handleChangeFilter}>
                        <Radio.Button value="not-achieved">未達成</Radio.Button>
                        <Radio.Button value="achieved">已達成</Radio.Button>
                        <Radio.Button value="all">全部</Radio.Button>
                    </Radio.Group>
                </Form.Item>
            </Form>

            <Table loading={loading} columns={[
                {
                    dataIndex: 'medal',
                    title: '獎盃',
                    sorter: (a, b) => medalSortOrder.indexOf(a.medal) - medalSortOrder.indexOf(b.medal),
                    width: 80,
                    render: (value) => {
                        try {
                            const image = require('../../images/medal/' + value + '.png');
                            return <Avatar src={image} />;
                        } catch (error) {
                            return <></>;
                        }
                    }
                },
                {
                    dataIndex: 'name',
                    title: '成就名稱',
                    width: 250,
                    ellipsis: true,
                },
                {
                    dataIndex: 'description',
                    title: '描述',
                    ellipsis: true,
                },
                {
                    title: '照片',
                    width: 64,
                    render: (value, record) => record.travelerachievement ? <Avatar size={32} src={process.env.REACT_APP_URL + record.travelerachievement.thumbnailfileurl} /> : ''
                },
                {
                    title: '操作',
                    width: 100,
                    fixed: 'right',
                    render: (value, record) => <Space>
                        {record.travelerachievement ?
                            <Typography.Link onClick={() => handleShowEditAchivementModal(record.id, 'update')}>已達成</Typography.Link> :
                            <Typography.Link style={{ color: 'gray' }} onClick={() => handleShowEditAchivementModal(record.id, 'store')}>未達成</Typography.Link>
                        }
                    </Space>
                }
            ]} dataSource={achievements} rowKey="id" scroll={{ x: 1000 }} />
            <Modal title="達成" open={editAchievementModal.open} onOk={handleOkEditAchievementModal} onCancel={handleCloseEditAchievementModal}>
                <p>讓大家知道你的旅行成就</p>
                <Form form={editAchievementForm} onFinish={handleSaveAchievement}>
                    <Form.Item label="成就" name="achievement_id" hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="照片">
                        <Form.Item name="thumbnailfileurl" style={{ marginBottom: 0 }}>
                            <AvatorUpload onChange={onSelectedAvatorFile} />
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            {editAchievementModal.id && <Button danger onClick={() => handleDeleteAchievement(editAchievementModal.id)}>刪除</Button>}
                        </Form.Item>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default TravelerAchievementsPage